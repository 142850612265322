import React, { Component } from "react";
import { PATHS } from '../../constants';
import { logout, setLoader } from "../../actions";
import { connect } from "react-redux";
import { LoaderScreen } from "../includes";


class Logout extends Component {

    componentDidMount() {
        this.props.setLoader(true);
        this.props.logout().then(result => {
            // this.props.setLoader(true);
            
            if (this.props.logoutResponse && this.props.logoutResponse.success) {
                this.props.setLoader(false);
                
                if(localStorage.getItem('checkbox') == '' && localStorage.getItem('checkbox') == undefined) {
                    localStorage.clear();
                }else {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('state');
                    localStorage.removeItem('current profile');
                    localStorage.removeItem('isFreeAccount');
                    localStorage.removeItem('vai_user_email');
                }
                
                //localStorage.clear();
                this.props.history.push(PATHS.LOGIN);
            }else {
                this.props.setLoader(false);
            }
        })
        // localStorage.removeItem('state');
    }


    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        //localStorage.removeItem('state');
        return (
            <div>
            </div>
        );
    }

}


// export default Logout;
const mapStateToProps = state => {
    return {
        logoutResponse: state.service ? state.service.logoutResponse : "",
        isLoading: state.service ? state.service.isLoading : ""
    };
};

export default connect(mapStateToProps, {
    logout,
    setLoader
})(Logout);
