// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderInput } from "../../constants/redux-form";
import { PATHS } from "../../constants";
import { setLoader, resetPswd, reset } from "../../actions";
import { required, passwordValidation } from "../../constants/validation";
import { LoaderScreen } from "../includes";
import { Bottom } from "../includes";
import * as Styled from "../page.styled";

/*
 TYPES
*/

type Props = {
  handleSubmit: Function,
  setLoader: Function,
  history: Object
};

class ResetPassword extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      alertClass: "",
      // icon: 'fas fa-eye',
      type: 'password',
      errorMsg: "",
      hidden: true,
      hidden1: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.toggleShow1 = this.toggleShow1.bind(this);
  }

  componentDidMount() {
    this.props.reset('resetPasswordForm');
  }

  renderPassword1 = ({ placeholder, id, input, icon, label, type, meta: { touched, error } }: Props) => (
    <div className="input-group mb-3">
      {/* <div className="input-group-prepend">
        <span className="input-group-text"><i className="fas fa-unlock-alt"></i></span>
      </div> */}
      <input  {...input} type={this.state.hidden ? "password" : "text"} placeholder={placeholder} className="form-control" id={id} />

      <span className="password-trigger"><Link to="#" onClick={this.toggleShow}><i className={this.state.hidden ? "fas fa-eye-slash" : "fas fa-eye"}></i></Link></span>

      {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
  );

  renderPasswordReyType = ({ placeholder, id, input, icon, label, type, meta: { touched, error } }: Props) => (
    <div className="input-group mb-3">
      {/* <div className="input-group-prepend">
        <span className="input-group-text"><i className="fas fa-unlock-alt"></i></span>
      </div> */}
      <input  {...input} type={this.state.hidden1 ? "password" : "text"} placeholder={placeholder} className="form-control" id={id} />

      <span className="password-trigger"><Link to="#" onClick={this.toggleShow1}><i className={this.state.hidden1 ? "fas fa-eye-slash" : "fas fa-eye"}></i></Link></span>

      {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
  );

  toggleShow() {
    console.log(this.state.hidden)
    this.setState({ hidden: !this.state.hidden });
  }

  toggleShow1() {
    this.setState({ hidden1: !this.state.hidden1 });
  }


  onSubmit = formValues => {
    // console.log(formValues)
    let errorMsg = "";
    if (formValues.password !== formValues.password_confirmation) {
      errorMsg = "Please match your password";
      return false;
    }
    this.setState({
      errorMsg: errorMsg
    });
    let formValue = {
      email: localStorage.getItem('email'),
      password: formValues.password,
      password_confirmation: formValues.password_confirmation,
      token: this.props.match.params.token
    }
    // console.log('final value', formValue)

    let successMessage = "";
    let errorMessage = "";
    setTimeout(() => {
      this.props.setLoader(true);
      this.props.resetPswd(formValue).then(result => {
        // console.log('resetPswdResponse password', this.props.resetPswdResponse)
        if (this.props.resetPswdResponse.success) {

          successMessage = this.props.resetPswdResponse.message;
          // this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
          
          this.setState({ alertMessage: 'Password updated successfully! You can login to your account with the new password.', alertClass: "alert alert-success" });
          this.props.reset('resetPasswordForm');
          setTimeout(() => {
            this.props.history.push(PATHS.LOGIN);
          }, 2000);

        } else {
          errorMessage = this.props.resetPswdResponse.message;
          this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
        }

        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);

      });
    }, 1000);
  };


  isPasswordMatch = event => {
    var pass1 = document.getElementById("password").value;
    var pass2 = document.getElementById("password_confirmation").value;

    // console.log('pass1 ', pass1);
    //  console.log('password_confirmation ', pass2);

    var errorMsg = "";
    var ok = true;
    if (pass1 !== pass2 && pass2.length > 0) {
      errorMsg = "Please match your password";
      this.setState({
        errorMsg: errorMsg,
        alertClass: "alert alert-danger"
      });
      return false;
    } else {
      this.setState({
        errorMsg: "",
        alertClass: ""
      });
    }

    return ok;
  };


  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value
    }, () => {
      if (name === 'password' || name === 'password_confirmation')
        this.isPasswordMatch();
    }
    );
  }

  render() {
    if (localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.HOME);
    }

    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (

      <div className="form-container small-form-page pb-0">
        <div className="inner-page-h container pt-form">
          <div className="form-body">
            <div className="form-content">
              <div className="form-header mb-4">
                <Link to={PATHS.HOME} variant="body2">
                  <img src="/assets/img/logo.svg" className="inner-logo img-fluid" alt="Logo Images" />
                </Link>
              </div>
              <div className="trans-card p-4 mb-4">
                <div className="tc-heading">
                  <h2>Reset Password</h2>
                </div>

                {this.state.alertMessage ? (
                  <div className={this.state.alertClass} role="alert">
                    {this.state.alertMessage}
                  </div>
                ) : null}

                {this.state.errorMsg ? (
                  <div className={this.state.alertClass} role="alert">
                    {this.state.errorMsg}
                  </div>
                ) : null}

                <form
                  name="resetPasswordForm"
                  className="form"
                  onSubmit={this.props.handleSubmit(this.onSubmit)}
                >

                  <Field
                    name="password"
                    id="password"
                    type="password"
                    validate={[required, passwordValidation]}
                    placeholder={`New Password`}
                    component={this.renderPassword1}
                    onChange={(event) => this.handleChange(event)}
                  />



                  <Field
                    name="password_confirmation"
                    // icon={this.state.icon}
                    type="password"
                    id="password_confirmation"
                    validate={[required, passwordValidation]}
                    placeholder={`Re-enter new Password`}
                    component={this.renderPasswordReyType}
                    // onChange={this.isPasswordMatch}
                    onChange={(event) => this.handleChange(event)}
                  />

                  <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span> Reset</button>

                </form>

              </div>
              <p className="footer-text">Do have an Account! <Link to={PATHS.LOGIN} variant="body2">Login Here</Link></p>
            </div>
          </div>

        </div>
        <Bottom />

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetPswdResponse: state.service.resetPswdResponse,
    isLoading: state.service ? state.service.isLoading : ""
  };
};

export default connect(mapStateToProps, {
  setLoader,
  resetPswd,
  reset
})(reduxForm({ form: "resetPasswordForm" })(ResetPassword));
