// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderEmail } from "../../constants/redux-form";
import { required, email } from "../../constants/validation";
import { PATHS } from "../../constants";
import * as Styled from "../page.styled";
import { Bottom } from "../includes";
import { setLoader, userLogin } from "../../actions";
import { LoaderScreen } from "../includes";

/*
 TYPES
*/

type Props = {
  history: Object,
  handleSubmit: Function,
  setLoader: Function
};

class Login extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      icon: 'fas fa-eye',
      type: 'password',
      hidden: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }


  componentDidMount() {
    this.props.setLoader(false);
    if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'notAutherised') {
      this.props.history.push(PATHS.HOME);
    }
  }

  onSubmit = formValue => {

    const rmCheck = document.getElementById("rememberMe"),
      emailInput = document.getElementById("email"),
      passwordInput = document.getElementById("password");

    if (rmCheck.checked && emailInput.value !== "" && passwordInput.value !== "") {
      localStorage.username = emailInput.value;
      localStorage.password = passwordInput.value;
      localStorage.checkbox = rmCheck.value;
    } else {
      this.props.reset('LoginForm');
      localStorage.username = "";
      localStorage.checkbox = "";
      localStorage.password = "";
    }

    this.props.setLoader(true);
    const email = formValue.email;
    const password = formValue.password;

    setTimeout(() => {
      this.props.userLogin({ email, password }).then(result => {
        this.props.setLoader(false);
        if (this.props.response) {
          if (this.props.response.success) {
            this.props.setLoader(false);
            if (this.props.response.data) {
              localStorage.setItem('current profile', this.props.response.data.profile_id)
              this.props.history.push(PATHS.HOME)
            }
          } else {
            this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-danger" });
            this.props.setLoader(false);
          }
        }

        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);
      });
    }, 500);
  };


  handleChange(e) {
  }

  redirectSignup = () => {
    localStorage.clear();
    this.props.history.push(PATHS.REGISTER);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  renderPassword1 = ({ placeholder, id, input, icon, label, type, meta: { touched, error } }: Props) => (
    <div className="input-group mb-3">
      <input  {...input} type={this.state.hidden ? "password" : "text"} placeholder={placeholder} className="form-control" id={id} autocomplete="off" />

      <span className="password-trigger"><Link to="#" onClick={this.toggleShow}><i className={this.state.hidden ? "fas fa-eye-slash" : "fas fa-eye"}></i></Link></span>

      {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
  );


  renderCheckBox = ({ placeholder, id, input, type, meta: { touched, error } }: Props) => {
    return (
      <div className="remember">
        <input {...input} type={type} placeholder={placeholder} id={id} className="css-checkbox" autocomplete="off" />
        <label for="rememberMe" className="css-label"> Remember me</label>
      </div>
    );
  }



  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="form-container small-form-page pb-0">
        <div className="inner-page-h container pt-form">
          <Link to={PATHS.HOME} className="inner-back-link">
            <i className="fas fa-angle-left mr-2"></i>
                      Back
          </Link>
          <div className="form-body">
            <div className="form-content">
              <div className="form-header mb-4">
                <Link to={PATHS.HOME}>
                  <img src="/assets/img/logo.svg" className="inner-logo img-fluid" alt="Logo Images" />
                </Link>

              </div>
              <div className="tc-heading">
                  <h2>Sign In</h2>
                </div>
              <div className="trans-card p-4 mb-4">
                {this.state.alertMessage ? (
                  <div className={this.state.alertClass} role="alert">
                    {this.state.alertMessage}
                  </div>
                ) : null}

               

                <form
                  name="LoginForm"
                  onSubmit={this.props.handleSubmit(this.onSubmit)}
                  className="form"
                >
                  <Field name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderEmail} />
                  <Field name="password" type="password" onChange={(event) => this.handleChange(event)} validate={[required]}
                    placeholder={`Password`} component={this.renderPassword1} id="password" />
                  <div>

                    <div className="rem-psw">
                      <Field name="rememberMe" type="checkbox" onChange={(event) => this.handleChange(event)} component={this.renderCheckBox} id="rememberMe" />
                      <div className="forget-pswd-text">
                        <Link to={PATHS.FORGETPASSWORD} variant="body2">
                          Forgot password?
                          </Link>
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span> Submit</button>

                </form>

              </div>
              <p className="footer-text">Don't have an Account? <Link onClick={this.redirectSignup} variant="body2">Sign up here</Link></p>
            </div>
          </div>

        </div>
        <Bottom />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const formValue = { email: undefined, password: undefined, rememberMe: undefined };

  if (localStorage.checkbox !== "" && localStorage.checkbox !== undefined) {
    formValue.rememberMe = 'on';
    formValue.email = localStorage.username;
    formValue.password = localStorage.password;
  }
  return {
    response: state.service ? state.service.response : "",
    isLoading: state.service ? state.service.isLoading : "",
    initialValues: formValue
  };
};

export default connect(mapStateToProps, {
  setLoader,
  userLogin
})(reduxForm({ form: "loginForm" })(Login));
