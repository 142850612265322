// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { PATHS } from "../../constants";
import { setLoader, manageProfile, deleteProfile } from "../../actions";
import { Foot, Nav, LoaderScreen } from "../includes";
import * as ICON from "@material-ui/icons";

/*
 TYPES
*/

type Props = {
    history: Object,
    setLoader: Function
};

class ManageProfile extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
        };
    }

    componentDidMount() {
        this.props.setLoader(true);

        this.props.manageProfile().then(result => {
            this.props.setLoader(false);
        })
            .catch((err) => {
                this.props.setLoader(false);
            });
    }

    handleView = (id) => {
        this.props.history.push('/view-profile/' + id);
    };

    getCurrentProfile = (id, is_adult) => {
        localStorage.setItem('current profile', id);
        if (is_adult == 0) {
            window.location.href = "/store/cartoon";
        } else {
            window.location.href = "/";
        }
    }

    handleEdit = (id) => {
        this.props.history.push('/edit-profile/' + id);
    };

    handleUserEdit = (id) => {
        this.props.history.push('/user-edit-profile/' + id);
    }

    // handleDelete = (id) => {
    //     if (window.confirm('Are you sure to delete this user?')) {
    //         this.props.setLoader(true);
    //         this.props.deleteProfile(id).then(result => {
    //             if (this.props.deleteProfileResponse.success) {
    //                 this.props.setLoader(false);
    //                 this.setState({ alertMessage: this.props.deleteProfileResponse.message, alertClass: "alert alert-success" });
    //                 setTimeout(() => {
    //                     this.props.setLoader(true);
    //                     this.props.manageProfile().then(result => {
    //                         this.props.setLoader(false);
    //                     })
    //                         .catch((err) => {
    //                             this.props.setLoader(false);
    //                             console.log(err);
    //                         });
    //                 }, 3000);

    //             } else {
    //                 this.setState({ alertMessage: this.props.deleteProfileResponse.message, alertClass: "alert alert-danger" });
    //             }

    //             setTimeout(() => {
    //                 this.setState({ alertMessage: "", alertClass: "" })
    //             }, 3000);

    //         })
    //             .catch((err) => {
    //                 this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
    //                 setTimeout(() => {
    //                     this.setState({ alertMessage: "", alertClass: "" })
    //                 }, 3000);

    //                 console.log(err);
    //             });
    //     }
    // };

    render() {
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.HOME)
        }

        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        return (
            <div className="form-container small-form-page pb-0">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-page-h container pt-form">
                    
                    <div className="row justify-content-center">

                        <div className="col-md-12">
                       
                            <div className="form-header mb-4">
                                <div className="tc-heading">
                                    <h2 className="mb-3 mb-md-2">Who's watching?</h2>
                                    {this.props.userList ? this.props.userList.get_profiles.length >= 5 ? ''
                                        : <Link to={PATHS.PROFILE} className="add-new-profile mb-2">
                                            <i className="fas fa-plus mr-2"></i>
                                            Add New Profile
                                        </Link>
                                        : ''}
                                </div>
                            </div>
                        </div>

                        {/* {this.props.userList ?


                            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                                <div className="trans-card users-box p-4 user-box-highlight">
                                    <button
                                        // title={this.props.userList.full_name}
                                        data-id={this.props.userList.id}
                                        onClick={() => this.getCurrentProfile(this.props.userList.id)}
                                        className="user-container"
                                    >
                                        <div className="profile-image img-center mb-3" >
                                            {/* {this.props.userList.avatar_url !== null ? <img src={this.props.userList.avatar_url} className="rounded-circle img-center" width='100' height='100' alt={this.props.userList.title} /> : <img src={'assets/img/adult-3.png'} class="rounded-circle img-center" width='100' height='100' alt="user" />} */}
                        {/* <img src={'assets/img/adult-2.png'} class="rounded-circle img-center" width='100' height='100' alt="user" />
                                        </div>

                                        <span className="user-title">{this.props.userList.full_name}</span>
                                        <span className="user-adult-title">Default</span>

                                    </button>

                                    <div className="users-box-btns">
                                        <Link
                                            className="btn red-btn"
                                            title="Edit"
                                            to={PATHS.ACCOUNT}

                                        ><ICON.Edit /> Edit profile
</Link>
                                    </div>
                                </div>
                            </div>
                            : 'No user found'}  */}

                        {/* {this.props.userList ? this.props.userList.get_profiles[0] ?
                            <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                                <div className="trans-card users-box p-4 user-box-highlight">
                                    <button
                                        title={this.props.userList.get_profiles[0].title}
                                        data-id={this.props.userList.get_profiles[0].id}
                                        onClick={() => this.getCurrentProfile(this.props.userList.get_profiles[0].id)}
                                        className="user-container"
                                    >
                                        <div className="profile-image img-center mb-3" >
                                            {this.props.userList.get_profiles[0].avatar_url !== null ? <img src={this.props.userList.get_profiles[0].avatar_url ? PATHS.BASEPATH + `/${this.props.userList.get_profiles[0].avatar_url}` : '/assets/img/adult-3.png'}
                                                className="rounded-circle img-center" width='100' height='100' alt={this.props.userList.get_profiles[0].title} /> : <img src={'/assets/img/adult-3.png'} class="rounded-circle img-center" width='100' height='100' alt="user" />}

                                        </div>
                                        <span className="user-title">{this.props.userList.get_profiles[0].title}</span>
                                        <span className="user-adult-title">Default</span>
                                    </button>
                                </div>
                            </div>
                            : 'No user found' : ''} */}


                        {this.props.userList ? this.props.userList.get_profiles ? this.props.userList.get_profiles.map((user, index) => (
                            <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                                <div className="trans-card users-box p-4 user-box-highlight">
                                    <button
                                        title={user.title}
                                        data-id={user.id}
                                        onClick={() => this.getCurrentProfile(user.id, user.is_adult)}
                                        className="user-container"
                                    >
                                        <div className="profile-image img-center mb-3" >
                                            {user.avatar_url !== null ? <img src={user.avatar_url ? PATHS.BASEPATH + `/${user.avatar_url}` : '/assets/img/adult-3.png'}
                                                className="rounded-circle img-center" width='100' height='100' alt={user.title} /> : <img src={'/assets/img/adult-3.png'} className="rounded-circle img-center" width='100' height='100' alt="user" />}

                                        </div>
                                        <span className="user-title">{user.title.length < 16 ? `${user.title}` : `${user.title.substring(0, 20)}...`}</span>
                                        {/* {index == 0 ? <span className="user-adult-title">Default</span> : <span className="user-adult-title">{user.is_adult == 1 ? <img src="/assets/img/icon_check_wrong.png" width='10px' /> : <img src="/assets/img/icon_check_right.png" width='10px' />} kids Profile</span>} */}

                                        {<span className="user-adult-title">{user.is_adult == 1 ? <img src="/assets/img/icon_check_wrong.png" width='10px' /> : <img src="/assets/img/icon_check_right.png" width='10px' />} kids Profile</span>}

                                    </button>
                                    {/* {index == 0 ? <button
                                            className="btn red-btn"
                                            title="Edit"
                                            data-id={user.id}
                                            onClick={() => this.handleUserEdit(user.id)}
                                        ><ICON.Edit /> Edit profile
                                        </button> : <div className="users-box-btns">
                                        <button
                                            className="btn red-btn"
                                            title="Edit"
                                            data-id={user.id}
                                            onClick={() => this.handleEdit(user.id)}
                                        ><ICON.Edit /> Edit profile
                                        </button> */}

                                    {/* <button
                                            className="action"
                                            onClick={() => { this.handleDelete(user.id) }}
                                            title="Delete"
                                        ><i className="far fa-trash-alt" aria-hidden="true" title="Delete"></i>
                                        </button> */}

                                    {/* </div>} */}
                                    <div className="users-box-btns">
                                        <button
                                            className="btn red-btn"
                                            title="Edit"
                                            data-id={user.id}
                                            onClick={() => this.handleEdit(user.id)}
                                        ><ICON.Edit /> Edit profile
                                        </button>
                                    </div>

                                </div>
                            </div>
                        )) : 'No user found' : ''}


                    </div>

                </div>
                <Foot />
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        userList: state.service.manageProfileResponse ? state.service.manageProfileResponse.data : "",
        isLoading: state.service ? state.service.isLoading : "",
        deleteProfileResponse: state.service.deleteProfileResponse
    };
};

export default connect(mapStateToProps, {
    setLoader,
    manageProfile,
    deleteProfile
})(ManageProfile);