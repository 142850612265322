export const DEFAULT_LANGUAGE = "en-us";
export const DEFAULT_COUNTRY_CODE = "us";
export const SUBMODULE_NAME = "vai";

const API_BASE_PATH = "https://admin.viewafricainternational.com/api/";


export const PATHS = {
  VIDEO_IMAGE_PATH: 'https://admin.viewafricainternational.com',
  BASEPATH: 'https://admin.viewafricainternational.com',
  API_PATH: "https://admin.viewafricainternational.com/api/",
  HOME: '/',
  LOGIN: "/sign-in",
  REGISTER: '/sign-up',
  SIGNUP_MOBILE: '/sign-up-mobile',
  FORGETPASSWORD: "/forget-password",
  RESET_PASSWORD: '/reset-password/:token',
  CONTACT: '/contact-us',
  ABOUT: '/about-us',
  CORPORATION_INFO: '/corporation-information',
  LEGAL_NOTICE: '/legal-notice',
  FAQ: '/faq',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS: '/terms-of-use',
  PROFILE: '/profile',
  EDIT_PROFILE: '/edit-profile/:id',
  DASHBOARD: '/store/:id',
  HELP_CENTRE: '/help-centre',
  VIEW_PROFILE: '/view-profile/:id',
  MANAGE_PROFILE: '/manage-profile',
  LOGOUT: "/logout",
  SEARCH: '/search',
  NOTIFICATION: '/notifications',
  ACCOUNT: '/account',
  SETTING: '/setting',
  SUBSCRIPTION: '/subscription',
  WISHLIST: '/watchlist',
  SEARCH_HISTORY: '/search-history',
  VIDEO_HISTORY: '/history',
  VIDEO_DETAIL: '/details/:videoSlug/:videoType',
  VIDEO_SERIES: '/series/:videoSlug/',
  VIDEO_CATEGORY: '/category/:categoryName/:id',
  TVSHOW: "/store/tvshows",
  MUSIC: "/store/music",
  MOVIE: "/store/movies",
  SERIES: '/store/web-series',
  PAGE_NOT_FOUND: "/errors/404",
  EDIT_DEFAULT_PROFILE: '/user-edit-profile/:id',
  SIGNUP_SUCCESS: '/signup-success',
  PAYMENT_HISTORY: '/payment-history',
  RENEW_MEMBERSHIP: '/update-plan',
  CONFIRM_ACCOUNT: '/confirm-account/:token',
  SIGNUP_MOBILE_SUCCESS: '/signup-mobile-success',
  PRODUCER: '/producer'
};

export const PAYPAL_PAYMENT = {
 // CLIENT_ID: "AcyISCT2Xv9hdZAO0zibgeO83j0OPZWo77G8i683dRI6U__RLwcluPMO_u3z6jph2RA70zXirxcVyGWA"
 CLIENT_ID: "AcESjQ-Bx3vx__q-EOg6Gt0vHw14_vC-_FZQ6Bfu98SI0JPy74jmzfkY-CbdYGdX5jAGC2etdcMa6Gta"
}

export const API_PATHS = {
  CONTACT_US: `${API_BASE_PATH}store-enquiries`,
  FAQ: `${API_BASE_PATH}get-content/faq`,
  TERMS_OF_USE: `${API_BASE_PATH}get-content/terms-of-use`,
  PRIVACY_POLICY: `${API_BASE_PATH}get-content/privacy-policy`,
  HELP_CENTRE: `${API_BASE_PATH}get-content/help-center`,
  USER_LOGIN_API: `${API_BASE_PATH}user/login`,
  PLAN_LIST: `${API_BASE_PATH}user/plan-lists`,
  FORGOT_PSWD_API: `${API_BASE_PATH}user/forgetpassword`,
  ADD_PROFILE_API: `${API_BASE_PATH}user/addProfile`,
  VIEW_PROFILE: `${API_BASE_PATH}user/viewProfile`,
  MANAGE_PROFILE: `${API_BASE_PATH}user/getprofile`,
  UPDATE_PROFILE_API: `${API_BASE_PATH}user/updateProfile`,
  DELETE_PROFILE: `${API_BASE_PATH}user/deleteProfile`,
  VIDEO_LIST: `${API_BASE_PATH}get-dashboard-data`,
  VIDEO_SEARCH: `${API_BASE_PATH}video/videoSearch`,
  VIDEO_NOTIFICATION: `${API_BASE_PATH}video/getNotification`,
  RESET_PSWD_API: `${API_BASE_PATH}user/resetPassword`,
  GET_USER_PROFILE_API: `${API_BASE_PATH}user/getprofile`,
  UPDATE_USER_PROFILE_API: `${API_BASE_PATH}user/updateUserProfile`,
  VERIFY_EMAIL_REGISTER_API: `${API_BASE_PATH}user/register`,
  USER_WISHLIST_API: `${API_BASE_PATH}video/userWatchlist`,
  SEARCH_HISTORY_API: `${API_BASE_PATH}video/searchingHistory`,
  VIDEO_HISTORY_API: `${API_BASE_PATH}video/videoHistory`,
  ADD_TO_WATCHLIST: `${API_BASE_PATH}video/addWatchList`,
  UNREAD_NOTIFICATION: `${API_BASE_PATH}user/getUnreadNotification`,
  VIDEO_CATEGORY_API: `${API_BASE_PATH}video/get-category-videos`,
  ABOUT_US_API: `${API_BASE_PATH}get-content/about-us`,
  CORPORATE_INFO_API: `${API_BASE_PATH}get-content/corporate-information`,
  LEGAL_NOTICE_API: `${API_BASE_PATH}get-content/legal-notices`,
  REMOVE_WATCHLIST_API: `${API_BASE_PATH}video/removeUserWatchlist`,
  CREATE_PASSWORD: `${API_BASE_PATH}user/createPassword`,
  VIDEO_INFO_API: `${API_BASE_PATH}video/get-video-info`,
  HEADER_MENU_API: `${API_BASE_PATH}get-header-menu`,
  REMOVE_WATCHLIST_VIDEO_API: `${API_BASE_PATH}video/remove-watch-list-video`,
  ADD_TO_VIDEO_HISTORY_API: `${API_BASE_PATH}video/add-user-videos-history`,
  REMOVE_VIDEO_FROM_HISTORY: `${API_BASE_PATH}video/remove-user-video-history`,
  ADD_USER_PLAN_API: `${API_BASE_PATH}user/add-user-plan`,
  PLAN_DETAIL_API: `${API_BASE_PATH}user/plan-type-details`,
  UPDATE_PLAN_API: `${API_BASE_PATH}user/updatePlan`,
  GET_USER_PAYMENT_API: `${API_BASE_PATH}user/plan-details`,
  PAYMENT_HISTORY_API: `${API_BASE_PATH}user/payment-history`,
  END_MEMBERSHIP_API: `${API_BASE_PATH}user/cancel-plan`,
  PAYMENT_DETAIL_API: `${API_BASE_PATH}user/save-payment-details`,
  CONFIRM_USER_ACCOUNT_API: `${API_BASE_PATH}user/confirm-account`,
  UPGRADE_PLAN_API: `${API_BASE_PATH}user/change-plan`,
  LOGOUT_API: `${API_BASE_PATH}user/logout`,
};