// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";

import { Nav, Foot } from "../includes";
import * as ICON from "@material-ui/icons";


/*
TYPES
*/
type Props = {
    history: Object
};

class Setting extends Component<Props> {
   
    render() {
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN);
        }

        return (
            <div className="black-body">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading">
                            <h2>Account & Settings</h2>
                        </div>
                    </div>
                    
                </div>
                <div className="page-min-h homepage inner-pt">
                    <div className='container'>
                        <ul className="list-group list-group-flush">
                            <div className="white-box p-3 mb-3 ac-setting">
	                            <div className="pr-3">
		                            <h2>Your details</h2>
		                            <p>Change your Name, Mobile number, password, and more.</p>
	                            </div>
	                            <div>
	                                <Link to={PATHS.ACCOUNT} className="btn dark-btn"title="Edit"><ICON.Edit /> Edit </Link>
	                            </div>
	                        </div>
                            <div className="white-box p-3 mb-3 ac-setting">
	                            <div className="pr-3">
	                             	<h2>Subscription</h2>
	                                <p>Changes to your memebership</p>
	                            </div>
	                            <div> 
	                            	<Link to={PATHS.SUBSCRIPTION} className="btn dark-btn" title="Edit"><ICON.Edit /> Edit </Link>
	                            </div>
	                        </div>
                        </ul>
                    </div>

                </div>
                <Foot/>
            </div>
        );
    }
}

export default Setting;