import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { PATHS } from "./constants";
import Login from "./pages/login";
import {Register, SignupSuccess, ConfirmAccount, SignupMobile, SignupMobileSuccess} from './pages/register';
import Forgetpassword from "./pages/forgetpassword";
import ResetPassword from './pages/reset';
import Contact from "./pages/contact";
import { Home, About, CorporateInfo, LegalNotice, Faq, PrivacyPolicy, Terms, Help, Producer } from './pages/home';
import { Profile, Editprofile, ViewProfile, ManageProfile, EditDefaultProfile } from './pages/profile';
import Dashboard from './pages/dashboard';
import Logout from "./pages/logout";
import {Search, Wishlist, SearchHistory, VideoHistory} from './pages/search';
import Notification from './pages/notification';
import {Account, Setting, Subscription, PaymentHistory, RenewMembership} from './pages/account';
import VideoDetail from './pages/videodetail';
import VideoSeries from './pages/videoSeries';
import VideoCategory from './pages/videocategory';
import Error404 from "./pages/errors/404";
import XML from './sitemap.xml';    
import './App.css';

class App extends Component<Props> {

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path={PATHS.HOME} component={Home} />
          <Route exact path={PATHS.LOGIN} component={Login} />
          <Route path={PATHS.REGISTER} component={Register} />
          <Route path={PATHS.SIGNUP_SUCCESS} component={SignupSuccess} />
          <Route path={PATHS.SIGNUP_MOBILE_SUCCESS} component={SignupMobileSuccess} />
          <Route path={PATHS.CONFIRM_ACCOUNT} component={ConfirmAccount} />
          <Route path={PATHS.SIGNUP_MOBILE} component={SignupMobile} />
          <Route path={PATHS.SIGNUP_MOBILE} component={SignupMobile} />
          <Route path={PATHS.FORGETPASSWORD} component={Forgetpassword} />
          <Route path={PATHS.RESET_PASSWORD} component={ResetPassword} />
          <Route path={PATHS.CONTACT} component={Contact} />
          <Route path={PATHS.ABOUT} component={About} />
          <Route path={PATHS.CORPORATION_INFO} component={CorporateInfo} />
          <Route path={PATHS.LEGAL_NOTICE} component={LegalNotice} />
          <Route path={PATHS.FAQ} component={Faq} />
          <Route path={PATHS.PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route path={PATHS.HELP_CENTRE} component={Help} />
          <Route path={PATHS.PRODUCER} component={Producer} />
          <Route path={PATHS.TERMS} component={Terms} />
          <Route path={PATHS.PROFILE} component={Profile} />
          <Route path={PATHS.EDIT_PROFILE} component={Editprofile} />
          <Route path={PATHS.VIEW_PROFILE} component={ViewProfile} />
          <Route path={PATHS.MANAGE_PROFILE} component={ManageProfile} />
          <Route path={PATHS.EDIT_DEFAULT_PROFILE} component={EditDefaultProfile} />
          <Route path={PATHS.DASHBOARD} component={Dashboard} />
          <Route exact path={PATHS.LOGOUT} component={Logout} />
          <Route path={PATHS.SEARCH} component={Search} />
          <Route path={PATHS.NOTIFICATION} component={Notification} />
          <Route path={PATHS.ACCOUNT} component={Account} />
          <Route path={PATHS.SETTING} component={Setting} />
          <Route path={PATHS.SUBSCRIPTION} component={Subscription} />
          <Route path={PATHS.PAYMENT_HISTORY} component={PaymentHistory} />
          <Route path={PATHS.RENEW_MEMBERSHIP} component={RenewMembership} />
          <Route path={PATHS.WISHLIST} component={withRouter(Wishlist)} />
          <Route path={PATHS.SEARCH_HISTORY} component={SearchHistory} />
          <Route path={PATHS.VIDEO_HISTORY} component={VideoHistory} />
          <Route path={PATHS.VIDEO_DETAIL} component={VideoDetail} />
          <Route path={PATHS.VIDEO_SERIES} component={VideoSeries} />
          <Route path={PATHS.VIDEO_CATEGORY} component={VideoCategory} />
          <Route component={XML} path={'/sitemap.xml'} />
          <Route component={Error404} />
        </Switch>
      </Router>
    );
  }
}
export default App;
