// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { Nav, Foot } from "../includes";
import * as ALL from "@material-ui/core";
import { connect } from 'react-redux';
import { getSearchHistory, setLoader } from "../../actions";
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';


class SearchHistory extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    componentDidMount() {
        this.props.setLoader(true);
        let profile_id = localStorage.getItem('current profile');
        this.props.getSearchHistory(profile_id).then(result => {
            this.props.setLoader(false);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    render() {

        return (
            <div className="black-body">
                <Nav />
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading">
                            <h2>Search history...</h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid page-min-h page-carousel btm-shadow mt-4">

                    <div className="row">
                        <div className="col-md-12 pt-3 pt-md-4 pt-lg-5 pb-3 pb-md-4 mt-4">
                            <div className="search-container">
                                {this.props.searchHistoryResponse ? this.props.searchHistoryResponse.length !== 0 ? this.props.searchHistoryResponse.map(data => (
                                    // <div className="item">
                                    //     <div className="sub-item">
                                    //         <Link to="#">
                                    //             {/* <span className="paid-label"> Paid </span> */}
                                    //             <img src={PATHS.VIDEO_IMAGE_PATH + `/${data.video_thumnail_path}`} alt="Poster" className="img-fluid" />
                                    //             <h4>{data.title}</h4>
                                    //         </Link>
                                    //         <div className="hide-item">

                                    //             <div className="video-display">
                                    //                 <div>
                                    //                     <Link to="#" onClick={this.handleClickOpen}>
                                    //                         <i className="fa fa-play play-icon-margin" aria-hidden="true" title="Watch"></i>
                                    //                     </Link>
                                    //                     <Link to="#">
                                    //                         <i className="fa fa-plus" aria-hidden="true" title="Add to Watchlist"></i>
                                    //                     </Link>
                                    //                 </div>
                                    //             </div>

                                    //             {/* <h1 className='video-title'>{data.title}</h1> */}
                                    //             <p className="video-des">{ReactHtmlParser(data.description)}</p>
                                    //             <div className="video-time">
                                    //                 <span className="video-duration">1h 40min</span>
                                    //                 <span className="video-duration">2010</span>
                                    //                 <div className="ml-auto"><span className="subscribers" title="Subscribers">{data.age_group}+</span></div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    //     <ALL.Dialog fullScreen open={this.state.open}>
                                    //         <div className="video-header">
                                    //             <ALL.AppBar >
                                    //                 <ALL.Toolbar>

                                    //                     <ALL.IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    //                         <CloseIcon />
                                    //                     </ALL.IconButton>

                                    //                 </ALL.Toolbar>
                                    //             </ALL.AppBar>
                                    //         </div>
                                    //         <div className='player-wrapper'>
                                    //             <ReactPlayer
                                    //                 className='react-player'
                                    //                 url={PATHS.VIDEO_IMAGE_PATH + `/${data.video_path}`}
                                    //                 width='100%'
                                    //                 height='100%'
                                    //                 controls={true}
                                    //             />
                                    //         </div>

                                    //     </ALL.Dialog>
                                    // </div>
                                    <li>{data.search_key}</li>
                                )) : 'No search history found' : ''}
                            </div>

                        </div>
                    </div>
                </div>
                <Foot />
            </div>

        );
    }
}

const mapStateToProps = state => {
    console.log(state.service.searchHistoryResponse)
    return {
        searchHistoryResponse: state.service.searchHistoryResponse ? state.service.searchHistoryResponse.data : '',
        isLoading: state.service.isLoading,
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getSearchHistory
})(SearchHistory);
