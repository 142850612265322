// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import {
    setLoader,
    getUserPayment,
    endMembership
} from "../../actions";
import { LoaderScreen, Foot, Nav } from "../includes";
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';


/*
TYPES
*/


type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class Subscription extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            alertMessage: "",
            alertClass: ""
        };
    }

    componentDidMount() {
        this.props.setLoader(true);
        this.props.getUserPayment().then(result => {
            if (this.props.userPayment && this.props.userPayment.success) {
                this.props.setLoader(false);
            }
        });
    }

    handleMembership = (subscriptionId) => {
        this.props.setLoader(true);
        let successMessage = "";
        let errorMessage = "";
        let subscription_id = subscriptionId;

        this.props.endMembership(subscription_id).then(result => {
            if (this.props.endMembershipResponse.success) {
                this.props.setLoader(false);
                successMessage = this.props.endMembershipResponse.message;
                this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
                this.props.getUserPayment().then(result => {
                    if (this.props.userPayment.success) {
                        this.props.setLoader(false);
                    }
                });
            } else {
                this.props.setLoader(false);
                errorMessage = this.props.endMembershipResponse.message;
                this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
    }

    render() {
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN);
        }


        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        return (
            <div className="black-body">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading sb-pt">
                            <h2>Manage your prime membership</h2>
                        </div>
                    </div>
                   
                </div>
                <div className="page-min-h homepage inner-pt">

                    <div className='container'>
                        {this.state.alertMessage ? (
                            <div className={this.state.alertClass} role="alert">
                                {this.state.alertMessage}
                            </div>
                        ) : null}
                        {this.props.userPlanDetail && this.props.userPayment  ? <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="subs-profile mb-3">
                                    {this.props.userPlanDetail ? this.props.userPlanDetail.user_data ? <span className='d-flex'><img src={this.props.userPlanDetail.user_data.user_avatar_url !== null ? PATHS.BASEPATH + this.props.userPlanDetail.user_data.user_avatar_url : "/assets/img/adult-2.png"} alt="user-avatar" /><h2>Hello! <br />{this.props.userPlanDetail.user_data.full_name}</h2></span> : ''
                                        : ''}


                                </div>

                                <div className="subscription-box mb-3">
                                    <div>
                                        <div>
                                            <h6>{this.props.userPayment ? this.props.userPayment.title : ''}</h6>
                                            <p>
                                                <i className="far fa-calendar-alt"></i>
                                                <strong className="text-muted">Start Date: <span>{this.props.paymentDetail ? moment(this.props.paymentDetail.plan_purchased_time).format("MMM D YYYY") : ''}</span></strong>
                                            </p>
                                            <p>
                                                <i className="far fa-calendar-alt"></i>
                                                <strong className="text-muted">Expiry Date: <span>{this.props.paymentDetail ? moment(this.props.paymentDetail.next_billing_time).format("MMM D YYYY") : ''}</span></strong>
                                            </p>
                                            <p>
                                                <i className="fas fa-money-bill-alt"></i>
                                                <strong className="text-muted">Price: <span>{this.props.userPayment ? this.props.userPayment.currency_type : ''}{this.props.userPayment ? this.props.userPayment.price : ''}</span></strong>
                                            </p>
                                            <p>
                                                <strong className="text-muted"> Description: <span>{this.props.userPayment ? ReactHtmlParser(this.props.userPayment.description) : ''}</span></strong>
                                            </p>
                                        </div>

                                    </div>

                                </div>
                                <ul className="list-group mb-3">

                                    <li className="list-group-item d-flex justify-content-between lh-condensed">

                                        <div className='payment-container'>
                                            <div className='payment-img'><img src="/assets/img/payment-history-icon.png" width='30px' alt="payment-history" /></div>

                                            <div>
                                                <h6>Payment history</h6>

                                                <span className='renew-plan'><Link to={PATHS.PAYMENT_HISTORY}>Payment History</Link></span>
                                            </div>

                                        </div>

                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-condensed">



                                        <div className='payment-container'>
                                            <div className='payment-img'><img src="/assets/img/service-period-icon.png" width='30px' alt='service-period' /></div>
                                            <div>
                                                <h6>Service period</h6>
                                                <span className="text-muted">{this.props.paymentDetail ? moment(this.props.paymentDetail.plan_purchased_time).format("MMM D YYYY") : ''} - {this.props.paymentDetail ? moment(this.props.paymentDetail.next_billing_time).format("MMM D YYYY") : ''}</span>

                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-condensed">

                                        <div className='payment-container'>
                                            <div className='payment-img'><img src="/assets/img/renewal-and-next-payment-icon.png" width='30px' alt="renewal-and-next-payment" /></div>
                                            <div>
                                                <h6>Renewal and Next payment</h6>

                                                {this.props.paymentDetail ? <span><span className="text-muted">{this.props.paymentDetail.message}</span></span> : <span className="text-muted">A reminder email/SMS for this auto-charge will be sent to you on {this.props.paymentDetail ? moment(this.props.paymentDetail.next_billing_time).format("MMM D YYYY") : ''}</span>}
                                                <br />

                                              {this.props.userPlanDetail.showLink == true ? <span className='renew-plan'><Link to={PATHS.RENEW_MEMBERSHIP}>{this.props.userPlanDetail && this.props.userPlanDetail.btnShow == true ? 'Activate plan' : 'Renew plan'}</Link></span> : ''}  
                                            </div>
                                        </div>
                                    </li>

                                </ul>

                                {this.props.paymentDetail && this.props.userPlanDetail && this.props.userPlanDetail.btnShow == false ? <Link to="#" onClick={() => { this.handleMembership(this.props.paymentDetail.subscription_id) }} className="btn btn-block btn-red mb-3">End Membership</Link> : ''}


                            </div>
                            <div className="col-md-6 col-lg-8 mb-3">
                                <div className='payment-container white-box'>
                                    <div className='payment-img'><img src="/assets/img/your-membership-features-icon.png" width='30px' alt='your-membership-features' /></div>
                                    <div className="payment-content">
                                        <h6>Your Memebership Features</h6>
                                        {this.props.userPayment ? this.props.userPayment.feature ? <ul className="feature-list mb-3"> {this.props.userPayment.feature.map((feature, index) => (
                                            <li key={index} className="">  <span className="text-muted"><img src="/assets/img/icon_check_right.png" width='20px' alt="right-check" /></span>
                                                <div>
                                                    {/* <h4>{feature.title} - {(feature.title == 'View Access' && feature.value == 3) ? 'Mobile' : (feature.title == 'View Access' && feature.value != 3) ? 'Full' : feature.value}</h4> */}
                                                    <h4>{feature.title} - {feature.value}</h4>
                                                    {/*  <span className="text-muted">Lorem ipsum is ato demonstrate.</span>*/}
                                                </div>
                                            </li>
                                        ))}</ul> : '' : ''}
                                    </div>
                                </div>


                            </div>
                        </div> : <div className="white-box p-3 mb-3 ac-setting">
                                <div className="pr-3">
                                    <h2>No active paid plan yet please activate a paid plan or go subscribe for watching paid movies and webseries.</h2>
                                    <p className='renew-plan'><Link to={PATHS.RENEW_MEMBERSHIP}>Activate plan</Link></p>
                                </div>

                            </div>}

                    </div>

                </div>
                <Foot />
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        isLoading: state.service ? state.service.isLoading : "",
        userPayment: (state.service.userPaymentResponse) ? state.service.userPaymentResponse.data : "",
        endMembershipResponse: state.service.endMembershipResponse ? state.service.endMembershipResponse : '',
        paymentDetail: (state.service.userPaymentResponse) ? state.service.userPaymentResponse.payment : "",
        userPlanDetail: (state.service.userPaymentResponse) ? state.service.userPaymentResponse : "",
    };
};
export default connect(mapStateToProps, {
    setLoader,
    getUserPayment,
    endMembership,
})(Subscription);