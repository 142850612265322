import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { required, email } from "../../constants/validation";
import { renderEmail2 } from "../../constants/redux-form";
import { setLoader, emailVerifyRegister } from "../../actions";

import { PATHS } from "../../constants";

/*
 TYPES
*/

type Props = {
  handleSubmit: Function,
};

class Footer extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      alertClass: ""
    };
  }

  step1validate = formValue => {

    setTimeout(() => {
      if (formValue.email) {
        this.props.emailVerifyRegister(formValue.email).then(result => {
          if (this.props.verifyEmailResponse) {
            if (this.props.verifyEmailResponse.success) {
              localStorage.setItem('vai_user_email', formValue.email);
              localStorage.setItem('vai_stage', 2);
              window.location.href = "/sign-up";
            } else {
              this.setState({ alertMessage: this.props.verifyEmailResponse.message, alertClass: "alert alert-success" });
            }

            setTimeout(() => {
              this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);
          }
        }).catch(e => {
          this.props.setLoader(false);
        });
      }
    }, 500);



  };
  render() {

    return (
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
              <div className="tryIt-now py-2 py-md-3 py-lg-5 mb-3 mb-lg-5 mt-4">



                <h3 className="mb-3">Try it now!</h3>
                <p>Ready to watch? Enter your email to create or restart your membership.</p>

                {this.state.alertMessage ? (
                  <div className={this.state.alertClass} role="alert">
                    {this.state.alertMessage}
                  </div>
                ) : null}

                {!localStorage.getItem('vai_user_email') ? <form
                  name="verifyEmailForm"
                  onSubmit={this.props.handleSubmit(this.step1validate)}

                >

                  <Field name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderEmail2} />

                </form> :
                  <Link to={PATHS.REGISTER} className="blue-big-btn btn-shadow try-btn mb-4">
                    <span>Finish Sign Up</span>
                    <i className="fas fa-chevron-right"></i>
                  </Link>}
                <div className="store-btns">
                  <Link to={PATHS.PRODUCER}><img src="/assets/img/producer.gif" alt="producer" className="img-fluid producer-btn ml-1 mr-1" /></Link>
                  <a href="https://play.google.com/store/apps/details?id=com.vai.vai" target="blank"><img src="/assets/img/google-play-btn.png" alt="" className="img-fluid ml-1 mr-1" /></a>
                  <a href="https://apps.apple.com/us/app/view-africa-international/id1550054844" target="blank"><img src="/assets/img/app-store-btn.png" alt="" className="img-fluid ml-1 mr-1" /></a>
                  <a href="https://www.facebook.com/View-Africa-Intern-Online-Platform-107002835073339" target="blank"><img src="/assets/img/facebook.png" alt="" className="img-fluid ml-1 mr-1 fb-btn" /></a>
                </div>
                <div className="paypal-btns">
                  <Link to="#"><img src="/assets/img/paypal-cards-2.png" alt="" className="img-fluid" /></Link>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container-fluid">
            <div className="cpr-section">
              <div className="cr-links">
                <Link to={PATHS.FAQ}>FAQ</Link>
                <Link to={PATHS.HELP_CENTRE}>Help Centre </Link>
                <Link to={PATHS.TERMS}>Terms of Use </Link>
                <Link to={PATHS.PRIVACY_POLICY}>Privacy</Link>
                <Link to={PATHS.CONTACT}>Contact Us </Link>
                <Link to={PATHS.LEGAL_NOTICE}>Legal Notice </Link>
                <Link to={PATHS.CORPORATION_INFO}>Corporate Information </Link>
                <Link to={PATHS.ABOUT}>About Us </Link>
                <Link to={PATHS.PRODUCER}>Producer </Link>
              </div>
              <p className="m-0">
                © Copyright :  2020 - All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.service ? state.service.isLoading : "",
    verifyEmailResponse: state.service.verifyEmailResponse ? state.service.verifyEmailResponse : ''
  };
};

export default connect(mapStateToProps, {
  setLoader,
  emailVerifyRegister,
})(reduxForm({ form: "registerForm" })(Footer));