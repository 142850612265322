// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { Nav, Foot, LoaderScreen } from "../includes";
import * as ALL from "@material-ui/core";
import { connect } from 'react-redux';
import { getUserWishlist, setLoader, getVideoInfo, addToWatchlist, removeWatchlistVideo, addUserVideoHistory } from "../../actions";
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { animateScroll as scroll } from 'react-scroll';
import { Line } from 'rc-progress';
import Carousel2 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 5,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 2,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 3,
        partialVisibilityGutter: 30
    }
};

class VideoDetail extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            secondsElapsed: null,
            duration: null,
            openTrailer: false,
            openSubscriptionBox: false
        };
    }

    componentDidMount() {
        this.props.setLoader(true);
        let video_slug = this.props.match.params.videoSlug;
        let video_type = this.props.match.params.videoType;
        let profile_id = localStorage.getItem('current profile');
        this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
            // this.props.getVideoInfo(video_slug, profile_id).then(result => {
            if (this.props.videoInfoResponse.success) {
                this.props.setLoader(false);
            }
        })
            .catch((err) => {
                console.log(err);
            });
        scroll.scrollToTop();
    }

    handleClose = (id) => {

        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            if (this.props.addVideoHistory.success) {
                this.setState({
                    open: false
                });
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    //this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse.success) {
                        this.props.setLoader(false);
                    }
                })
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            });

    }

    handleClickOpen = (video_id, playback_time, video_path) => {
        localStorage.setItem('video_id', video_id);
        localStorage.setItem('playback_time', playback_time);
        localStorage.setItem('video_path', video_path);

        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN)
        } else {
            this.setState({
                open: true
            });
        }

    };

    handleOpenTrailer = (video_trailer_path) => {
        localStorage.setItem('video_trailer_path', video_trailer_path);
        this.setState({
            openTrailer: true
        });
    }

    handleCloseTrailer = () => {
        this.setState({
            openTrailer: false
        });
    }

    videoSeriesInfoOpen = (video_slug, video_type) => {
        let videoslug = video_slug;
        let videotype = video_type;

        if (localStorage.getItem("access_token")) {
            // this.props.history.push('/details/' + videoslug + '/' + videotype);
            window.location.href = '/details/' + videoslug + '/' + videotype;
        } else {
            this.props.history.push(PATHS.LOGIN);
        }
    }

    handleWatchlist = (id, videoType) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let video_type = videoType;

        this.props.addToWatchlist(profile_id, video_id, video_type).then(result => {
            // this.props.setLoader(true);
            if (this.props.watchlistResponse.success) {
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    // this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse) {
                        this.props.setLoader(false);
                    }
                })
                this.setState({ alertMessage: this.props.watchlistResponse.message, alertClass: "alert alert-success" });
            } else {
                this.setState({ alertMessage: this.props.watchlistResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            });

    };

    removeWatchlist = (id, videoType) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let video_type = videoType;
        this.props.removeWatchlistVideo(profile_id, video_id, video_type).then(result => {
            // this.props.setLoader(true);
            //console.log(this.props.removeWatchlistResponse)
            if (this.props.removeWatchlistResponse.success) {
                // this.props.setLoader(false);
                // this.setState({ alertMessage: this.props.removeWatchlistResponse.message, alertClass: "alert alert-success" });
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    //this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse) {
                        this.props.setLoader(false);
                    }
                })
            } else {
                this.setState({ alertMessage: this.props.removeWatchlistResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            })
    }

    handlePause = (id) => {

        this.setState({ playing: false })
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            if (this.props.addVideoHistory.success) {
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    // this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse.success) {
                        this.props.setLoader(false);
                    }
                })
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
    }

    handleDuration = (duration) => {
        this.setState({ duration })
    }

    onProgress = (progress) => {
        if (!this.state.duration) {
            return
        }

        const secondsElapsed = progress.played * this.state.duration
        if (secondsElapsed !== this.state.secondsElapsed) {
            this.setState({ secondsElapsed })
        }
    }

    subscriptionBox = () => {
        this.setState({
            openSubscriptionBox: true
        })
    }

    closeSubscriptionBox = () => {
        this.setState({
            openSubscriptionBox: false
        })
    }

    gotoSubscribePlan = () => {
        this.props.history.push(PATHS.RENEW_MEMBERSHIP)
    }

    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN)
        }

        let iscurrent = true;
        return (
            <div className="black-body">
                <Nav />

                <div className="inner-page-h">
                    <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                    {this.props.videoInfoResponse ? this.props.videoInfoResponse.videoInfo ?
                        <div>
                            <div className="video-detail mb-5">
                                <div className="video-detail-banner" style={{ backgroundImage: "url(" + PATHS.BASEPATH + '/' + this.props.videoInfoResponse.videoInfo.video_cover_image + ")" }}>
                                    <div className="container inner-pt pb-5">
                                        <div className="row">
                                            <div className="col-md-6 pt-5">
                                                <div className="vdb-content">
                                                    <h1 className="mb-3">
                                                        {this.props.videoInfoResponse.videoInfo.title}
                                                    </h1>
                                                    <div className="vdb-detail mb-4">
                                                        {/* <span>{moment(this.props.videoInfoResponse.videoInfo.created_at).format('YYYY')}</span> */}
                                                        <span>{moment(this.props.videoInfoResponse.videoInfo.release_date, 'MM-DD-YYYY').format('ll')}</span>
                                                        <span>{moment.duration(this.props.videoInfoResponse.videoInfo.length, "seconds").format(' h[h] m[min] s[s]')}</span>
                                                        <span><span className="rate-bg">{this.props.videoInfoResponse.videoInfo.age_group}</span></span>


                                                        {localStorage.getItem('isFreeAccount') == 'true' && this.props.videoInfoResponse.videoInfo.is_paid != 0 ? <span className="paid-red-label"> Premium </span> : ''}
                                                    </div>
                                                    <div>
                                                        {ReactHtmlParser(this.props.videoInfoResponse.videoInfo.description)}
                                                    </div>
                                                    {/* <div className="vdb-extra-detail mb-3">
                                                <strong>Starring:</strong><span>Ramsey Nouah,Ayo Makun,Jim Iyke</span>
                                            </div> */}

                                                    <div className="vdb-btns mb-3">
                                                        {this.props.videoInfoResponse.videoInfo.get_video_history ?
                                                            this.props.videoInfoResponse.videoInfo.get_video_history.playback_time != 0 ?


                                                                <Link onClick={localStorage.getItem('isFreeAccount') == 'true' && this.props.videoInfoResponse.videoInfo.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(this.props.videoInfoResponse.videoInfo.id, this.props.videoInfoResponse.videoInfo.get_video_history ? this.props.videoInfoResponse.videoInfo.get_video_history.playback_time : 0, this.props.videoInfoResponse.videoInfo.video_path)} className="red-btn">Continue watching</Link>
                                                                : <Link onClick={localStorage.getItem('isFreeAccount') == 'true' && this.props.videoInfoResponse.videoInfo.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(this.props.videoInfoResponse.videoInfo.id, this.props.videoInfoResponse.videoInfo.get_video_history ? this.props.videoInfoResponse.videoInfo.get_video_history.playback_time : 0, this.props.videoInfoResponse.videoInfo.video_path)} className="red-btn"><i className="fa fa-play mr-2" aria-hidden="true"></i>Play</Link>

                                                            : <Link to="#" onClick={localStorage.getItem('isFreeAccount') == 'true' && this.props.videoInfoResponse.videoInfo.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(this.props.videoInfoResponse.videoInfo.id, this.props.videoInfoResponse.videoInfo.get_video_history ? this.props.videoInfoResponse.videoInfo.get_video_history.playback_time : 0, this.props.videoInfoResponse.videoInfo.video_path)} className="red-btn"><i className="fa fa-play mr-2" aria-hidden="true"></i>Play</Link>}

                                                        {this.props.videoInfoResponse.watchlistStatus == true ? <Link to="#" onClick={() => { this.removeWatchlist(this.props.videoInfoResponse.videoInfo.id, this.props.videoInfoResponse.videoInfo.video_type) }} className="red-btn"><i className="fas fa-heart mr-1"></i>Remove from Watchlist</Link> : <Link to="#" onClick={() => { this.handleWatchlist(this.props.videoInfoResponse.videoInfo.id, this.props.videoInfoResponse.videoInfo.video_type) }} className="red-btn"><i className="far fa-heart mr-1"></i>Add Watchlist</Link>}


                                                        {this.props.videoInfoResponse.videoInfo.get_video_trailer ?
                                                            <div>
                                                                <Link to="#" onClick={() => this.handleOpenTrailer(this.props.videoInfoResponse.videoInfo.get_video_trailer.video_path)} className="dark-btn"><img src="/assets/img/icon_trailer.png" width='20px' className='mr-1' />Watch Trailer</Link>
                                                            </div>


                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="vdb-more-detail">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2 className="vdb-heading mb-3">More Details</h2>
                                            <ul className="vdb-more-list">

                                                <li>
                                                    <label>Audio</label>
                                                    {this.props.videoInfoResponse.videoInfo.audio_languages.map((audio, index) => (
                                                        <span key={index}> {audio.get_name ? audio.get_name.picklist_item_id : ''}</span>
                                                    ))}
                                                </li>

                                                <li>
                                                    <label>Genres</label>
                                                    {this.props.videoInfoResponse.videoInfo.genres.map((genres, index) => (
                                                        <span key={index}>{genres.get_name ? genres.get_name.picklist_item_id : ''}</span>
                                                    ))}
                                                </li>

                                                <li>
                                                    <label>Subtitles</label>
                                                    {this.props.videoInfoResponse.videoInfo.subtitle_file.map((subttile, index) => (
                                                        <span key={index}>{subttile.get_name ? subttile.get_name.picklist_item_id : ''}</span>
                                                    ))}
                                                </li>

                                                <li>
                                                    <label>Category</label>
                                                    {this.props.videoInfoResponse.videoInfo.category.map((category, index) => (
                                                        <span key={index}> {category.get_category_name ? category.get_category_name.picklist_item_id : ''}</span>
                                                    ))}
                                                </li>

                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : '' : ''}
                    {/* subscription box popup */}

                    <ALL.Dialog
                        open={this.state.openSubscriptionBox}
                        aria-labelledby="responsive-dialog-title"
                        className="upgrade-plan-box"
                    >
                        <ALL.DialogTitle id="responsive-dialog-title">
                            Please upgrade your plan to unblock this video
                                    </ALL.DialogTitle>
                        <ALL.DialogContent>
                            <ALL.DialogActions className="justify-content-center mb-2">
                                <Link onClick={this.closeSubscriptionBox} className="btn red-btn" to="#">
                                    Not Now
                                        </Link>
                                <Link
                                    to="#"
                                    className="btn red-btn"
                                    onClick={this.gotoSubscribePlan}
                                >
                                    Upgrade Plan
                                        </Link>

                            </ALL.DialogActions>
                        </ALL.DialogContent>
                    </ALL.Dialog>

                    {/* subscription box popup */}

                    {this.props.videoInfoResponse ? this.props.videoInfoResponse.seriesDetail ?

                        this.props.videoInfoResponse.seriesDetail.get_videos.map((series, index) => (
                            series.isLastWatched === true && iscurrent ?

                                <div>
                                    {iscurrent = false}
                                    <div className="video-detail mb-5">
                                        <div className="video-detail-banner" style={{ backgroundImage: "url(" + PATHS.BASEPATH + series.video_thumnail_path + ")" }}>
                                            <div className="container inner-pt pb-5">
                                                <div className="row">
                                                    <div className="col-md-6 pt-5">
                                                        <div className="vdb-content">
                                                            <h1 className="mb-3">
                                                                {this.props.videoInfoResponse.seriesDetail.title}
                                                            </h1>
                                                            <h5 className="mb-3">
                                                                {series.title}
                                                            </h5>

                                                            <div className="vdb-detail mb-4">
                                                                <span>
                                                                    {/* {moment(series.created_at).format('YYYY')} */}
                                                                    {moment(series.release_date, 'MM-DD-YYYY').format('ll')}
                                                                </span>
                                                                <span><span className="rate-bg">{series.age_group}</span></span>
                                                                {localStorage.getItem('isFreeAccount') == 'true' && series.is_paid != 0 ? <span className="paid-red-label"> Premium </span> : ''}
                                                            </div>

                                                            <p>
                                                                {ReactHtmlParser(series.description)}
                                                            </p>

                                                            <div className="vdb-btns mb-3">
                                                                {series.playback_time != 0 && series.playback_time != null ?
                                                                    <div className="cw-container">

                                                                        <Link
                                                                            // onClick={() => this.handleClickOpen(series.id, series.playback_time, series.video_path)} 
                                                                            onClick={localStorage.getItem('isFreeAccount') == 'true' && series.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(series.id, series.playback_time, series.video_path)}
                                                                        > <img src="/assets/img/player-btn.png" width='75px' height='75px' alt="" className="img-fluid" />
                                                                            <span className='cw-section'>
                                                                                <span className='cw-text'>Continue watching</span>
                                                                                <Line percent={(series.playback_time / series.length) * 100} strokeWidth="3" strokeColor="#e30813" trailWidth='2' className='cw-progress' />
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    : <Link
                                                                        //onClick={() => this.handleClickOpen(series.id, series.playback_time, series.video_path)} 
                                                                        onClick={localStorage.getItem('isFreeAccount') == 'true' && series.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(series.id, series.playback_time, series.video_path)}
                                                                        className="red-btn"><i className="fa fa-play mr-2" aria-hidden="true"></i>Play</Link>

                                                                }

                                                                {this.props.videoInfoResponse.watchlistStatus == true ? <Link onClick={() => { this.removeWatchlist(this.props.videoInfoResponse.seriesDetail.id, 2) }} className="red-btn"><i class="fas fa-heart mr-1"></i>Remove from Watchlist</Link> : <Link onClick={() => { this.handleWatchlist(this.props.videoInfoResponse.seriesDetail.id, 2) }} className="red-btn"><i class="far fa-heart mr-1"></i>Add Watchlist</Link>}

                                                                {/* {series.get_video_trailer ?
                                                                    <Link onClick={this.handleOpenTrailer} className="dark-btn"><img src="/assets/img/icon_trailer.png" width='20px' className='mr-1' />Watch Trailer</Link>
                                                                    : ''} */}
                                                                {/* {this.props.videoInfoResponse ? this.props.videoInfoResponse.seriesDetail ?
                                                                    this.props.videoInfoResponse.seriesDetail.get_video_trailer ?
                                                                        <Link onClick={() => this.handleOpenTrailer(this.props.videoInfoResponse.seriesDetail.get_video_trailer.video_path)} className="dark-btn"><img src="/assets/img/icon_trailer.png" width='20px' className='mr-1' />Watch Trailer</Link>
                                                                        : '' : '' : ''} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="vdb-more-detail">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="vdb-heading mb-3">More Details</h2>
                                                    <ul className="vdb-more-list">

                                                        <li>
                                                            <label>Audio</label>
                                                            {series.audio_languages.map((audio, index) => (
                                                                <span> {audio.get_name ? audio.get_name.picklist_item_id : ''}</span>
                                                            ))}
                                                        </li>

                                                        <li>
                                                            <label>Genres</label>
                                                            {series.genres.map((genres, index) => (
                                                                <span>{genres.get_name ? genres.get_name.picklist_item_id : ''}</span>
                                                            ))}
                                                        </li>

                                                        <li>
                                                            <label>Subtitles</label>
                                                            {series.subtitle_file.map((subttile, index) => (
                                                                <span>{subttile.get_name ? subttile.get_name.picklist_item_id : ''}</span>
                                                            ))}
                                                        </li>

                                                        <li>
                                                            <label>Category</label>
                                                            {series.category.map((category, index) => (
                                                                <span> {category.get_category_name ? category.get_category_name.picklist_item_id : ''}</span>
                                                            ))}
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        ))

                        : '' : ''}

                    {this.props.videoInfoResponse ? this.props.videoInfoResponse.seriesDetail ?
                        this.props.videoInfoResponse.seriesDetail.get_video_trailer ?

                            <div className="related-video">
                                <div className="container mb-4">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2 className="vdb-heading mb-4">Bonus</h2>
                                        </div>
                                        <div class="se-container col-md-12">

                                            <div class="se-section">

                                                <div class="se-left">
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="rv-sec">
                                                            <Link to="#" onClick={() => this.handleOpenTrailer(this.props.videoInfoResponse.seriesDetail.get_video_trailer.video_path)}>
                                                                <img src={PATHS.BASEPATH + '/' + this.props.videoInfoResponse.seriesDetail.get_video_trailer.video_thumnail_path} alt="Related Video" className="" />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                    <div class="se-content">
                                                        <div className="cw-container mb-2">
                                                            <Link onClick={() => this.handleOpenTrailer(this.props.videoInfoResponse.seriesDetail.get_video_trailer.video_path)} > <img src="/assets/img/player-btn.png" width='50px' height='50px' alt="" className="img-fluid" />
                                                                <span className='cw-section'>
                                                                    <span className='cw-text'>{this.props.videoInfoResponse.seriesDetail.get_video_trailer.title}</span>
                                                                </span>
                                                            </Link>
                                                        </div>

                                                        <div className="vdb-detail mb-2">
                                                            <span>
                                                                {/* {moment(this.props.videoInfoResponse.seriesDetail.get_video_trailer.created_at).format('YYYY')} */}
                                                                {moment(this.props.videoInfoResponse.seriesDetail.get_video_trailer.release_date, 'MM-DD-YYYY').format('ll')}
                                                            </span>
                                                            <span>{moment.duration(this.props.videoInfoResponse.seriesDetail.get_video_trailer.length, "seconds").format(' h[h] m[min] s[s]')}</span>
                                                            <span><span className="rate-bg">{this.props.videoInfoResponse.seriesDetail.get_video_trailer.age_group}+</span></span>

                                                            {localStorage.getItem('isFreeAccount') == 'true' && this.props.videoInfoResponse.seriesDetail.get_video_trailer.is_paid != 0 ? <span className="paid-red-label"> Premium </span> : ''}
                                                        </div>
                                                        <span className="se-des"> {this.props.videoInfoResponse.seriesDetail.get_video_trailer.description.length < 245 ? <span dangerouslySetInnerHTML={{ __html: `${this.props.videoInfoResponse.seriesDetail.get_video_trailer.description}` }} /> : <span dangerouslySetInnerHTML={{ __html: `${this.props.videoInfoResponse.seriesDetail.get_video_trailer.description.substring(0, 245)}...` }} />}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : '' : '' : ''}

                    <div className="related-video">
                        <div className="container mb-4">
                            {this.props.videoInfoResponse ? this.props.videoInfoResponse.seriesDetail ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="vdb-heading mb-4">Episodes ({this.props.videoInfoResponse.seriesDetail.get_videos.length})</h2>
                                    </div>
                                    <div class="se-container col-md-12">
                                        {this.props.videoInfoResponse.seriesDetail.get_videos.map((series, index) => (


                                            <div class="se-section">

                                                <div class="se-left">
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="rv-sec">
                                                            <Link to="#"
                                                                //  onClick={() => this.handleClickOpen(series.id, series.playback_time, series.video_path)}
                                                                onClick={localStorage.getItem('isFreeAccount') == 'true' && series.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(series.id, series.playback_time, series.video_path)}
                                                            >
                                                                <img src={PATHS.BASEPATH + '/' + series.video_thumnail_path} alt="Related Video" className="" />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                    <div class="se-content">
                                                        <div className="cw-container mb-2">
                                                            <Link
                                                                // onClick={() => this.handleClickOpen(series.id, series.playback_time, series.video_path)}
                                                                onClick={localStorage.getItem('isFreeAccount') == 'true' && series.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(series.id, series.playback_time, series.video_path)}
                                                            > <img src="/assets/img/player-btn.png" width='50px' height='50px' alt="" className="img-fluid" />
                                                                <span className='cw-section'>
                                                                    <span className='cw-text'>{series.title}</span>
                                                                    <span className='cw-progress'>
                                                                        {series.playback_time != 0 && series.playback_time != null ? <Line percent={(series.playback_time / series.length) * 100} strokeWidth="3" strokeColor="#e30813" trailWidth='2' /> : ''}
                                                                    </span>
                                                                </span>
                                                            </Link>
                                                        </div>

                                                        <div className="vdb-detail mb-2">
                                                            <span>
                                                                {/* {moment(series.created_at).format('YYYY')} */}
                                                                {moment(series.release_date, 'MM-DD-YYYY').format('ll')}
                                                            </span>
                                                            <span>{moment.duration(series.length, "seconds").format(' h[h] m[min] s[s]')}</span>
                                                            <span><span className="rate-bg">{series.age_group}</span></span>

                                                            {localStorage.getItem('isFreeAccount') == 'true' && series.is_paid != 0 ? <span className="paid-red-label"> Premium </span> : ''}
                                                            {/* {console.log(series)} */}

                                                            {series.subtitle_file ? series.subtitle_file.length != 0 ?
                                                                <ALL.Tooltip title={<div><h6>Subtitles</h6><p> {series.subtitle_file.map((subtitle, index) => (
                                                                    subtitle.get_name ? subtitle.get_name.picklist_item_id : ''
                                                                ))}</p></div>} arrow placement="top"><span>Subtitles</span></ALL.Tooltip> : '' : ''}

                                                            {series.audio_languages ? series.audio_languages.length != 0 ? <ALL.Tooltip title={<div><h6>Audio</h6><p>  {series.audio_languages.map((audio, index) => (
                                                                <span> {audio.get_name ? audio.get_name.picklist_item_id : ''}</span>
                                                            ))}</p></div>} arrow placement="top"><span>Audio</span></ALL.Tooltip> : '' : ''}

                                                            {/* {series.watchlistStatus == true ? <ALL.Tooltip title={"Remove from watchlist"} arrow placement="top"><Link onClick={() => { this.removeWatchlist(series.id) }} className="heart-icon"><i class="fas fa-heart mr-1"></i></Link></ALL.Tooltip> : <ALL.Tooltip title="Add to watchlist" arrow placement="top"><Link onClick={() => { this.handleWatchlist(series.id) }} className="heart-icon"><i class="far fa-heart mr-1"></i></Link></ALL.Tooltip>} */}
                                                        </div>
                                                        <span className="se-des"> {series.description.length < 245 ? <span dangerouslySetInnerHTML={{ __html: `${series.description}` }} /> : <span dangerouslySetInnerHTML={{ __html: `${series.description.substring(0, 245)}...` }} />}</span>


                                                    </div>
                                                </div>
                                            </div>


                                        ))}
                                    </div>

                                </div>
                                : '' : ''}
                        </div>
                    </div>
                    {this.props.videoInfoResponse ? this.props.videoInfoResponse.relatedVideo ?
                        this.props.videoInfoResponse.relatedVideo.length != 0 ?
                            <div className="container-fluid mb-4 pb-10">
                                <div className="row">
                                    <div className="col-md-12 pt-md-4 pb-5 pb-md-4 mt-1">
                                        <h2 className="vdb-heading mb-3">Related Videos</h2>

                                        <Carousel2
                                            additionalTransfrom={0}
                                            arrows
                                            autoPlaySpeed={3000}
                                            centerMode={false}
                                            className=""
                                            containerClass="container-with-dots"
                                            dotListClass=""
                                            draggable
                                            focusOnSelect={false}
                                            itemClass="image-item"
                                            keyBoardControl
                                            minimumTouchDrag={80}
                                            renderButtonGroupOutside={false}
                                            renderDotsOutside={false}
                                            showDots={false}
                                            sliderClass=""
                                            slidesToSlide={1}
                                            swipeable
                                            responsive={responsive}
                                            removeArrowOnDeviceType={["tablet", "mobile"]}
                                        >
                                            {this.props.videoInfoResponse.relatedVideo.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <Link
                                                        to="#"
                                                        className="movie-poster"
                                                        onClick={() => this.videoSeriesInfoOpen(item.video_slug, item.video_type)}
                                                    >
                                                        {localStorage.getItem('isFreeAccount') == 'true' && item.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                        <img
                                                            src={PATHS.VIDEO_IMAGE_PATH + `/${item.video_thumnail_path}`}
                                                            alt="Poster"
                                                            className="img-fluid"
                                                            videoslug={item.video_slug}
                                                            videotype={item.video_type}
                                                        />
                                                    </Link>
                                                    <div className="sub-item">
                                                        <Link
                                                            to="#"
                                                            className="movie-poster"
                                                            onClick={() => this.videoSeriesInfoOpen(item.video_slug, item.video_type)}
                                                        >
                                                            {localStorage.getItem('isFreeAccount') == 'true' && item.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                            <img
                                                                src={PATHS.VIDEO_IMAGE_PATH + `/${item.video_thumnail_path}`}
                                                                alt="Poster"
                                                                className="img-fluid"
                                                                videoslug={item.video_slug}
                                                                videotype={item.video_type}
                                                            />
                                                        </Link>

                                                        <div className="hide-item">
                                                            <div className="video-display">

                                                                <div className="video-play">
                                                                    <Link to="#"
                                                                        //onClick={() => this.handleClickOpen(item.video_id, item.get_video_history ? item.get_video_history.playback_time : 0, item.video_path)}
                                                                        onClick={localStorage.getItem('isFreeAccount') == 'true' && item.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(item.video_id, item.get_video_history ? item.get_video_history.playback_time : 0, item.video_path)}
                                                                    >
                                                                        <i className="fa fa-play-circle play-icon-margin" aria-hidden="true"></i>
                                                                    </Link>

                                                                    {item.playback_time > 0 ? <div><span className='play-text'>Play</span><Line percent={(item.playback_time / item.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></div> : <span>Play</span>}

                                                                </div>

                                                                {item.watchlistStatus == 'true' ? <ALL.Tooltip title="Remove from watchlist" arrow placement="top">
                                                                    <Link to="#" onClick={() => { this.removeWatchlist(item.video_id, item.video_type) }}>
                                                                        <i className="fas fa-trash-alt" aria-hidden="true" ></i>
                                                                    </Link>
                                                                </ALL.Tooltip> : <ALL.Tooltip title="Add to Watchlist" arrow placement="top">
                                                                        <Link to="#" onClick={() => { this.handleWatchlist(item.video_id, item.video_type) }}>
                                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                                        </Link>
                                                                    </ALL.Tooltip>}
                                                            </div>
                                                            <h1 className="video-t"> {item.title.length < 30 ? `${item.title}` : `${item.title.substring(0, 30)}...`}</h1>

                                                            {item.description.length < 130 ? <span dangerouslySetInnerHTML={{ __html: `${item.description}` }} /> : <span className="video-des" dangerouslySetInnerHTML={{ __html: `${item.description.substring(0, 130)}...` }} />}


                                                            <div className="video-time">
                                                                <span className="video-duration">{moment.duration(item.length, "seconds").format(' h[h] m[min] s[s]')}</span>
                                                                <span className="video-duration">
                                                                    {/* {moment(item.created_at).format('YYYY')} */}
                                                                    {moment(item.release_date, 'MM-DD-YYYY').format('ll')}
                                                                </span>
                                                                <div className="ml-auto"><span className="subscribers" title="Subscribers">{item.age_group}</span></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                        </Carousel2>
                                    </div>
                                </div>
                            </div>
                            : '' : '' : ''}

                    <ALL.Dialog fullScreen open={this.state.open}>
                        <div className="video-header">
                            <ALL.AppBar >
                                <ALL.Toolbar>

                                    <ALL.IconButton edge="start" color="inherit" onClick={() => { this.handleClose(localStorage.getItem('video_id')) }} aria-label="close">
                                        <CloseIcon />
                                    </ALL.IconButton>

                                </ALL.Toolbar>
                            </ALL.AppBar>
                        </div>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                ref={p => { this.p = p }}
                                className='react-player'
                                url={localStorage.getItem('video_path')}
                                playing
                                width='100%'
                                height='100%'
                                controls={true}
                                onStart={() => { this.p.seekTo(localStorage.getItem('playback_time') != 'null' ? localStorage.getItem('playback_time') : 0) }}
                                onPause={() => this.handlePause(localStorage.getItem('video_id'))}
                                onDuration={this.handleDuration}
                                onProgress={this.onProgress}
                                config={{
                                    file: {
                                        attributes: {
                                            autoPlay: true,
                                            controlsList: 'nodownload',
                                        },
                                        // tracks: [
                                        //     (this.props.videoInfoResponse ? this.props.videoInfoResponse.videoInfo ? this.props.videoInfoResponse.videoInfo.subtitle_file.map(result => (
                                        //         { kind: 'subtitles', src: PATHS.BASEPATH + result.subtitles_file_path_vtt, srcLang: result.get_name.picklist_item_id }
                                        //     )) : 0 : 0)
                                        // ]
                                    }
                                }}
                            />
                        </div>

                    </ALL.Dialog>

                    {/* trailer player box */}
                    <ALL.Dialog fullScreen open={this.state.openTrailer}>
                        <div className="video-header">
                            <ALL.AppBar >
                                <ALL.Toolbar>

                                    <ALL.IconButton edge="start" color="inherit" onClick={() => { this.handleCloseTrailer() }} aria-label="close">
                                        <CloseIcon />
                                    </ALL.IconButton>

                                </ALL.Toolbar>
                            </ALL.AppBar>
                        </div>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                ref={p => { this.p = p }}
                                className='react-player'
                                url={localStorage.getItem('video_trailer_path')}
                                playing
                                width='100%'
                                height='100%'
                                controls={true}
                                config={{
                                    file: {
                                        attributes: {
                                            autoPlay: true,
                                            controlsList: 'nodownload',
                                        },
                                        // tracks: [
                                        //     this.props.videoInfoResponse.videoInfo.subtitle_file.map(data => (
                                        //         { kind: 'subtitles', src: PATHS.BASEPATH + data.subtitles_file_path_vtt, srcLang: 'en', default: true }
                                        //     ))
                                        // ]
                                    }
                                }}
                            />
                        </div>

                    </ALL.Dialog>
                </div>
                <Foot />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.service.isLoading,
        videoInfoResponse: state.service.videoInfoResponse ? state.service.videoInfoResponse.data : '',
        removeWatchlistResponse: state.service.removeWatchlistVideoResponse ? state.service.removeWatchlistVideoResponse : '',
        watchlistResponse: state.service.watchlistResponse ? state.service.watchlistResponse : '',
        addVideoHistory: state.service.addVideoHistoryResponse ? state.service.addVideoHistoryResponse : ''
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getUserWishlist,
    getVideoInfo,
    addToWatchlist,
    removeWatchlistVideo,
    addUserVideoHistory
})(VideoDetail);
