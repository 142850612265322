// @flow

import React, { Component } from "react";
import { PATHS } from "../../constants";
import { Bottom } from "../includes";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
class Error404 extends Component {

  render() {

    return (
      <div className="black-body">
        <Helmet>
            <title>404 Page Not Found | View Africa International Page Not found</title>
            <meta name="description" content="View Africa International Page Not found" />
        </Helmet>
        <div  className="form-container  page-error-container pb-0">
          <div className="inner-page-h container pt-page-error">
            <div className="mb-4 page-error">   
              <img src={require('../../images/404-error.png')} alt="logo" className="img-fluid"/>
              <h4 className="mb-4">uh-oh! Page not found...</h4>
              <Link to={PATHS.HOME} className="btn btn-block btn-red mb-2">Go back to Home</Link>
            </div>
          </div>
          <Bottom />
        </div>
      </div>
    );
  }
}

export default Error404;
