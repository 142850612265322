// @flow

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { renderTextArea1, renderInputNormal } from "../../constants/redux-form";
import { required, email, whiteSpace, phoneNumberValidation, number } from "../../constants/validation";
import { setLoader, contactForm, contactUsSubmit } from "../../actions";
import { Nav, Footer, Foot } from "../includes";
import { animateScroll as scroll } from 'react-scroll';
import Helmet from "react-helmet";

/*
 TYPES
*/
type Props = {};

class Contact extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            alertMessage: "",
            alertClass: "",
        };
    }

    componentDidMount() {
        this.props.setLoader(false);
        scroll.scrollToTop();
    }

    onSubmit = formValues => {
        let errorMessage = "";
        setTimeout(() => {

            this.props.contactUsSubmit(formValues)
                .then(result => {
                    this.props.setLoader(false);
                    if (this.props.response.success) {
                        this.props.setLoader(false);
                        this.props.reset('contactForm');
                        this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });
                    } else {
                        this.props.setLoader(false);
                        errorMessage = this.props.response.message;
                        this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
                    }

                    setTimeout(() => {
                        this.setState({ alertMessage: "", alertClass: "" })
                    }, 3000);
                })
                .catch(e => {
                    this.props.setLoader(false);
                });
        }, 500);
    };

    render() {

        return (
            <div className="black-body">
                <Helmet>
                    <title>Contact Us | View Africa International</title>
                    <meta name="description" content="For enquiry, feedback and suggestions, fill and send this form. Our team will revert you shortly." />
                </Helmet>
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                    <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading">
                            <h2>Contact Us</h2>
                        </div>
                    </div>
                </div>

                <div className="page-min-h homepage mt-5 pt-4 btm-shadow">
                    <div className="container verticalcenter">

                        <div className="col-md-12">
                            <div className="page-scroll-content p-3 p-md-4">
                                {/*                                
                                <h2 className="mb-3 mb-md-4 pos-rel">
                                Contact Us
            </h2> */}

                                <form
                                    name="contactForm"
                                    onSubmit={this.props.handleSubmit(this.onSubmit)}
                                    className="php-email-form mt-3 mt-md-4"
                                >
                                    {this.state.alertMessage ? (
                                        <div className={this.state.alertClass} role="alert">
                                            {this.state.alertMessage}
                                        </div>
                                    ) : null}
                                    <h4 className='contact-title'>For enquiry, feedback and suggestions, contact:-</h4>
                                    <div className="form-row">
                                        <div className="col-md-6 form-group">
                                            <Field name="name" type="text" id="name" validate={[required, whiteSpace]} placeholder={`Full Name`} component={renderInputNormal} />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <Field name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderInputNormal} />
                                        </div>
                                    </div>

                                    <Field name="phone_number" id="phone_number" type="text" validate={[number, phoneNumberValidation, required]} placeholder={`Phone Number`} component={renderInputNormal} />

                                    <div className="form-group">
                                        <Field name="message" id="message" validate={[required]} placeholder={`Please write something for us`} component={renderTextArea1} />
                                    </div>
                                    <div className="text-center">

                                        <button type="submit" className="contact-btn get-started-btn">Send</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>
                {!localStorage.getItem("access_token") ? <Footer /> : <Foot />}
            </div>


        );
    }
}

const mapStateToProps = state => {
    return {
        response: state.service.contactResponse,
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, {
    setLoader,
    contactForm,
    contactUsSubmit
})(reduxForm({ form: "contactForm" })(Contact));
