import {
  SET_LOADER,
  LOGIN_FORM,
  CONTACT_FORM,
  REGISTER_FORM,
  FORGET_PASSWORD_FORM,
  PROFILE_FORM,
  CHANGE_PASSWORD_FORM,
  CATCH_ERROR,
  UPDATE_PROFILE,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  UPDATE_USER_PROFILE,
  FORGOT_PSWD,
  RESET_PSWD,
  CONTACT_US,
  FAQ,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  HELP_CENTRE,
  ADD_PROFILE,
  VIEW_PROFILE,
  MANAGE_PROFILE,
  DELETE_PROFILE,
  VIDEO_LIST,
  VIDEO_SEARCH,
  VIDEO_NOTIFICATION,
  GET_USER_PROFILE,
  USER_WISHLIST,
  SEARCH_HISTORY,
  VIDEO_HISTORY,
  VERIFY_EMAIL_REGISTER,
  PLAN_LIST,
  ADD_TO_WATCHLIST,
  UNREAD_NOTIFICATION,
  VIDEO_CATEGORY,
  ABOUT_US,
  CORPORATE_INFO,
  LEGAL_NOTICE,
  REMOVE_WATCHLIST,
  CREATE_PASSWORD,
  VIDEO_INFO,
  HEADER_MENU,
  REMOVE_WATCHLIST_VIDEO,
  ADD_TO_VIDEO_HISTORY,
  REMOVE_VIDEO_FROM_HISTORY,
  ADD_USER_PLAN,
  PLAN_DETAIL,
  UPDATE_PLAN,
  GET_USER_PAYMENT,
  PAYMENT_HISTORY,
  END_MEMBERSHIP,
  PAYMENT_DETAIL,
  CONFIRM_USER_ACCOUNT,
  UPGRADE_PLAN,
  LOGOUT

} from "./types";
import { API_PATHS } from "../constants";
import axios from "axios";
const publicIp = require("react-public-ip");
const { detect } = require('detect-browser');
const browser = detect();

// Set loader
export const setLoader = loader => dispatch => {
  dispatch({ type: SET_LOADER, payload: loader });
};

export const loginForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: LOGIN_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};

export const contactForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: CONTACT_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};

export const registerForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: REGISTER_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};

export const forgetPasswordForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: FORGET_PASSWORD_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};

export const profileForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: PROFILE_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};

export const changePasswordForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: CHANGE_PASSWORD_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};

//VAI Contact us 
export const contactUsSubmit = (formValues) => async dispatch => {
  try {
    let response = [];
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    response = await instance.post(API_PATHS.CONTACT_US, {
      email: formValues.email,
      name: formValues.name,
      phone_number: formValues.phone_number,
      content_url: formValues.content_url,
      message: formValues.message
    });

    dispatch({ type: CONTACT_US, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//Faq cms page
export const getFaqContent = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.get(API_PATHS.FAQ);
    dispatch({ type: FAQ, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//Terms of use 
export const getTermContent = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.get(API_PATHS.TERMS_OF_USE);
    dispatch({ type: TERMS_OF_USE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//Privacy Policy
export const getPolicyContent = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.get(API_PATHS.PRIVACY_POLICY);
    dispatch({ type: PRIVACY_POLICY, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//Help Center
export const getHelpContent = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.get(API_PATHS.HELP_CENTRE);
    dispatch({ type: HELP_CENTRE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//About us
export const getAboutUsContent = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.get(API_PATHS.ABOUT_US_API);
    dispatch({ type: ABOUT_US, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//Corporate Info
export const getCorporateInfoContent = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.get(API_PATHS.CORPORATE_INFO_API);
    dispatch({ type: CORPORATE_INFO, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//Legal Notice
export const getLegalNoticeContent = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.get(API_PATHS.LEGAL_NOTICE_API);
    dispatch({ type: LEGAL_NOTICE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

// VAI login api
export const userLogin = ({ email, password }) => async dispatch => {

  try {

    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const ipv4 = await publicIp.v4() || "";

    const response = await instance.post(API_PATHS.USER_LOGIN_API, {
      email: email,
      password: password,
      device: 'web',
      ip_address: ipv4,
      web_browser: browser.name
    });

    if (response.data.success) {
      localStorage.setItem("access_token", response.data.data.token);
     
      localStorage.setItem('current profile', response.data.data.profile_id);
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: response.data,
        token: response.data.data.token
      });
    } else {
      dispatch({ type: USER_LOGIN_FAIL, payload: response.data, token: "" });
    }
  } catch (e) {
    dispatch({ type: CATCH_ERROR, payload: e.message });
    setLoader(false);
  }
};

export const logout = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const ipv4 = await publicIp.v4() || "";
    const response = await instance.post(API_PATHS.LOGOUT_API, {
      device: 'web',
      fcm_token: '',
      device_id: '',
      ip_address: ipv4,
      web_browser: browser.name
    });
    dispatch({ type: LOGOUT, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const forgotPassword = ({ email }) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.FORGOT_PSWD_API, {
      email: email
    });
    dispatch({ type: FORGOT_PSWD, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const resetPswd = formValues => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.RESET_PSWD_API, formValues);
    dispatch({ type: RESET_PSWD, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const reset = (formName) => async dispatch => {
  dispatch(reset(formName));
}

export const addProfile = (input) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(
      API_PATHS.ADD_PROFILE_API, input);
    dispatch({ type: ADD_PROFILE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const viewProfile = (id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.get(API_PATHS.VIEW_PROFILE + '/' + id);

    dispatch({ type: VIEW_PROFILE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const manageProfile = (id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.post(API_PATHS.MANAGE_PROFILE);

    dispatch({ type: MANAGE_PROFILE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}


export const updateProfile = (input) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(
      API_PATHS.UPDATE_PROFILE_API, input
    );

    dispatch({ type: UPDATE_PROFILE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const deleteProfile = (id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    let response = [];
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    response = await instance.get(API_PATHS.DELETE_PROFILE + '/' + id);
    dispatch({ type: DELETE_PROFILE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};


export const getvideoList = (category_id, profile_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.post(API_PATHS.VIDEO_LIST, {
      category_id: category_id,
      profile_id: profile_id
    });

    dispatch({ type: VIDEO_LIST, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const getPlanList = (isLogin) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    const response = await instance.post(API_PATHS.PLAN_LIST, {
      isLogin: isLogin
    });
    dispatch({ type: PLAN_LIST, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const videoSearch = (search, profile_id, offset, limit) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.post(API_PATHS.VIDEO_SEARCH, {
      search: search,
      profile_id: profile_id,
      offset: offset,
      limit: limit
    });

    dispatch({ type: VIDEO_SEARCH, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const getVideoNotification = (offset, limit) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.VIDEO_NOTIFICATION, {
      offset: offset,
      limit: limit
    });
    dispatch({ type: VIDEO_NOTIFICATION, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const getUserProfile = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(API_PATHS.GET_USER_PROFILE_API);
    dispatch({ type: GET_USER_PROFILE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const updateUserProfile = formValues => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(
      API_PATHS.UPDATE_USER_PROFILE_API,
      formValues
    );
    dispatch({ type: UPDATE_USER_PROFILE, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const getUserWishlist = (limit, offset, profile_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.USER_WISHLIST_API, {
      limit: limit,
      offset: offset,
      profile_id: profile_id
    });
    dispatch({ type: USER_WISHLIST, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}



export const getSearchHistory = (profile_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.SEARCH_HISTORY_API, {
      profile_id: profile_id
    });
    dispatch({ type: SEARCH_HISTORY, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const getVideoHistory = (limit, offset, profile_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.VIDEO_HISTORY_API, {
      limit: limit,
      offset: offset,
      profile_id: profile_id
    });
    dispatch({ type: VIDEO_HISTORY, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const emailVerifyRegister = (email) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.VERIFY_EMAIL_REGISTER_API, {
      email: email
    });
    dispatch({ type: VERIFY_EMAIL_REGISTER, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const addToWatchlist = (profile_id, video_id, video_type) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.post(API_PATHS.ADD_TO_WATCHLIST, {
      profile_id: profile_id,
      video_id: video_id,
      video_type: video_type
    });

    dispatch({ type: ADD_TO_WATCHLIST, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const getUnreadNotification = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.UNREAD_NOTIFICATION);
    dispatch({ type: UNREAD_NOTIFICATION, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const getVideoByCategory = (profile_id, limit, offset, category_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.VIDEO_CATEGORY_API, {
      profile_id: profile_id,
      limit: limit,
      offset: offset,
      category_id: category_id
    });
    dispatch({ type: VIDEO_CATEGORY, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const removeUserWatchlist = (profile_id, video_id, video_type) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.REMOVE_WATCHLIST_API, {
      profile_id: profile_id,
      video_id: video_id,
      video_type: video_type
    });
    dispatch({ type: REMOVE_WATCHLIST, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const createPassword = (email, password) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.post(API_PATHS.CREATE_PASSWORD, {
      email: email,
      password: password
    });

    dispatch({ type: CREATE_PASSWORD, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}


export const getVideoInfo = (video_slug, video_type, profile_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.post(API_PATHS.VIDEO_INFO_API, {
      video_slug: video_slug,
      video_type: video_type,
      profile_id: profile_id,
      device: 'web'
    });


    dispatch({ type: VIDEO_INFO, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const getHeaderMenus = () => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    const response = await instance.post(API_PATHS.HEADER_MENU_API);
    dispatch({ type: HEADER_MENU, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const removeWatchlistVideo = (profile_id, video_id, video_type) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.REMOVE_WATCHLIST_VIDEO_API, {
      profile_id: profile_id,
      video_id: video_id,
      video_type: video_type
    });
    dispatch({ type: REMOVE_WATCHLIST_VIDEO, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const addUserVideoHistory = (profile_id, video_id, playback_time) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.post(API_PATHS.ADD_TO_VIDEO_HISTORY_API, {
      profile_id: profile_id,
      video_id: video_id,
      playback_time: playback_time
    });

    dispatch({ type: ADD_TO_VIDEO_HISTORY, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}


export const removeUserVideoHistory = (id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await instance.get(API_PATHS.REMOVE_VIDEO_FROM_HISTORY + '?id=' + id);

    dispatch({ type: REMOVE_VIDEO_FROM_HISTORY, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const addUserPlan = (plan_type, email) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    const response = await instance.post(API_PATHS.ADD_USER_PLAN_API, {
      plan_type: plan_type,
      email: email
    });

    dispatch({ type: ADD_USER_PLAN, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const planDetail = (email) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.PLAN_DETAIL_API, {
      email: email
    });
    dispatch({ type: PLAN_DETAIL, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};


export const updatePlan = (email, plan_id) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.UPDATE_PLAN_API, {
      email: email,
      plan_id: plan_id
    });
    dispatch({ type: UPDATE_PLAN, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const getUserPayment = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(API_PATHS.GET_USER_PAYMENT_API);
    dispatch({ type: GET_USER_PAYMENT, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};


export const getPaymentHistory = (limit, offset ) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(API_PATHS.PAYMENT_HISTORY_API, {
      limit: limit,
      offset: offset
    });
    dispatch({ type: PAYMENT_HISTORY, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}


export const endMembership = (subscription_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(API_PATHS.END_MEMBERSHIP_API, {
      subscription_id: subscription_id
    });
    dispatch({ type: END_MEMBERSHIP, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};


export const savePaymentDetail = paymentDetails => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    const response = await instance.post(
      API_PATHS.PAYMENT_DETAIL_API,
      paymentDetails
    );
    dispatch({ type: PAYMENT_DETAIL, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

export const confirmUserAccount = (token) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.CONFIRM_USER_ACCOUNT_API, {
      token: token
    });
    dispatch({ type: CONFIRM_USER_ACCOUNT, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
}

export const upgardePlan = (plan_id, subscription_id) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(API_PATHS.UPGRADE_PLAN_API, {
      plan_id: plan_id,
      subscription_id: subscription_id
    });
    dispatch({ type: UPGRADE_PLAN, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};