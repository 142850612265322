// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { PATHS } from "../../constants";
import { Nav, Footer, LoaderScreen, Foot } from "../includes";
import { setLoader, getvideoList, addToWatchlist, removeWatchlistVideo, addUserVideoHistory } from "../../actions";
import ReactPlayer from 'react-player';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import * as ALL from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Carousel2 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'moment-duration-format';
import { Line } from 'rc-progress';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Helmet from "react-helmet";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1366
    },
    items: 5
  },
  laptop: {
    breakpoint: {
      max: 1365,
      min: 1024
    },
    items: 5
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 768
    },
    items: 4
  },
  mobile: {
    breakpoint: {
      max: 767,
      min: 500
    },
    items: 2
  },
  smallmobile: {
    breakpoint: {
      max: 499,
      min: 0
    },
    items: 2
  },

};

class Dashboard extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      duration: null,
      secondsElapsed: null,
      playing: true,
      openSubscriptionBox: false
    };

    this.handlePause = this.handlePause.bind(this);
  }

  componentDidMount() {
    this.props.setLoader(true);
    let category_id = this.props.match.params.id;
    let profile_id = localStorage.getItem('current profile');
    this.props.getvideoList(category_id, profile_id).then(result => {
      this.props.setLoader(false);
      if (this.props.dashboardData && this.props.dashboardData.success) {
        this.props.setLoader(false);
      }
      else if (
        this.props.dashboardData &&
        this.props.dashboardData.success === false
      ) {
        NotificationManager.error(
          this.props.dashboardData.message,
          "Error",
          150000
        );
      } else {
        NotificationManager.error(
          "Something went wrong on server",
          "Error",
          150000
        );
      }
    })
  }

  componentDidUpdate(prevProps){
    let category_id = this.props.match.params.id;
    let profile_id = localStorage.getItem('current profile');

    if(prevProps.match.params.id !== category_id){
      this.props.getvideoList(category_id, profile_id).then(result => {
        this.props.setLoader(false);
        if (this.props.dashboardData && this.props.dashboardData.success) {
          this.props.setLoader(false);
        }
        else if (
          this.props.dashboardData &&
          this.props.dashboardData.success === false
        ) {
          NotificationManager.error(
            this.props.dashboardData.message,
            "Error",
            150000
          );
        } else {
          NotificationManager.error(
            "Something went wrong on server",
            "Error",
            150000
          );
        }
      })
    }
  }


  handleClickOpen = (video_id, playback_time, video_path) => {
    localStorage.setItem('video_id', video_id);
    localStorage.setItem('playback_time', playback_time);
    localStorage.setItem('video_path', video_path);

    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      this.setState({
        open: true
      });
    }
  };

  videoInfoOpen = (e) => {
    let videoslug = e.target.getAttribute('videoslug')
    let videotype = e.target.getAttribute('videotype')
    this.props.history.push('/details/' + videoslug + '/' + videotype)
  }

  handleSeeMore = (picklist_item_id, id) => {
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      this.props.history.push('/category/' + picklist_item_id + '/' + id)
    }
  }

  handlePlay = (id) => {
    if (!this.state.playing) {
      this.setState({
        playing: true
      });
    }
  }

  handlePause = (id) => {
    this.setState({ playing: false })
    let profile_id = localStorage.getItem('current profile');
    let video_id = id;
    let playback_time = Math.trunc(this.state.secondsElapsed);

    this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
      if (this.props.addVideoHistory.success) {
      }
    })
      .catch((err) => {
        this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);
      });
  }

  handleWatchlist = (id, videotype) => {
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      let profile_id = localStorage.getItem('current profile');
      let video_id = id;
      let video_type = videotype;

      this.props.addToWatchlist(profile_id, video_id, video_type).then(result => {
        if (this.props.watchlistResponse.success) {
          let category_id = this.props.match.params.id;
          let profile_id = localStorage.getItem('current profile');
          this.props.getvideoList(category_id, profile_id).then(result => {
            this.props.setLoader(false);
          })
        }
      })
        .catch((err) => {
          this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
          setTimeout(() => {
            this.setState({ alertMessage: "", alertClass: "" })
          }, 3000);

          console.log(err);
        });
    }
  };

  removeWatchlist = (id, videotype) => {
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      let profile_id = localStorage.getItem('current profile');
      let video_id = id;
      let video_type = videotype;
      this.props.removeWatchlistVideo(profile_id, video_id, video_type).then(result => {
        if (this.props.removeWatchlistResponse.success) {
          let category_id = this.props.match.params.id;
          let profile_id = localStorage.getItem('current profile');
          this.props.getvideoList(category_id, profile_id).then(result => {
            this.props.setLoader(false);
          })
        }
      })
        .catch((err) => {
          this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
          setTimeout(() => {
            this.setState({ alertMessage: "", alertClass: "" })
          }, 3000);
        })
    }
  }


  handleClose = () => {

    let profile_id = localStorage.getItem('current profile');
    let video_id = localStorage.getItem('video_id');
    let playback_time = Math.trunc(this.state.secondsElapsed);

    this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
      if (this.props.addVideoHistory.success) {
        let category_id = this.props.match.params.id;
        let profile_id = localStorage.getItem('current profile');
        this.props.getvideoList(category_id, profile_id).then(result => {
          if (this.props.videoList.success) {

          }
        });
        this.setState({
          open: false
        });

      }
    })
      .catch((err) => {
        this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);

        console.log(err);
      });

  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  onProgress = (progress) => {
    if (!this.state.duration) {
      return
    }

    const secondsElapsed = progress.played * this.state.duration
    if (secondsElapsed !== this.state.secondsElapsed) {
      this.setState({ secondsElapsed })
    }
  }

  subscriptionBox = () => {
    this.setState({
      openSubscriptionBox: true
    })
  }

  closeSubscriptionBox = () => {
    this.setState({
      openSubscriptionBox: false
    })
  }

  gotoSubscribePlan = () => {
    this.props.history.push(PATHS.RENEW_MEMBERSHIP)
  }

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    let fetchUrl = this.props.location.pathname;
    return (
      <div>
        {fetchUrl == '/store/tvshows' ? <Helmet>
          <title>Watch Drama, Cartoons, Comedy & Global TV Shows Online | View Africa International</title>
          <meta name="description" content="Our platforms provide latest and entertaining Drama, Cartoons, Comedy, and Magazine Shows& Global TV Shows online. Join Our Free Plan Today." />
        </Helmet> : fetchUrl == '/store/music' ? <Helmet>
          <title>Listen African, Worship, Praise & Global Music Online | View Africa International</title>
          <meta name="description" content="Play your favourite African traditional, Modern, Worship, Praise, and Global music online right on your smart TV, game console, PC, Mac, mobile, tablet and more." />
        </Helmet> : fetchUrl == '/store/movies' ? <Helmet>
          <title>Watch Classic, Drama, Romantic, Thriller, Mystery Movies Online | View Africa International</title>
          <meta name="description" content="We provide old and new Classic, Drama, Romantic, Thriller, Mystery Movies content online or stream right on your smart TV, mobile, tablet and more." />
        </Helmet> : fetchUrl == '/store/web-series' ? <Helmet>
          <title>Watch Original Webseries Online | View Africa International</title>
          <meta name="description" content="Join our free plan and start watching the latest and original webseries online on View Africa International right to your smart TV, mobile, tablet and more." />
        </Helmet> : ''}
        <Nav />
        <NotificationContainer />
        <div className="banner-shadow mb-n-22">
          <section id="hero" className="d-flex flex-column justify-content-center p-0">
            <div className="page-slider pt-5 pb-0">
              <Carousel showThumbs={false} infiniteLoop={true} autoPlay showStatus={false}>
                {this.props.videoList ? this.props.videoList.silder ? this.props.videoList.silder.length != 0 ? this.props.videoList.silder.map(sliderData => (
                  <Link to="#" onClick={this.videoInfoOpen}>
                    <div videoslug={sliderData.reference_slug}
                      videotype={sliderData.reference_type}>
                      <img src={PATHS.BASEPATH + `/${sliderData.path}`} />
                      {/* <p className="legend">{sliderData.title}</p> */}
                    </div>
                  </Link>
                )) : '' : '' : ''}
              </Carousel>
            </div>
          </section>
        </div>

        <main id="main" className="main-height pb-10">
          {this.props.videoList ? this.props.videoList.categoryData ? this.props.videoList.categoryData.length !== 0 ? this.props.videoList.categoryData.map(category => (
            <div className="container-fluid page-carousel btm-shadow" key={category.id}>

              <div className="row">
                <div className="col-md-12 pt-3 pt-md-4 pt-lg-5 pb-3 pb-md-4 mt-1">
                  <div>
                    <div className="see-more">
                      <h2 className="mb-3 mb-md-4 pos-rel">
                        {category.picklist_item_id}
                      </h2>
                      {/* <Link
                        to="#"
                        data-id={category.id}
                        onClick={() => this.handleSeeMore(category.picklist_item_id, category.id)}
                      ><span className="see-more-text">SEE ALL</span></Link> */}
                      {category.videoList ? category.videoList.length > 5 ? <Link
                        to="#"
                        data-id={category.id}
                        onClick={() => this.handleSeeMore(category.picklist_item_id, category.id)}
                      ><span className="see-more-text">SEE ALL</span></Link> : '' : ''}
                    </div>
                    <Carousel2
                      additionalTransfrom={0}
                      arrows
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      itemClass="image-item"
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                      responsive={responsive}
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                    >
                      {category.videoList ? category.videoList.length !== 0 ? category.videoList.map((data, index) => (
                        <div className="item" key={index}>
                          <Link
                            to="#"
                            className="movie-poster"
                            onClick={this.videoInfoOpen}
                          // onClick={data.is_paid != 0 ? this.subscriptionBox : this.videoInfoOpen}
                          >
                            {this.props.videoList.isFreeAccount == true && data.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                            <img
                              src={PATHS.VIDEO_IMAGE_PATH + `/${data.video_thumnail_path}`}
                              alt="Poster"
                              className="img-fluid"
                              videoslug={data.video_slug}
                              videotype={data.video_type}
                            />
                          </Link>
                          <div className="sub-item">
                            <Link
                              to="#"
                              className="movie-poster"
                              onClick={this.videoInfoOpen}
                            // onClick={data.is_paid != 0 ? this.subscriptionBox : this.videoInfoOpen}
                            >
                              {this.props.videoList.isFreeAccount == true && data.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                              <img
                                src={PATHS.VIDEO_IMAGE_PATH + `/${data.video_thumnail_path}`}
                                alt="Poster"
                                className="img-fluid"
                                videoslug={data.video_slug}
                                videotype={data.video_type}
                              />
                            </Link>
                            {/* <h4 title={data.title}>
                              {data.title.length < 15 ? `${data.title}` : `${data.title.substring(0, 30)}...`}
                            </h4> */}
                            <div className="hide-item">
                              <div className="video-display">


                                <div className="video-play">
                                  {/* {console.log(data.video_type)} */}
                                  {/* {data.video_type == 2 ? } */}
                                  <Link to="#"
                                    onClick={this.props.videoList.isFreeAccount == true && data.is_paid != 0 ? this.subscriptionBox : data.video_type == 2 ? this.videoInfoOpen : () => this.handleClickOpen(data.id, data.playback_time, data.video_path)}
                                  >
                                    <i className="fa fa-play-circle play-icon-margin"
                                      videoslug={data.video_slug}
                                      videotype={data.video_type}
                                      aria-hidden="true"></i>
                                  </Link>

                                  {/* subscription box popup */}

                                  <ALL.Dialog
                                    open={this.state.openSubscriptionBox}
                                    aria-labelledby="responsive-dialog-title"
                                    className="upgrade-plan-box"
                                  >
                                    <ALL.DialogTitle id="responsive-dialog-title">
                                      Please upgrade your plan to unblock this video
                                    </ALL.DialogTitle>
                                    <ALL.DialogContent>
                                      <ALL.DialogActions className="justify-content-center mb-2">
                                        <Link onClick={this.closeSubscriptionBox} className="btn red-btn" to="#">
                                          Not Now
                                        </Link>
                                        <Link
                                          to="#"
                                          className="btn red-btn"
                                          onClick={this.gotoSubscribePlan}
                                        >
                                          Upgrade Plan
                                        </Link>

                                      </ALL.DialogActions>
                                    </ALL.DialogContent>
                                  </ALL.Dialog>
                                  {/* subscription box popup */}
                                  {data.video_type == 2 ? '' : data.playback_time > 0 ? <div><span className='play-text'>Play</span><Line percent={(data.playback_time / data.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></div> : <span>Play</span>}


                                </div>
                                {/* {!(this.props.match.params.id == 'WebSeries') ? console.log('hello') : console.log('test') } */}
                                {data.watchlistStatus == true ?
                                  <ALL.Tooltip title="Remove from watchlist" arrow placement="top">
                                    {data.video_type == 2 && !(this.props.match.params.id == 'WebSeries') ? <Link to="#" onClick={() => { this.removeWatchlist(data.series_id, data.video_type) }}>
                                      <i className="fas fa-trash-alt" aria-hidden="true"></i>
                                    </Link> : <Link to="#" onClick={() => { this.removeWatchlist(data.id, data.video_type) }}>
                                      <i className="fas fa-trash-alt" aria-hidden="true"></i>
                                    </Link>
                                    }

                                  </ALL.Tooltip>
                                  : <ALL.Tooltip title="Add to Watchlist" arrow placement="top">
                                    {data.video_type == 2 && !(this.props.match.params.id == 'WebSeries') ? <Link to="#" onClick={() => { this.handleWatchlist(data.series_id, data.video_type) }}>
                                      <i className="fa fa-plus" aria-hidden="true"></i>
                                    </Link> : <Link to="#" onClick={() => { this.handleWatchlist(data.id, data.video_type) }}>
                                      <i className="fa fa-plus" aria-hidden="true"></i>
                                    </Link>
                                    }

                                  </ALL.Tooltip>
                                }

                              </div>
                              <h1 className="video-t">{data.title ? data.title.length < 30 ? `${data.title}` : `${data.title.substring(0, 30)}...` : ''}</h1>
                              {data.description ? data.description.length < 130 ? <span dangerouslySetInnerHTML={{ __html: `${data.description}` }} /> : <span className="video-des" dangerouslySetInnerHTML={{ __html: `${data.description.substring(0, 130)}...` }} /> : ''}
                              <div className="video-time">
                                {data.video_type == 2 && !(this.props.match.params.id == 'WebSeries') ? '' : <span className="video-duration">{moment.duration(data.length, "seconds").format(' h[h] m[min] s[s]')}</span>}

                                <span className="video-duration">
                                  {/* {moment(data.created_at).format('YYYY')} */}
                                  {moment(data.release_date, 'MM-DD-YYYY').format('ll')}
                                </span>
                                <div className="ml-auto"><span className="subscribers" title="Subscribers">{data.age_group}</span></div>
                              </div>
                            </div>


                          </div>
                          <ALL.Dialog fullScreen open={this.state.open}>
                            <div className="video-header">
                              <ALL.AppBar >
                                <ALL.Toolbar>

                                  <ALL.IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                  </ALL.IconButton>

                                </ALL.Toolbar>
                              </ALL.AppBar>
                            </div>
                            <div className='player-wrapper'>
                              <ReactPlayer
                                ref={p => { this.p = p }}
                                className='react-player'
                                url={localStorage.getItem('video_path')}
                                width='100%'
                                height='100%'
                                controls={true}
                                onPlay={() => { this.handlePlay(localStorage.getItem('video_id')) }}
                                onStart={() => { this.p.seekTo(localStorage.getItem('playback_time') == 'null' ? 0 : localStorage.getItem('playback_time')) }}
                                onPause={() => this.handlePause(localStorage.getItem('video_id'))}
                                onDuration={this.handleDuration}
                                onProgress={this.onProgress}
                                light={true}
                                playing={this.state.playing}
                                config={{
                                  file: {
                                    attributes: {
                                      autoPlay: true,
                                      controlsList: 'nodownload',
                                    },
                                    // tracks: [
                                    //   { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-en.vtt', srcLang: 'en', default: true },
                                    //   { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-de.vtt', srcLang: 'de' },
                                    //   { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-es.vtt', srcLang: 'es' }
                                    // ]
                                  }
                                }}
                              />
                            </div>

                          </ALL.Dialog>
                        </div>

                      )) : ''
                        : ''}
                    </Carousel2>
                  </div>
                </div>
              </div>
            </div>

          )) : '' : '' : ''}
          {fetchUrl == '/store/tvshows' &&
            <div className="footer-content container pt-5">
              <p>View Africa International brings you a wide range of famous television shows from African and different continents of the world at your fingertips. Keeping the expectations of all our viewers in mind we bring all types of television shows including cartoon, comedy, dramatic shows, magazine shows, and global shows. We offer everything to our viewers according to their age group.</p>

              <p>You can download these programmes to watch them later during your free time if it is not possible to watch them on their scheduled time. To watch these programmes you just need a device that supports connectivity to the internet. It may be your smartphone, laptop, tablet, iPad, or television with an internet connection. We have a wide range of subscription options at affordable charges of $ 1, $ 2, and $ 4.50 for daily, weekly, and monthly viewers respectively. You can also try our free plans to find whether the programmes shown on our platform are according to your interest or not.</p>
            </div>}

          {fetchUrl == '/store/music' &&
            <div className="footer-content container pt-5">
              <p>Music is the best way to relax and cherish your mood after a hectic schedule throughout the day. Even doctors have also realized the relevance of music therapy in treating patients suffering from chronic diseases. View Africa International brings you the facility of online music streaming direct to your devices connected to the internet.</p>

              <p>We bring online music of all genres including African Traditional and Modern Music, Classic Music, Worship Songs (Devotional music), and Global Music. You can download music of your choice and listen to it whether you are travelling or sitting alone or going to sleep.</p>
            </div>}

          {fetchUrl == '/store/movies' &&
            <div className="footer-content container pt-5">
              <p>Movies are not only the best entertainers but also the source of educating society regarding current issues. Some movies remain fresh in the memories of people for the years and their charm remains the same as it was at the time of their release years back. We at View Africa International bring you all types of movies including African movies, Classical movies, Mystery movies, Romantic movies, and thriller movies.</p>

              <p>WDepending upon your interest you can download different movies on your devices and watch them as and when you are free. If you are not sure about the quality of content available on our platform then you can subscribe to our free plans. Our subscription plans are affordable including the payment of $1 for a daily subscription, $ 2 for a weekly subscription, and $ 4.50 for the monthly subscription.</p>
            </div>}

          {fetchUrl == '/store/web-series' &&
            <div className="footer-content container pt-5">
              <p>In recent years web series have become the first choice of people searching for some interesting content to watch. We bring the latest series available at charges that are easily affordable to everyone. If you are interested to watch our web series you can select from different options including $1 for a daily subscription, $ 2 for a weekly subscription, and $ 4.50 for the monthly subscription.</p>
            </div>}
        </main>

        {!localStorage.getItem("access_token") ? <Footer /> : <Foot />}

      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    videoList: state.service.videoListResponse ? state.service.videoListResponse.data : "",
    isLoading: state.service ? state.service.isLoading : "",
    watchlistResponse: state.service.watchlistResponse ? state.service.watchlistResponse : '',
    removeWatchlistResponse: state.service.removeWatchlistVideoResponse ? state.service.removeWatchlistVideoResponse : '',
    addVideoHistory: state.service.addVideoHistoryResponse ? state.service.addVideoHistoryResponse : '',
    dashboardData: state.service.videoListResponse ? state.service.videoListResponse : "",
  };
};

export default connect(mapStateToProps, {
  setLoader,
  getvideoList,
  addToWatchlist,
  removeWatchlistVideo,
  addUserVideoHistory
})(Dashboard);
