// @flow

import React, { Component } from "react";

import { PATHS } from "../../constants";
import { Bottom } from "../includes";
import { Link } from "react-router-dom";

/*
 TYPES
*/

type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class SignupSuccess extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount() {

        if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'notAutherised') {
            this.props.history.push(PATHS.HOME);
        }
    }

    render() {
    
        return (
            <div className="form-container small-form-page pb-0">
                <div className="inner-page-h container pt-signsuccess">
                    <div className="form-body">
                        <div className="form-content"> 
                            <div className="mb-4 signup-success">
                                <img src="/assets/img/signup-success.png" className="img-fluid" alt="thank you" />
                                <h4>Verify your email address</h4>
                                <p>We have sent a verification link to your registered email id. Please check your inbox to confirm the account.</p>
                                <p>If you have not received the verification email, please check your 'Spam' folder. If you not received any mail then please<Link to={PATHS.CONTACT}> contact us</Link>.</p>
                                <Link to={PATHS.LOGIN} className="btn btn-block btn-red mb-2">Sign In</Link>
                            </div>
                        </div>
                    </div>

                </div>
                <Bottom />
            </div>
        );
    }
}


export default SignupSuccess;
