import React from "react";
import * as Styled from "../pages/page.styled";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import * as ALL from "@material-ui/core";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import Multiselect from 'react-widgets/lib/Multiselect'

// import 'react-widgets/dist/css/react-widgets.css';

// import * as Styled from '../pages/page.styled';
type Props = {
  input: Object,
  label: string,
  type: string,
  meta: Object,
  showTime: string,
  children: Object,
  data: string,
  value: string
};

// const renderField = ({
//   label,
//   input,
//   meta: { touched, invalid, error },
//   ...custom
// }) => (
//   <ALL.TextField
//     label={label}
//     placeholder={label}
//     error={touched && invalid}
//     helperText={touched && error}
//     {...input}
//     {...custom}
//   />
// );

// const radioButton = ({ input, value, ...rest }) => (
//   <ALL.FormControl>
//     <ALL.RadioGroup {...input} {...rest}>
//       <ALL.FormControlLabel
//         value={value}
//         control={<ALL.Radio />}
//         checked={input.value ? true : false}
//         label={value}
//       />
//     </ALL.RadioGroup>
//   </ALL.FormControl>
// );


const renderRadioButton = ({ input, label, value, type, meta: { touched, error } }: Props) => (
  <ALL.FormControl >
    <ALL.RadioGroup {...input} >
      <ALL.FormControlLabel className={touched && (error && "error")}
        type={type}
        autoComplete="off"
        control={<ALL.Radio />}
        label={label}
        value={label}
      />

    </ALL.RadioGroup>

  </ALL.FormControl>
);



const renderFile = ({
  placeholder,
  id,
  data,
  input,
  label,
  type,
  meta: { touched, error }
}: Props) => {
  return (
    <div>
      <input
        {...input}
        value={data}
        accept="image/x-png,image/gif,image/jpeg"
        type="file"
        placeholder={placeholder}
        id={id}
        style={{ marginBottom: "15px" }}
      />
      {touched && error && <Styled.error>{error}</Styled.error>}
    </div>
  );
};



// const renderDatePicker = ({
//   label,
//   placeholder,
//   input: { onChange, value },
//   meta: { touched, error }
// }: Props) => {
//   return (
//     <div>
//       <DatePicker
//         name={"from"}
//         selected={!value ? null : new Date(value)}
//         onChange={onChange}
//         dateFormat="dd/MM/yyyy"
//         placeholder={placeholder}
//       />
//       {touched && error && <Styled.error>{error}</Styled.error>}
//     </div>
//   );
// };





// const renderFromHelper = ({ touched, error }) => {
//   if (!(touched && error)) {
//     return
//   } else {
//     return <ALL.FormHelperText>{touched && error}</ALL.FormHelperText>
//   }
// }

// const renderSelectField = ({
//   input,
//   label,
//   meta: { touched, error },
//   children,
//   ...custom
// }) => (
//   <ALL.FormControl error={touched && error} fullWidth>
//     <ALL.InputLabel htmlFor="age-native-simple">{label}</ALL.InputLabel>
//     <ALL.Select
//       native
//       {...input}
//       {...custom}
//       // inputProps={{
//       //   name: 'age',
//       //   id: 'age-native-simple'
//       // }}
//     >
//       {children}
//     </ALL.Select>
//     {renderFromHelper({ touched, error })}
//   </ALL.FormControl>
// )

// const renderMultiselect = ({ input, data, valueField, textField }) =>
//   <Multiselect {...input}
//     onBlur={() => input.onBlur()}
//     value={input.value || []} // requires value to be an array
//     data={data}
//     valueField={valueField}
//     textField={textField}
//   />

const renderSwitch = ({ input, label, checked }) => (
  <ALL.Switch
    label={label}
    defaultChecked={checked}
  />
)

// const renderDobPicker = ({
//   label,
//   input,
//   value,
//   meta: { touched, invalid, error },
//   ...custom
// }) => (
//   <MuiPickersUtilsProvider utils={DateFnsUtils}>
//     <KeyboardDatePicker
//       format="MM/dd/yyyy"
//       label={label}
//       {...custom}
//       {...input}
//       selectedDate={!value ? null : new Date(value)}
//       error={touched && invalid}
//       helperText={touched && error}
//     />
//   </MuiPickersUtilsProvider>
// );

const renderPassword = ({ placeholder, id, input, label, type, meta: { touched, error } }: Props) => (
  <div className="input-group mb-3">
    {/* <div className="input-group-prepend">
      <span className="input-group-text"><i className="fas fa-unlock-alt"></i></span>
    </div> */}
    <input  {...input} type={type} placeholder={placeholder} className="form-control" id={id} />
    {touched && (error && <Styled.error>{error}</Styled.error>)}
  </div>
);

const SelectField = ({ input, data, id, icon, meta: { touched, error }, children }: Props) => (
  <div className="input-group mb-3">
    {/* <div className="input-group-prepend">
      <span className="input-group-text"><i className={icon}></i></span>
    </div> */}
    <select {...input} id={id} className="form-control">
      {children}
    </select>
    {touched && (error && <Styled.error>{error}</Styled.error>)}
  </div>

);

// const SelectFieldNormal = ({ input, data, id, icon, meta: { touched, error }, children }: Props) => (
//   <div className="input-group">
//     <select {...input} id={id} className="form-control select-class">
//       {children}
//     </select>
//     {touched && (error && <Styled.error>{error}</Styled.error>)}
//   </div>

// );

const renderEmail = ({ placeholder, id, input, label, type, meta: { touched, error } }: Props) => (
  <div className="input-group mb-3">
    {/* <div className="input-group-prepend">
      <span className="input-group-text"><i className="fas fa-user-tie"></i></span>
    </div> */}
    <input  {...input} type={type} placeholder={placeholder} className="form-control" id={id} autocomplete="off" />
    {touched && (error && <Styled.error>{error}</Styled.error>)}
  </div>
);

const renderEmail2 = ({ placeholder, id, input, label, type, meta: { touched, error } }: Props) => (
  <div className="email-box mb-4">
  <div className="input-group mb-3">

    <input  {...input} type={type} placeholder={placeholder} className="form-control" id={id} />
    
    <div className="input-group-append">
      <button type="submit" className="input-group-text pl-4 pr-4">Get Started</button>
    </div>
  </div>
  {touched && (error && <Styled.error>{error}</Styled.error>)}
  </div>

);

const renderInput = ({ placeholder, id, className, data, icon, disabled, input, type, meta: { touched, error } }: Props) => {
  return (
    <div className="input-group mb-3">
      {/* <div className="input-group-prepend">
        <span className="input-group-text"><i className={icon}></i></span>
      </div> */}
      <input  {...input} type={type} placeholder={placeholder} disabled={disabled} id={id} className="form-control" />
      {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
  );
}

const renderInputNormal = ({ placeholder, id, className, data, input, type, meta: { touched, error } }: Props) => {
  return (
    <div className="input-group mb-3">
      <input  {...input} type={type} placeholder={placeholder} id={id} className="form-control" />
      {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
  );
}

const renderTextArea1 = ({ placeholder, id, input, type, meta: { touched, error } }: Props) => {
  // console.log('input ',input);
  return (
    <div className="input-group mb-3">
      <textarea {...input} rows="5" className="form-control" placeholder={placeholder}></textarea>
      {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
  );
}

const renderRadioField = ({ input, label, type, checked }) => (
  <div>
    <input {...input} type={type} checked={checked} />
    <label>{label}</label>
  </div>
);


export {
  // renderField,
  renderFile,
  // renderDatePicker,
   SelectField,
  // radioButton,
  // renderDobPicker,
  // renderSelectField,
  // renderMultiselect,
  renderRadioButton,
  renderTextArea1,
  renderPassword,
  renderEmail,
  renderInput,
  renderRadioField,
  renderInputNormal,
  renderSwitch,
  renderEmail2
  // SelectFieldNormal
};
