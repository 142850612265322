// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { PATHS } from "../../constants";
import { Nav, Footer, LoaderScreen, Foot } from "../includes";
import { animateScroll as scroll } from 'react-scroll'
import { setLoader, getvideoList, addToWatchlist, removeWatchlistVideo, addUserVideoHistory } from "../../actions";
import ReactPlayer from 'react-player';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import * as ALL from "@material-ui/core";
import Carousel2 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'moment-duration-format';
import { Line } from 'rc-progress';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Helmet from "react-helmet";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1366
    },
    items: 5
  },
  laptop: {
    breakpoint: {
      max: 1365,
      min: 1024
    },
    items: 5
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 768
    },
    items: 4
  },
  mobile: {
    breakpoint: {
      max: 767,
      min: 500
    },
    items: 2
  },
  smallmobile: {
    breakpoint: {
      max: 499,
      min: 0
    },
    items: 2
  },

};

class Home extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      duration: null,
      secondsElapsed: null,
      playing: true,
      openSubscriptionBox: false,
      addClass: false
    };

    this.handlePause = this.handlePause.bind(this);
  }

  componentDidMount() {
    this.props.setLoader(true);
    let category_id = '';
    let profile_id = localStorage.getItem('current profile');
    this.props.getvideoList(category_id, profile_id).then(result => {
      this.props.setLoader(false);
      if (this.props.dashboardData && this.props.dashboardData.success) {
        this.props.setLoader(false);
      }
      else if (
        this.props.dashboardData &&
        this.props.dashboardData.success === false
      ) {
        NotificationManager.error(
          this.props.dashboardData.message,
          "Error",
          150000
        );
      } else {
        NotificationManager.error(
          "Something went wrong on server",
          "Error",
          150000
        );
      }
    })
  }

  handleClickOpen = (video_id, playback_time, video_path) => {
    localStorage.setItem('video_id', video_id);
    localStorage.setItem('playback_time', playback_time);
    localStorage.setItem('video_path', video_path);

    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      this.setState({
        open: true,
        addClass: !this.state.addClass
      });
    }
  };

  subscriptionBox = () => {
    this.setState({
      openSubscriptionBox: true
    })
  }

  closeSubscriptionBox = () => {
    this.setState({
      openSubscriptionBox: false
    })
  }

  gotoSubscribePlan = () => {
    this.props.history.push(PATHS.RENEW_MEMBERSHIP)
  }

  handleSeeMore = (picklist_item_id, id) => {
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      this.props.history.push('/category/' + picklist_item_id + '/' + id)
    }
  }

  scrollUp = () => {
    scroll.scrollToTop()
  }

  handleWatchlist = (id, videotype) => {
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      let profile_id = localStorage.getItem('current profile');
      let video_id = id;
      let video_type = videotype;

      this.props.addToWatchlist(profile_id, video_id, video_type).then(result => {
        if (this.props.watchlistResponse.success) {
          let category_id = '';
          let profile_id = localStorage.getItem('current profile');
          this.props.getvideoList(category_id, profile_id).then(result => {
            this.props.setLoader(false);
          })
        }
      })
        .catch((err) => {
          this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
          setTimeout(() => {
            this.setState({ alertMessage: "", alertClass: "" })
          }, 3000);
        });
    }
  };

  handleClose = () => {

    let profile_id = localStorage.getItem('current profile');
    let video_id = localStorage.getItem('video_id');
    let playback_time = Math.trunc(this.state.secondsElapsed);

    this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
      if (this.props.addVideoHistory.success) {
        let category_id = '';
        let profile_id = localStorage.getItem('current profile');
        this.props.getvideoList(category_id, profile_id).then(result => {
          this.props.setLoader(false);
        })
        this.setState({
          open: false
        });
      }
    })
      .catch((err) => {
        this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);
      });

  }

  videoInfoOpen = (e) => {
    let videoslug = e.target.getAttribute('videoslug');
    let videotype = e.target.getAttribute('videotype');

    if (localStorage.getItem("access_token")) {
      this.props.history.push('/details/' + videoslug + '/' + videotype);
    } else {
      this.props.history.push(PATHS.LOGIN);
    }
  }

  removeWatchlist = (id, videotype) => {
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
    } else {
      let profile_id = localStorage.getItem('current profile');
      let video_id = id;
      let video_type = videotype;
      this.props.removeWatchlistVideo(profile_id, video_id, video_type).then(result => {
        if (this.props.removeWatchlistResponse.success) {
          let category_id = '';
          this.props.getvideoList(category_id, profile_id).then(result => {
            this.props.setLoader(false);
          })
        }
      })
        .catch((err) => {
          this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
          setTimeout(() => {
            this.setState({ alertMessage: "", alertClass: "" })
          }, 3000);
        })
    }
  }

  handlePlay = () => {
    if (!this.state.playing) {
      this.setState({
        playing: true
      });
    }
  }

  handlePause = (id) => {
    this.setState({ playing: false })
    let profile_id = localStorage.getItem('current profile');
    let video_id = id;
    let playback_time = Math.trunc(this.state.secondsElapsed);

    this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
      if (this.props.addVideoHistory.success) {
        let category_id = '';
        let profile_id = localStorage.getItem('current profile');
        this.props.getvideoList(category_id, profile_id).then(result => {
          this.props.setLoader(false);
        })
      }
    })
      .catch((err) => {
        this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);
      });
  }

  handlePlayPause = (e) => {
    this.setState({ playing: !this.state.playing })
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  onProgress = (progress) => {
    if (!this.state.duration) {
      return
    }

    const secondsElapsed = progress.played * this.state.duration
    if (secondsElapsed !== this.state.secondsElapsed) {
      this.setState({ secondsElapsed })
    }
  }

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }
    return (
      <div>
        <Helmet>
          <title>Watch Movies, TV Shows, Latest Promos, Documentaries Online | View Africa International</title>
          <meta name="description" content="View Africa International provides an online platform to play music of all genres or watch movies online right on your mobile, TV, PC and more. Join Our Free Plan Today." />
        </Helmet>
        <Nav />
        <NotificationContainer />
        <div className="banner-shadow mb-n-22">
          <section id="hero" className="d-flex flex-column justify-content-center p-0">
            <div className="poster"/>
            <div className="banner-content">
              <div className="container">
                <div className="row justify-content-center pos-rel">
                  <div className="col-xl-8">
                    <img src="assets/img/banner-logo.png" className={!localStorage.getItem("access_token") ? "img-fluid mb-2 banner-logo1" : 'img-fluid mb-2 banner-logo'} alt="Banner Logo" />
                    <h1 className={!localStorage.getItem("access_token") ? "mb-2 text-shadow vai-text" : "mb-2 text-shadow"}>VIEW AFRICA INTERNATIONAL </h1>
                    <h2 className="mb-3 text-shadow">Free plans | Daily $1 | Weekly $2 | Monthly $4.50</h2>
                    {!localStorage.getItem("access_token") ? !localStorage.getItem('vai_user_email') ? <Link to={PATHS.REGISTER} className="blue-big-btn btn-shadow try-btn mb-2">
                      <span>Try It Now</span>
                      <i className="fas fa-chevron-right"></i>
                    </Link> : <Link to={PATHS.REGISTER} className="blue-big-btn btn-shadow try-btn mb-2">
                      <span>Finish Sign up</span>
                      <i className="fas fa-chevron-right"></i>
                    </Link> : ''}

                    <div className="clearfix"> </div>

                    <div className="arrows">
                      <Link to="#" onClick={() => scroll.scrollMore(615)}>
                        <div className="a1"> <img src="assets/img/drop-arrow.png" /> </div>
                        <div className="a2"> <img src="assets/img/drop-arrow.png" /> </div>
                        <div className="a3"> <img src="assets/img/drop-arrow.png" /> </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <main id="main" className="main-height pb-10">

          {this.props.videoList ? this.props.videoList.categoryData ? this.props.videoList.categoryData.length !== 0 ? this.props.videoList.categoryData.map(category => (
            localStorage.setItem('isFreeAccount', this.props.videoList.isFreeAccount),
            <div className="container-fluid page-carousel btm-shadow" key={category.id}>

              <div className="row">
                <div className="col-md-12 pt-3 pt-md-4 pt-lg-5 pb-3 pb-md-4 mt-1">
                  <div>
                    <div className="see-more">
                      <h2 className="mb-3 mb-md-4 pos-rel">
                        {category.picklist_item_id}
                      </h2>
                      {category.videoList ? category.videoList.length > 5 ? <Link
                        to="#"
                        data-id={category.id}
                        onClick={() => this.handleSeeMore(category.picklist_item_id, category.id)}
                      ><span className="see-more-text">SEE ALL</span></Link> : '' : ''}

                    </div>
                    <Carousel2
                      additionalTransfrom={0}
                      arrows
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      itemClass="image-item"
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                      responsive={responsive}
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                    >
                      {category.videoList ? category.videoList.length !== 0 ? category.videoList.map(item => (

                        <div className="item" key={item.id}>
                          <Link
                            to="#"
                            className="movie-poster"
                            onClick={this.videoInfoOpen}
                          >
                            {this.props.videoList.isFreeAccount == true && item.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                            <img
                              src={PATHS.VIDEO_IMAGE_PATH + `/${item.video_thumnail_path}`}
                              alt="Poster"
                              className="img-fluid"
                              videoslug={item.video_slug}
                              videotype={item.video_type}
                            />
                          </Link>
                          <div className="sub-item">
                            <Link
                              to="#"
                              className="movie-poster"
                              onClick={this.videoInfoOpen}
                            >
                              {this.props.videoList.isFreeAccount == true && item.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                              <img
                                src={PATHS.VIDEO_IMAGE_PATH + `/${item.video_thumnail_path}`}
                                alt="Poster"
                                className="img-fluid"
                                videoslug={item.video_slug}
                                videotype={item.video_type}
                              />
                            </Link>
                            <div className="hide-item">
                              <div className="video-display">

                                <div className="video-play">
                                  <Link to="#"
                                    onClick={this.props.videoList.isFreeAccount == true && item.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(item.id, item.playback_time, item.video_path)}
                                  >
                                    <i className="fa fa-play-circle play-icon-margin" aria-hidden="true"></i>
                                  </Link>

                                  {/* subscription box popup */}

                                  <ALL.Dialog
                                    open={this.state.openSubscriptionBox}
                                    aria-labelledby="responsive-dialog-title"
                                    className="upgrade-plan-box"
                                  >
                                    <ALL.DialogTitle id="responsive-dialog-title">
                                      Please upgrade your plan to unblock this video
                                    </ALL.DialogTitle>
                                    <ALL.DialogContent>
                                      <ALL.DialogActions className="justify-content-center mb-2">
                                        <Link onClick={this.closeSubscriptionBox} className="btn red-btn" color="primary" to="#">
                                          Not Now
                                        </Link>
                                        <Link
                                          to="#"
                                          className="btn red-btn"
                                          onClick={this.gotoSubscribePlan}
                                          color="primary">
                                          Upgrade Plan
                                        </Link>

                                      </ALL.DialogActions>
                                    </ALL.DialogContent>
                                  </ALL.Dialog>

                                  {/* subscription box popup */}
                                  {item.video_type == 2 ? '' : item.playback_time > 0 ? <div><span className='play-text'>Play</span><Line percent={(item.playback_time / item.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></div> : <span>Play</span>}


                                </div>

                                {item.watchlistStatus == true ? <ALL.Tooltip title="Remove from watchlist" arrow placement="top">
                                  {item.video_type == 2 && !(this.props.match.params.id == 'WebSeries') ? <Link to="#" onClick={() => { this.removeWatchlist(item.series_id, item.video_type) }}>
                                    <i className="fas fa-trash-alt" aria-hidden="true"></i>
                                  </Link> : <Link to="#" onClick={() => { this.removeWatchlist(item.id, item.video_type) }}>
                                    <i className="fas fa-trash-alt" aria-hidden="true"></i>
                                  </Link>
                                  }

                                </ALL.Tooltip> : <ALL.Tooltip title="Add to Watchlist" arrow placement="top">
                                  {item.video_type == 2 && !(this.props.match.params.id == 'WebSeries') ? <Link to="#" onClick={() => { this.handleWatchlist(item.series_id, item.video_type) }}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                  </Link> : <Link to="#" onClick={() => { this.handleWatchlist(item.id, item.video_type) }}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                  </Link>}
                                </ALL.Tooltip>}


                              </div>
                              <h1 className="video-t"> {item.title ? item.title.length < 30 ? `${item.title}` : `${item.title.substring(0, 30)}...` : ''}</h1>
                             
                              {item.description ? item.description.length < 130 ? <span dangerouslySetInnerHTML={{ __html: `${item.description}` }} /> : <span className="video-des" dangerouslySetInnerHTML={{ __html: `${item.description.substring(0, 130)}...` }} /> : ''}

                              <div className="video-time">
                                {item.video_type == 2 ? '' : <span className="video-duration">{moment.duration(item.length, "seconds").format(' h[h] m[min] s[s]')}</span>}

                                <span className="video-duration">
                                  {moment(item.release_date, 'MM-DD-YYYY').format('LL')}
                                </span>
                                <div className="ml-auto"><span className="subscribers" title="Subscribers">{item.age_group}</span></div>
                              </div>
                            </div>

                          </div>
                          <ALL.Dialog fullScreen open={this.state.open}>
                            <div className="video-header">
                              <ALL.AppBar >
                                <ALL.Toolbar>

                                  <ALL.IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                  </ALL.IconButton>

                                </ALL.Toolbar>
                              </ALL.AppBar>
                            </div>
                            <div className='player-wrapper'>
                              <ReactPlayer
                                ref={p => { this.p = p }}
                                className='react-player'
                                url={localStorage.getItem('video_path')}
                                width='100%'
                                height='100%'
                                controls={true}
                                onStart={() => { this.p.seekTo(localStorage.getItem('playback_time') == 'null' ? 0 : localStorage.getItem('playback_time')) }}
                                onPause={() => this.handlePause(localStorage.getItem('video_id'))}
                                onDuration={this.handleDuration}
                                onProgress={this.onProgress}
                                playing={this.state.playing}
                                config={{
                                  file: {
                                    attributes: {
                                      autoPlay: true,
                                      controlsList: 'nodownload',
                                    }
                                  }
                                }}
                              />
                            </div>

                          </ALL.Dialog>
                        </div>

                      )) : ''
                        : ''}
                    </Carousel2>
                  </div>
                </div>
              </div>


            </div>




          )) : '' : '' : ''}
          <div className="footer-content container pt-5 ">
            <p>Welcome to View Africa International an online platform to watch your favourite TV Programmes, Films, Animated Movies, Documentaries and other programmes and Listen to Music of your choice directly on your mobile, laptop, desktop, and other internet-connected devices.</p>

            <p>You can watch all these programmes as many times according to your preference anytime without any commercial break by paying a minimum monthly charge. Keeping your interest in concern we keep on adding new programmes regularly on our platform to entertain you.</p>

            <p><b>What is the cost of a subscription?</b> You can watch all programmes of View Africa International on your smartphone, laptop, desktop, smart television, tablet, and every device that has an access to the internet. We have different subscription options including monthly, quarterly, half-yearly, and annually. Depending upon your requirement you can opt for any of them.</p>

            <p><b>Where you can watch these programmes?</b> You can watch these programmes anytime, anywhere, according to your convenience. You need to sign in with your View Africa International account from any of the device connected to the internet. You can also download your favourite programmes on your iOS, Android, or Windows 10 devices. You can watch these downloaded programmes at places which have no internet access or while travelling. View Africa International is always there to entertain you.</p>

            <p><b>Can children watch the programmes shown on View Africa International?</b> View Africa International is for people of all age groups. In our subscription, we facilitate the parents to retain full control of the programmes and let their children watch family-friendly programmes without any concern.</p>

            <p><b>How to cancel your subscription?</b> View Africa International is an easy-going online streaming platform without any irritating contracts or terms and conditions. You can withdraw your subscription anytime by just clicking on the unsubscribe option.</p>
          </div>
        </main>

        {!localStorage.getItem("access_token") ? <Footer /> : <Foot />}

        <Link to="#scroll-up"
          className={this.state.addClass == false ? "back-to-top" : 'remove-backtotop'}
          onClick={this.scrollUp}>
          <i className="fas fa-arrow-up"></i>
        </Link>

      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    videoList: state.service.videoListResponse ? state.service.videoListResponse.data : "",
    isLoading: state.service ? state.service.isLoading : "",
    watchlistResponse: state.service.watchlistResponse ? state.service.watchlistResponse : '',
    removeWatchlistResponse: state.service.removeWatchlistVideoResponse ? state.service.removeWatchlistVideoResponse : '',
    addVideoHistory: state.service.addVideoHistoryResponse ? state.service.addVideoHistoryResponse : '',
    dashboardData: state.service.videoListResponse ? state.service.videoListResponse : ""
  };
};

export default connect(mapStateToProps, {
  setLoader,
  getvideoList,
  addToWatchlist,
  removeWatchlistVideo,
  addUserVideoHistory
})(Home);
