// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { Nav, Foot, LoaderScreen } from "../includes";
import * as ALL from "@material-ui/core";
import { connect } from 'react-redux';
import { getUserWishlist, setLoader, getVideoInfo, addToWatchlist, removeWatchlistVideo, addUserVideoHistory } from "../../actions";
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { animateScroll as scroll } from 'react-scroll';
import { Line, Circle } from 'rc-progress';
import Carousel2 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 5,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 2,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 3,
        partialVisibilityGutter: 30
    }
};

class VideoSeries extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            secondsElapsed: null,
            duration: null,
            openTrailer: false,
            openSeries: false,
            openRVPlayer: false
        };
    }

    componentDidMount() {
        this.props.setLoader(true);
        let video_slug = this.props.match.params.videoSlug;
        let video_type = this.props.match.params.videoType;
        let profile_id = localStorage.getItem('current profile');
        this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
            // this.props.getVideoInfo(video_slug, profile_id).then(result => {
            if (this.props.videoInfoResponse.success) {
                this.props.setLoader(false);
            }
        })
            .catch((err) => {
                console.log(err);
            });
        scroll.scrollToTop();
    }

    handleClose = (id) => {

        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            console.log('ghh', this.props.addVideoHistory)
            if (this.props.addVideoHistory.success) {
                this.setState({
                    open: false
                });
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    //this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse.success) {
                        this.props.setLoader(false);
                    }
                })
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            });

    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleOpenTrailer = () => {
        this.setState({
            openTrailer: true
        });
    }

    handleCloseTrailer = () => {
        this.setState({
            openTrailer: false
        });
    }

    handleOpenSeriesVideo = (video_path, playback_time, id) => {
        localStorage.setItem('video_path', video_path);
        localStorage.setItem('playback_time', playback_time);
        localStorage.setItem('video_id', id);
        this.setState({
            openSeries: true
        });
    }

    handleCloseSeries = () => {
        this.setState({
            openSeries: false
        });
    }

    videoSeriesInfoOpen = (video_slug, video_type) => {
        // console.log(video_slug)
        let videoSlug = video_slug;
        let videoType = video_type;
        //let videoType = e.target.getAttribute('videoType');

        if (localStorage.getItem("access_token")) {
            this.props.history.push('/details/' + videoSlug + '/' + videoType);
        } else {
            this.props.history.push(PATHS.LOGIN);
        }
    }

    openRelatedVideoPlayer = (video_id, playback_time, video_path) => {
        localStorage.setItem('video_id', video_id);
        localStorage.setItem('playback_time', playback_time);
        localStorage.setItem('video_path', video_path);

        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN)
        } else {
            this.setState({
                openRVPlayer: true
            });
        }
    };

    handleCloseRVplayer = (id) => {

        let profile_id = localStorage.getItem('current profile');
        let video_id = localStorage.getItem('video_id');
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            if (this.props.addVideoHistory.success) {
                this.setState({
                    openRVPlayer: false
                });
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    //  this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse.success) {
                        this.props.setLoader(false);
                    }
                })
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            });

    }

    handleWatchlist = (id) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;

        this.props.addToWatchlist(profile_id, video_id).then(result => {
            // this.props.setLoader(true);
            if (this.props.watchlistResponse.success) {
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    // this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse) {
                        this.props.setLoader(false);
                    }
                })
                this.setState({ alertMessage: this.props.watchlistResponse.message, alertClass: "alert alert-success" });
            } else {
                this.setState({ alertMessage: this.props.watchlistResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            });

    };

    removeWatchlist = (id) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        this.props.removeWatchlistVideo(profile_id, video_id).then(result => {
            // this.props.setLoader(true);
            //console.log(this.props.removeWatchlistResponse)
            if (this.props.removeWatchlistResponse.success) {
                // this.props.setLoader(false);
                // this.setState({ alertMessage: this.props.removeWatchlistResponse.message, alertClass: "alert alert-success" });
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    //this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse) {
                        this.props.setLoader(false);
                    }
                })
            } else {
                this.setState({ alertMessage: this.props.removeWatchlistResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            })
    }

    handlePause = (id) => {

        this.setState({ playing: false })
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            if (this.props.addVideoHistory.success) {
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    // this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse.success) {
                        this.props.setLoader(false);
                    }
                })
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
    }

    handlePauseRVplayer = (id) => {
        console.log(id, 'xyz')
        this.setState({ playing: false })
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            if (this.props.addVideoHistory.success) {
                let video_slug = this.props.match.params.videoSlug;
                let video_type = this.props.match.params.videoType;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoInfo(video_slug, video_type, profile_id).then(result => {
                    // this.props.getVideoInfo(video_slug, profile_id).then(result => {
                    if (this.props.videoInfoResponse.success) {
                        this.props.setLoader(false);
                    }
                })
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
    }

    handleDuration = (duration) => {
        this.setState({ duration })
    }

    onProgress = (progress) => {
        if (!this.state.duration) {
            return
        }

        const secondsElapsed = progress.played * this.state.duration
        if (secondsElapsed !== this.state.secondsElapsed) {
            this.setState({ secondsElapsed })
        }
    }

    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN)
        }

        let iscurrent = true;
        return (
            <div className="black-body">
                <Nav />
                <div className="inner-page-h">

                    {this.props.videoInfoResponse ? this.props.videoInfoResponse.seriesDetail ?
                        this.props.videoInfoResponse.seriesDetail.get_videos.map((series, index) => (
                            series.isLastWatched === true && iscurrent ?

                                <div>
                                    {iscurrent = false}
                                    <div className="video-detail mb-5">
                                        <div className="video-detail-banner" style={{ backgroundImage: "url(" + PATHS.BASEPATH + series.video_thumnail_path + ")" }}>
                                            <div className="container inner-pt pb-5">
                                                <div className="row">
                                                    <div className="col-md-6 pt-5">
                                                        <div className="vdb-content">
                                                            <h1 className="mb-3">
                                                                {series.title}
                                                            </h1>
                                                            <div className="vdb-detail mb-4">
                                                                <span>{moment(series.created_at).format('YYYY')}</span>
                                                                <span><span className="rate-bg">{series.age_group}</span></span>
                                                                {/* <span>1h 54m</span> */}
                                                                {/* <span>Comedies</span> */}
                                                                {series.is_paid != 0 ? <span className="paid-red-label"> Premium </span> : ''}
                                                            </div>
                                                            <p>
                                                                {ReactHtmlParser(series.description)}
                                                            </p>
                                                            {/* <div className="vdb-extra-detail mb-3">
                                                <strong>Starring:</strong><span>Ramsey Nouah,Ayo Makun,Jim Iyke</span>
                                            </div> */}

                                                            <div className="vdb-btns mb-3">
                                                                {series.playback_time != 0 ?
                                                                    <div>
                                                                        {/* <Link onClick={this.handleClickOpen} className="red-btn">Continue watching</Link> */}
                                                                        <Link onClick={this.handleClickOpen} > <img src="/assets/img/player-btn.png" width='80px' height='80px' alt="" className="img-fluid" />
                                                                            <span>Continue watching</span>
                                                                            <Line percent={(series.playback_time / series.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></Link>
                                                                    </div>
                                                                    : <Link onClick={this.handleClickOpen} className="red-btn"><i className="fa fa-play mr-2" aria-hidden="true"></i>Play</Link>

                                                                }

                                                                {/* <Link onClick={this.handleClickOpen} className="red-btn">Play</Link> */}

                                                                {series.watchlistStatus == true ? <Link onClick={() => { this.removeWatchlist(series.id) }} className="red-btn"><i class="fas fa-heart mr-1"></i>Remove from Watchlist</Link> : <Link onClick={() => { this.handleWatchlist(series.id) }} className="red-btn"><i class="far fa-heart mr-1"></i>Add Watchlist</Link>}

                                                                {/* currentVideo */}
                                                                <ALL.Dialog fullScreen open={this.state.open}>
                                                                    <div className="video-header">
                                                                        <ALL.AppBar >
                                                                            <ALL.Toolbar>

                                                                                <ALL.IconButton edge="start" color="inherit" onClick={() => { this.handleClose(series.id) }} aria-label="close">
                                                                                    <CloseIcon />
                                                                                </ALL.IconButton>

                                                                            </ALL.Toolbar>
                                                                        </ALL.AppBar>
                                                                    </div>
                                                                    {console.log(series.video_path)}
                                                                    <div className='player-wrapper'>
                                                                        <ReactPlayer
                                                                            ref={p => { this.p = p }}
                                                                            className='react-player'
                                                                            url={PATHS.BASEPATH + series.video_path}
                                                                            playing
                                                                            width='100%'
                                                                            height='100%'
                                                                            controls={true}
                                                                            crossorigin='use-credentials'
                                                                            onStart={() => { this.p.seekTo(series.playback_time !== null ? series.playback_time : 0) }}
                                                                            onPause={() => { this.handlePause(series.id) }}
                                                                            onDuration={this.handleDuration}
                                                                            onProgress={this.onProgress}
                                                                            config={{
                                                                                file: {
                                                                                    attributes: {
                                                                                        autoPlay: true,
                                                                                        controlsList: 'nodownload',
                                                                                    },
                                                                                    tracks: [

                                                                                        // { kind: 'subtitles', src: 'https://viewafricainternational.24livehost.com/uploads/video_subtitles/1598434516-1597661022-sample.vtt', srcLang: 'en', default: true },
                                                                                        // { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-de.vtt', srcLang: 'de' },
                                                                                        // { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-es.vtt', srcLang: 'es' }
                                                                                        (series.subtitle_file.map(result => (
                                                                                            console.log('result', PATHS.BASEPATH + result.subtitles_file_path_vtt),
                                                                                            { kind: 'subtitles', src: PATHS.BASEPATH + result.subtitles_file_path_vtt, srcLang: result.get_name.picklist_item_id }
                                                                                        )))
                                                                                    ]
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </ALL.Dialog>
                                                                {/* end currentVideo */}

                                                                {series.get_video_trailer ?
                                                                    <div>
                                                                        <Link onClick={this.handleOpenTrailer} className="dark-btn"><img src="/assets/img/icon_trailer.png" width='20px' className='mr-1' />Watch Trailer</Link>
                                                                        <ALL.Dialog fullScreen open={this.state.openTrailer}>
                                                                            <div className="video-header">
                                                                                <ALL.AppBar >
                                                                                    <ALL.Toolbar>

                                                                                        <ALL.IconButton edge="start" color="inherit" onClick={() => { this.handleCloseTrailer() }} aria-label="close">
                                                                                            <CloseIcon />
                                                                                        </ALL.IconButton>

                                                                                    </ALL.Toolbar>
                                                                                </ALL.AppBar>
                                                                            </div>

                                                                            <div className='player-wrapper'>
                                                                                <ReactPlayer
                                                                                    ref={p => { this.p = p }}
                                                                                    className='react-player'
                                                                                    url={PATHS.BASEPATH + series.get_video_trailer.video_path}
                                                                                    // url={PATHS.BASEPATH + `${data.video_path}`}
                                                                                    //  url={['https://iandevlin.github.io/mdn/video-player-with-captions/video/sintel-short.mp4']}
                                                                                    playing
                                                                                    width='100%'
                                                                                    height='100%'
                                                                                    controls={true}
                                                                                    //crossOrigin='anonymous'
                                                                                    //  light={true}
                                                                                    // onStart={() => { this.p.seekTo(this.props.videoInfoResponse ? this.props.videoInfoResponse.videoInfo ? this.props.videoInfoResponse.videoInfo.get_video_history ? this.props.videoInfoResponse.videoInfo.get_video_history.playback_time : 0 : 0 : 0) }}
                                                                                    // onPause={() => { this.handlePause(this.props.match.params.videoId) }}
                                                                                    // onDuration={this.handleDuration}
                                                                                    // onProgress={this.onProgress}
                                                                                    config={{
                                                                                        file: {
                                                                                            attributes: {
                                                                                                //crossOrigin: 'anonymous',
                                                                                                autoPlay: true,
                                                                                                controlsList: 'nodownload',
                                                                                                // muted: true
                                                                                            },
                                                                                            tracks: [
                                                                                                this.props.videoInfoResponse ? this.props.videoInfoResponse.videoInfo ? this.props.videoInfoResponse.videoInfo.subtitle_file.map(data => (
                                                                                                    { kind: 'subtitles', src: PATHS.BASEPATH + data.subtitles_file_path_vtt, srcLang: data.get_name.picklist_item_id, default: true }
                                                                                                )) : '' : ''


                                                                                                // { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-de.vtt', srcLang: 'de' },
                                                                                                // { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-es.vtt', srcLang: 'es' }
                                                                                            ]
                                                                                        }
                                                                                    }}

                                                                                    playIcon={<img src="/assets/img/player-btn.png" width='100px' height='100px' alt="" className="img-fluid" />}


                                                                                />
                                                                            </div>

                                                                        </ALL.Dialog>
                                                                    </div>


                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div></div>
                                    <div className="vdb-more-detail">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="vdb-heading mb-3">More Details</h2>
                                                    <ul className="vdb-more-list">
                                                        {/* <li>
                                             <label>More Details</label>
                                             <span>Available to download</span>
                                         </li> */}
                                                        <li>
                                                            <label>Audio</label>

                                                            {series.audio_languages.map((audio, index) => (

                                                                <span> {audio.get_name ? audio.get_name.picklist_item_id : ''}</span>

                                                            ))}

                                                        </li>
                                                        <li>
                                                            <label>Genres</label>

                                                            {series.genres.map((genres, index) => (

                                                                <span>{genres.get_name ? genres.get_name.picklist_item_id : ''}</span>

                                                            ))}

                                                            {/* African Movies,Nollywood Movies,Crime Movies,Crime Comedies,Comedies */}

                                                        </li>
                                                        <li>
                                                            <label>Subtitles</label>
                                                            {series.subtitle_file.map((subttile, index) => (

                                                                <span>{subttile.get_name ? subttile.get_name.picklist_item_id : ''}</span>

                                                            ))}
                                                        </li>
                                                        {/* <li>
                                             <label>This movie is...</label>
                                             <span>Exciting</span>
                                         </li> */}
                                                        <li>
                                                            <label>Category</label>
                                                            {series.category.map((category, index) => (

                                                                <span> {category.get_category_name ? category.get_category_name.picklist_item_id : ''}</span>

                                                            ))}
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div> </div>
                                : ''
                        ))

                        : '' : ''}





                    <div className="related-video">
                        <div className="container mb-4">
                            {this.props.videoInfoResponse ? this.props.videoInfoResponse.seriesDetail ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="vdb-heading mb-4">Episodes ({this.props.videoInfoResponse.seriesDetail.get_videos.length})</h2>
                                    </div>
                                    <div class="v-history">
                                        {this.props.videoInfoResponse.seriesDetail.get_videos.map((series, index) => (


                                            <div class="vh-section">

                                                <div class="vh-left">
                                                    <div className="col-md-4 col-lg-3 mb-4">
                                                        <div className="rv-sec">
                                                            <Link to="#" onClick={() => this.handleOpenSeriesVideo(series.video_path, series.playback_time, series.id)}>
                                                                <img src={PATHS.BASEPATH + '/' + series.video_thumnail_path} alt="Related Video" className="" />
                                                                <h4>{series.title}</h4>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                    <div class="vh-content">
                                                        <h5>{series.title}</h5>
                                                        <Line percent={(series.playback_time / series.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' />
                                                        <div className="vdb-detail mb-4">
                                                            <span>{moment(series.created_at).format('YYYY')}</span>
                                                            <span><span className="rate-bg">{series.age_group}</span></span>
                                                             <span>1h 54m</span> 
                                                            <span>Comedies</span> 
                                                            {series.is_paid != 0 ? <span className="paid-red-label"> Premium </span> : ''}
                                                        </div>
                                                        <p> HELLP</p>
                                                       

                                                    </div>
                                                </div>
                                            </div>


                                        ))}
                                    </div>

                                </div>
                                : '' : ''}
                        </div>
                        {/* episodes player */}
                        <ALL.Dialog fullScreen open={this.state.openSeries}>
                            <div className="video-header">
                                <ALL.AppBar >
                                    <ALL.Toolbar>

                                        <ALL.IconButton edge="start" color="inherit" onClick={() => { this.handleCloseSeries() }} aria-label="close">
                                            <CloseIcon />
                                        </ALL.IconButton>

                                    </ALL.Toolbar>
                                </ALL.AppBar>
                            </div>
                            <div className='player-wrapper'>

                                <ReactPlayer
                                    ref={p => { this.p = p }}
                                    className='react-player'
                                    url={PATHS.BASEPATH + localStorage.getItem('video_path')}
                                    playing
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                    onStart={() => { this.p.seekTo(localStorage.getItem('playback_time') == 'null' ? 0 : localStorage.getItem('playback_time')) }}
                                    onPause={() => this.handlePause(localStorage.getItem('video_id'))}
                                    onDuration={this.handleDuration}
                                    onProgress={this.onProgress}
                                    config={{
                                        file: {
                                            attributes: {
                                                autoPlay: true,
                                                controlsList: 'nodownload',
                                            },
                                            tracks: [

                                                { kind: 'subtitles', src: 'https://viewafricainternational.24livehost.com/uploads/video_subtitles/1598434516-1597661022-sample.vtt', srcLang: 'en', default: true }

                                            ]
                                        }
                                    }}
                                />
                            </div>

                        </ALL.Dialog>
                    </div>



                    {this.props.videoInfoResponse ? this.props.videoInfoResponse.relatedVideo ?
                        <div className="container-fluid mb-4">
                            <div className="row">
                                <div className="col-md-12 pt-md-4 pb-5 pb-md-4 mt-1">
                                    <h2 className="vdb-heading mb-3">Related Videos</h2>

                                    <Carousel2
                                        additionalTransfrom={0}
                                        arrows
                                        autoPlaySpeed={3000}
                                        centerMode={false}
                                        className=""
                                        containerClass="container-with-dots"
                                        dotListClass=""
                                        draggable
                                        focusOnSelect={false}
                                        itemClass="image-item"
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        showDots={false}
                                        sliderClass=""
                                        slidesToSlide={1}
                                        swipeable
                                        responsive={responsive}
                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                    >
                                        {this.props.videoInfoResponse.relatedVideo.map(item => (
                                            <div className="item">
                                                <div className="sub-item">
                                                    <Link
                                                        className="movie-poster"
                                                        onClick={() => this.videoSeriesInfoOpen(item.video_slug, item.video_type)}
                                                    >
                                                        {item.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                        <img
                                                            src={PATHS.VIDEO_IMAGE_PATH + `/${item.video_thumnail_path}`}
                                                            alt="Poster"
                                                            className="img-fluid"
                                                            videoSlug={item.video_slug}
                                                            videoType={item.video_type}
                                                        />
                                                    </Link>

                                                    <div className="hide-item">
                                                        <div className="video-display">

                                                            <div className="video-play">
                                                                <Link to="#" onClick={() => this.openRelatedVideoPlayer(item.video_id, item.get_video_history ? item.get_video_history.playback_time : 0, item.video_path)}
                                                                >
                                                                    <i className="fa fa-play-circle play-icon-margin" aria-hidden="true"></i>
                                                                </Link>

                                                                {item.playback_time > 0 ? <div><span className='play-text'>Play</span><Line percent={(item.playback_time / item.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></div> : <span>Play</span>}

                                                            </div>

                                                            {item.watchlistStatus == 'true' ? <ALL.Tooltip title="Remove from watchlist" arrow placement="top">
                                                                <Link to="#" onClick={() => { this.removeWatchlist(item.id) }}>
                                                                    <i className="fas fa-trash-alt" aria-hidden="true" ></i>
                                                                </Link>
                                                            </ALL.Tooltip> : <ALL.Tooltip title="Add to Watchlist" arrow placement="top">
                                                                    <Link to="#" onClick={() => { this.handleWatchlist(item.id) }}>
                                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                                    </Link>
                                                                </ALL.Tooltip>}
                                                        </div>
                                                        <h1 className="video-t"> {item.title.length < 30 ? `${item.title}` : `${item.title.substring(0, 30)}...`}</h1>

                                                        {item.description.length < 130 ? <span dangerouslySetInnerHTML={{ __html: `${item.description}` }} /> : <span className="video-des" dangerouslySetInnerHTML={{ __html: `${item.description.substring(0, 130)}...` }} />}


                                                        <div className="video-time">
                                                            <span className="video-duration">{moment.duration(item.length, "seconds").format(' h[h] m[min] s[s]')}</span>
                                                            <span className="video-duration">{moment(item.created_at).format('YYYY')}</span>
                                                            <div className="ml-auto"><span className="subscribers" title="Subscribers">{item.age_group}</span></div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <ALL.Dialog fullScreen open={this.state.openRVPlayer}>
                                                    <div className="video-header">
                                                        <ALL.AppBar >
                                                            <ALL.Toolbar>

                                                                <ALL.IconButton edge="start" color="inherit" onClick={this.handleCloseRVplayer} aria-label="close">
                                                                    <CloseIcon />
                                                                </ALL.IconButton>

                                                            </ALL.Toolbar>
                                                        </ALL.AppBar>
                                                    </div>
                                                    <div className='player-wrapper'>
                                                        <ReactPlayer
                                                            ref={p => { this.p = p }}
                                                            className='react-player'
                                                            url={PATHS.BASEPATH + localStorage.getItem('video_path')}
                                                            width='100%'
                                                            height='100%'
                                                            controls={true}
                                                            //onPlay={() => { this.handlePlay(localStorage.getItem('video_id')) }}
                                                            onStart={() => { this.p.seekTo(localStorage.getItem('playback_time') == null || localStorage.getItem('playback_time') == 'undefined' ? 0 : localStorage.getItem('playback_time')) }}
                                                            onPause={() => this.handlePauseRVplayer(localStorage.getItem('video_id'))}
                                                            onDuration={this.handleDuration}
                                                            onProgress={this.onProgress}
                                                            light={true}
                                                            playing={this.state.playing}
                                                            config={{
                                                                file: {
                                                                    attributes: {
                                                                        autoPlay: true,
                                                                        controlsList: 'nodownload',
                                                                    },
                                                                    tracks: [
                                                                        { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-en.vtt', srcLang: 'en', default: true },
                                                                        { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-de.vtt', srcLang: 'de' },
                                                                        { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-es.vtt', srcLang: 'es' }
                                                                    ]
                                                                }
                                                            }}
                                                            playIcon={<img src="/assets/img/player-btn.png" width='100px' height='100px' alt="" className="img-fluid" />}
                                                        />
                                                    </div>
                                                </ALL.Dialog>
                                            </div>
                                        ))}
                                    </Carousel2>
                                </div>
                            </div>
                        </div>
                        : '' : ''}
                </div>
                <Foot />
            </div>

        );
    }
}

const mapStateToProps = state => {
    //  console.log(state.service.videoInfoResponse.data)
    return {
        isLoading: state.service.isLoading,
        videoInfoResponse: state.service.videoInfoResponse ? state.service.videoInfoResponse.data : '',
        removeWatchlistResponse: state.service.removeWatchlistVideoResponse ? state.service.removeWatchlistVideoResponse : '',
        watchlistResponse: state.service.watchlistResponse ? state.service.watchlistResponse : '',
        addVideoHistory: state.service.addVideoHistoryResponse ? state.service.addVideoHistoryResponse : ''
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getUserWishlist,
    getVideoInfo,
    addToWatchlist,
    removeWatchlistVideo,
    addUserVideoHistory
})(VideoSeries);
