// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { Nav, Foot, LoaderScreen } from "../includes";
import * as ALL from "@material-ui/core";
import { connect } from 'react-redux';
import { getVideoByCategory, setLoader, addToWatchlist, removeWatchlistVideo, addUserVideoHistory } from "../../actions";
// import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import InfiniteScroll from "react-infinite-scroll-component";
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import moment from 'moment';
import 'moment-duration-format';
import { Line } from 'rc-progress';


class VideoCategory extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            items: [],
            hasMore: true,
            duration: null,
            secondsElapsed: null,
            // playing: true,
            openSubscriptionBox: false
        };
        this.handlePause = this.handlePause.bind(this);
    }

    componentDidMount() {
        this.props.setLoader(true);
        // console.log(this.props.match.params.categoryName)
        let profile_id = localStorage.getItem('current profile');
        let limit = 20;
        let offset = 0;
        let category_id = this.props.match.params.id;
        this.props.getVideoByCategory(profile_id, limit, offset, category_id).then(result => {
            // console.log('----', this.props.videoCategory)
            this.props.setLoader(false);

            this.setState({
                items: this.props.videoCategory
            });

            // console.log(this.state.items)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        // debugger;
        let profile_id = localStorage.getItem('current profile');
        let limit = 20;
        let offset = this.state.items.length;
        let category_id = this.props.match.params.id;
        // console.log('offset', offset)
        this.props.getVideoByCategory(profile_id, limit, offset, category_id).then(result => {
            //  console.log('----', this.props.videoCategory)
            // this.props.setLoader(false);
            //  console.log(this.state.items.length)
            if (this.props.videoCategoryRecord.totalRecord == this.state.items.length) {
                this.setState({ hasMore: false });
                return;
            } else {
                this.setState({
                    items: this.state.items.concat(this.props.videoCategory)
                });
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    videoInfoOpen = (e) => {
        let videoSlug = e.target.getAttribute('videoSlug');
        let videoType = e.target.getAttribute('videoType');
        this.props.history.push('/details/' + videoSlug + '/' + videoType);
        //this.props.history.push('/details/' + videoSlug);
        // console.log(videoId, videoType)

        // if (localStorage.getItem("access_token")) {
        //     this.props.history.push('/video-detail/' + videoId + '/' + videoType);
        // } else {
        //     this.props.history.push(PATHS.LOGIN);
        // }
        // {
        //   localStorage.getItem("access_token") ? this.props.history.push('/video-detail/' + videoId + '/' + videoType) :
        //     this.props.history.push(PATHS.LOGIN)
        // }

    }

    handleClickOpen = (video_id, playback_time, video_path) => {
        console.log(video_id, playback_time, video_path)
        localStorage.setItem('video_id', video_id);
        localStorage.setItem('playback_time', playback_time);
        localStorage.setItem('video_path', video_path);

        this.setState({
            open: true
        });
    };

    handleWatchlist = (id, videoType) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let video_type = videoType;

        this.props.addToWatchlist(profile_id, video_id, video_type).then(result => {
            // this.props.setLoader(true);
            if (this.props.watchlistResponse.sucess) {
                let profile_id = localStorage.getItem('current profile');
                let limit = 20;
                let offset = 0;
                let category_id = this.props.match.params.id;
                this.props.getVideoByCategory(profile_id, limit, offset, category_id).then(result => {
                    // console.log('----', this.props.videoCategory)
                    this.props.setLoader(false);

                    this.setState({
                        items: this.props.videoCategory
                    });

                    // console.log(this.state.items)
                })
                // this.props.setLoader(false);
                this.setState({ alertMessage: this.props.watchlistResponse.message, alertClass: "alert alert-success" });
            } else {
                this.setState({ alertMessage: this.props.watchlistResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            });

    };

    removeWatchlist = (id, videoType) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let video_type = videoType;
        this.props.removeWatchlistVideo(profile_id, video_id, video_type).then(result => {
            console.log(this.props.removeWatchlistResponse)
            if (this.props.removeWatchlistResponse.success) {
                let profile_id = localStorage.getItem('current profile');
                let limit = 20;
                let offset = 0;
                let category_id = this.props.match.params.id;
                this.props.getVideoByCategory(profile_id, limit, offset, category_id).then(result => {
                    // console.log('----', this.props.videoCategory)
                    this.props.setLoader(false);

                    this.setState({
                        items: this.props.videoCategory
                    });

                    // console.log(this.state.items)
                })
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            })

    }

    handlePause = (id) => {
        this.setState({ playing: false })
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            if (this.props.addVideoHistory.success) {
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
    }

    handleDuration = (duration) => {
        this.setState({ duration })
    }

    onProgress = (progress) => {
        if (!this.state.duration) {
            return
        }

        const secondsElapsed = progress.played * this.state.duration
        if (secondsElapsed !== this.state.secondsElapsed) {
            this.setState({ secondsElapsed })
        }
    }


    handleClose = () => {
        this.setState({
            open: false
        });
    }

    subscriptionBox = () => {
        this.setState({
            openSubscriptionBox: true
        })
    }

    closeSubscriptionBox = () => {
        this.setState({
            openSubscriptionBox: false
        })
    }

    gotoSubscribePlan = () => {
        this.props.history.push(PATHS.RENEW_MEMBERSHIP)
    }

    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        // console.log('after load', this.state.items)
        return (
            <div className="black-body">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div>
                    <div className="inner-bnr banner-shadow mb-n-20">
                        <div className="container mt-5 pt-4 h-100">
                            <div className="inner-page-heading">
                                <h2>{this.props.match.params.categoryName}</h2>
                            </div>
                        </div>
                        
                    </div>
                    {this.state.items ?
                        <InfiniteScroll
                            dataLength={this.state.items.length}
                            next={this.fetchMoreData}
                            hasMore={this.state.hasMore}
                            loader={<div className="load-more-btn"><Spinner color="#e30813" size={36} speed={1} animating={true} />
                            </div>}
                        >
                            <div className="container-fluid page-min-h page-carousel mt-4">

                                <div className="row">
                                    <div className="col-md-12 pt-3 pt-md-4 pt-lg-5 pb-3 pb-md-4 mt-4">
                                        <div className="search-container pb-20">

                                            {this.state.items.map((data, index) => (
                                                <div className="item">
                                                    <Link to="#" className="movie-poster"
                                                            onClick={this.videoInfoOpen}
                                                        // onClick={data.is_paid != 0 ? this.subscriptionBox : this.videoInfoOpen}
                                                        >
                                                            {localStorage.getItem('isFreeAccount') == 'true' && data.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                            <img src={PATHS.VIDEO_IMAGE_PATH + `/${data.video_thumnail_path}`} alt="Poster" className="img-fluid" videoId={data.id}
                                                                videoType={data.video_type}
                                                                videoSlug={data.video_slug} />

                                                        </Link>
                                                    <div className="sub-item">
                                                        <Link to="#" className="movie-poster"
                                                            onClick={this.videoInfoOpen}
                                                        // onClick={data.is_paid != 0 ? this.subscriptionBox : this.videoInfoOpen}
                                                        >
                                                            {localStorage.getItem('isFreeAccount') == 'true' && data.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                            <img src={PATHS.VIDEO_IMAGE_PATH + `/${data.video_thumnail_path}`} alt="Poster" className="img-fluid" videoId={data.id}
                                                                videoType={data.video_type}
                                                                videoSlug={data.video_slug} />

                                                        </Link>
                                                        {/* <h4>{data.title.length < 15 ? `${data.title}` : `${data.title.substring(0, 30)}...`}</h4> */}
                                                        <div className="hide-item">

                                                            <div className="video-display">
                                                                <div className="video-play">
                                                                    <Link to="#"
                                                                        //onClick={() => this.handleClickOpen(data.video_id, data.playback_time, data.video_path)}
                                                                        onClick={localStorage.getItem('isFreeAccount') == 'true' && data.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(data.video_id, data.playback_time, data.video_path)}
                                                                    >
                                                                        <i className="fa fa-play-circle play-icon-margin" aria-hidden="true"></i>
                                                                    </Link>

                                                                    {data.playback_time > 0 ? <div><span className='play-text'>Play</span><Line percent={(data.playback_time / data.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></div> : <span>Play</span>}

                                                                </div>
                                                                {/* <Link to="#" onClick={() => this.handleClickOpen(data.video_id, data.playback_time, data.video_path)}>
                                                                <i className="fa fa-play play-icon-margin" aria-hidden="true" title="Watch"></i>
                                                            </Link> */}
                                                                {/* <ALL.Tooltip title="Add to Watchlist" arrow placement="top">
                                                                    <Link to="#" onClick={() => { this.handleWatchlist(data.id) }}>
                                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                                    </Link>
                                                                </ALL.Tooltip> */}
                                                                {data.watchlistStatus == 'true' ? <ALL.Tooltip title="Remove from watchlist" arrow placement="top">
                                                                    <Link to="#" onClick={() => { this.removeWatchlist(data.id, data.video_type) }}>
                                                                        <i className="fas fa-trash-alt" aria-hidden="true" ></i>
                                                                    </Link>
                                                                </ALL.Tooltip> : <ALL.Tooltip title="Add to Watchlist" arrow placement="top">
                                                                        <Link to="#" onClick={() => { this.handleWatchlist(data.id, data.video_type) }}>
                                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                                        </Link>
                                                                    </ALL.Tooltip>}

                                                            </div>

                                                            <h1 className="video-t"> {data.title.length < 30 ? `${data.title}` : `${data.title.substring(0, 30)}...`}</h1>
                                                            {data.description.length < 150 ? <span dangerouslySetInnerHTML={{ __html: `${data.description}` }} /> : <span className="video-des" dangerouslySetInnerHTML={{ __html: `${data.description.substring(0, 150)}...` }} />}
                                                            <div className="video-time">
                                                                {data.video_type == 2 ? '' : <span className="video-duration">{moment.duration(data.length, "seconds").format(' h[h] m[min] s[s]')}</span>}
                                                                
                                                                <span className="video-duration">
                                                                    {/* {moment(data.created_at).format('YYYY')} */}
                                                                    {moment(data.release_date, 'MM-DD-YYYY').format('ll')}
                                                                </span>
                                                                <div className="ml-auto"><span className="subscribers" title="Subscribers">{data.age_group}</span></div>
                                                            </div>
                                                        </div>

                                                        {/* subscription box popup */}

                                                        <ALL.Dialog
                                                            open={this.state.openSubscriptionBox}
                                                            aria-labelledby="responsive-dialog-title"
                                                            className="upgrade-plan-box"
                                                        >
                                                            <ALL.DialogTitle id="responsive-dialog-title">
                                                                Please upgrade your plan to unblock this video
                                    </ALL.DialogTitle>
                                                            <ALL.DialogContent>
                                                                <ALL.DialogActions className="justify-content-center mb-2">
                                                                    <Link onClick={this.closeSubscriptionBox} className="btn red-btn">
                                                                        Not Now
                                        </Link>
                                                                    <Link
                                                                        className="btn red-btn"
                                                                        onClick={this.gotoSubscribePlan}
                                                                    >
                                                                        Upgrade Plan
                                        </Link>

                                                                </ALL.DialogActions>
                                                            </ALL.DialogContent>
                                                        </ALL.Dialog>

                                                        {/* subscription box popup */}
                                                    </div>
                                                    <ALL.Dialog fullScreen open={this.state.open}>
                                                        <div className="video-header">
                                                            <ALL.AppBar >
                                                                <ALL.Toolbar>

                                                                    <ALL.IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                                                        <CloseIcon />
                                                                    </ALL.IconButton>

                                                                </ALL.Toolbar>
                                                            </ALL.AppBar>
                                                        </div>
                                                        <div className='player-wrapper'>

                                                            <ReactPlayer
                                                                ref={p => { this.p = p }}
                                                                className='react-player'
                                                                url={localStorage.getItem('video_path')}
                                                                // url={PATHS.VIDEO_IMAGE_PATH + `/${data.video_path}`}
                                                                width='100%'
                                                                height='100%'
                                                                controls={true}
                                                                onStart={() => { this.p.seekTo(localStorage.getItem('playback_time') == 'null' ? 0 : localStorage.getItem('playback_time')) }}
                                                                onPause={() => this.handlePause(localStorage.getItem('video_id'))}
                                                                onDuration={this.handleDuration}
                                                                onProgress={this.onProgress}
                                                                playing
                                                                config={{
                                                                    file: {
                                                                        attributes: {
                                                                            autoPlay: true,
                                                                            controlsList: 'nodownload',
                                                                        },
                                                                        tracks: [
                                                                            { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-en.vtt', srcLang: 'en', default: true },
                                                                            { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-de.vtt', srcLang: 'de' },
                                                                            { kind: 'subtitles', src: 'https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-es.vtt', srcLang: 'es' }
                                                                        ]
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                    </ALL.Dialog>
                                                </div>
                                            ))}

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </InfiniteScroll>
                        : <div class="wl-empty page-min-h mt-4" ><div class="wl-empty-poster"><img src="/assets/img/empty_list_watchlist.png" alt="" /></div><h3>Your Video category is currently empty</h3>
                            <p>Something went wrong on server.</p>
                        </div>}

                </div>

                <Foot />
            </div>

        );
    }
}

const mapStateToProps = state => {
    // console.log(state.service.videoCategoryResponse)
    return {
        videoCategoryRecord: state.service.videoCategoryResponse ? state.service.videoCategoryResponse : '',
        videoCategory: state.service.videoCategoryResponse ? state.service.videoCategoryResponse.data : '',
        watchlistResponse: state.service.watchlistResponse ? state.service.watchlistResponse : '',
        isLoading: state.service.isLoading,
        removeWatchlistResponse: state.service.removeWatchlistVideoResponse ? state.service.removeWatchlistVideoResponse : '',
        addVideoHistory: state.service.addVideoHistoryResponse ? state.service.addVideoHistoryResponse : '',
        enableReinitialize: true
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getVideoByCategory,
    addToWatchlist,
    removeWatchlistVideo,
    addUserVideoHistory
})(VideoCategory);
