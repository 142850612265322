import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { PATHS } from "../../constants";
import { viewProfile, videoSearch, setLoader, getSearchHistory, manageProfile, getHeaderMenus, getvideoList } from "../../actions";
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

/*
 TYPES
*/
type Props = {};

class Nav extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      search: '',
      searchString: '',
      searchAutoString: '',
      homeUrl: '',
      headerArrayMenu: []
    }
    this.onTagsChange = this.onTagsChange.bind(this);
  }

  componentDidMount() {
    let id = localStorage.getItem('current profile')
    this.props.viewProfile(id);
    this.props.manageProfile();

    this.props.getHeaderMenus().then(result => {
      let headerArray = this.props.headerMenu ? this.props.headerMenu : '';
      let homeUrl = this.state.homeUrl;

      if (this.props.currentProfile) {
        if (this.props.currentProfile.is_adult == 0) {
          headerArray = [];
          homeUrl = '/store/cartoon';
        } else if (this.props.currentProfile.is_adult == 1) {
          delete headerArray.Home;
        }
      }

      this.setState({
        homeUrl: homeUrl,
        headerArrayMenu: headerArray
      })
    });
    let profile_id = localStorage.getItem('current profile');
    this.props.getSearchHistory(profile_id).then(result => {
      this.props.setLoader(false);
    })
      .catch((err) => {
        console.log(err);
      });

  }

  onTagsChange = (event, values) => {
    this.setState({
      searchText: event.target.value,
      searchString: event.target.value
    })
  }

  autoSearch = (event, newValue) => {
    this.setState({
      searchText: event.target.value,
      searchAutoString: newValue.search_key
    })
  }

  keyPress = (e) => {
    if (e.which == 13) {
      let searchString = e.target.value;
      this.props.history.push("/search?search=" + searchString)
    } else {
      this.setState({
        searchString: e.target.value
      })
    }
  }

  submitSearch = (e) => {
    if (this.state.searchString) {
      e.preventDefault();
      this.setState({
        searchString: document.getElementById("search-string").value
      })
      return false;
    }

  }

  getCurrentProfile = (id, is_adult) => {
    localStorage.setItem('current profile', id);
    if (is_adult == 0) {
      this.props.history.push("/store/cartoon");
    } else {
      this.props.history.push("/");
    }
  }

  handleDynamicMenu = (id) => {
    let url = '';
    if (id == 'WebSeries') {
      url = id
    } else {
      url = id.toLowerCase();
    }

    this.props.history.push("/store/" + url);
  }

  render() {
    let fetchUrl = this.props.location.pathname;

    // if (!localStorage.getItem("access_token")) {
    //   let headerArray = this.props.headerMenu ? this.props.headerMenu : '';
    //   delete headerArray.Home;
    // }
    let searchHistoryList = [];

    if (this.props.searchHistoryResponse) {
      if (this.props.searchHistoryResponse.data) {
        if (this.props.searchHistoryResponse.data.length > 0) {
          searchHistoryList = this.props.searchHistoryResponse.data
        }
      }
    }
console.log(this.props.location.pathname)
    return (
      <header id="header" className="fixed-top btm-shadow" style={{ position: 'fixed' }}>
        

        <nav className="navbar navbar-expand-lg navbar-light">
          <Link to='/' className="logo mr-2 mr-md-3">
            <img src="/assets/img/logo.svg" alt="" className="img-fluid" />
          </Link>


          <div className="collapse navbar-collapse nav-menu" id="navbarsExample09">
            <ul className="navbar-nav mr-auto">

              <li className={fetchUrl === "/" ? 'nav-item active' : 'nav-item'}><Link to='/' className="nav-link">Home</Link></li>
              {/* {this.state.headerArrayMenu ?
                Object.keys(this.state.headerArrayMenu).map(key => (
                  //  console.log(this.props.headerMenu[key], key.replace(/\s+/g, '')),
                  <li className={(window.location.href.includes((key.replace(/\s+/g, '')).toLowerCase()) ? "nav-item active" : "nav-item")} key={key}><Link
                    onClick={() => this.handleDynamicMenu(key.replace(/\s+/g, ''))} to="#" className="nav-link"
                  >{key}</Link></li>
                ))
                : ''} */}
                <li className={fetchUrl === "/store/tvshows" ? 'nav-item active' : 'nav-item'}><Link to={PATHS.TVSHOW} className="nav-link">TV Shows</Link></li>
                <li className={fetchUrl === "/store/music" ? 'nav-item active' : 'nav-item'}><Link to={PATHS.MUSIC} className="nav-link">Music</Link></li>
                <li className={fetchUrl === "/store/movies" ? 'nav-item active' : 'nav-item'}><Link to={PATHS.MOVIE} className="nav-link">Movies</Link></li>
                <li className={fetchUrl === "/store/web-series" ? 'nav-item active' : 'nav-item'}><Link to={PATHS.SERIES}className="nav-link">Web-Series</Link></li>
                
            </ul>
          </div>
          {localStorage.getItem("access_token") ? <div className="search-sec">

            <form action='/search' onSubmit={this.submitSearch}>
              <Autocomplete
                className="search-box"
                freeSolo
                options={searchHistoryList}
                getOptionLabel={option => option.search_key}
                noOptionsText='No search history found'
                renderInput={params => (
                  <TextField
                    {...params}
                    id='search-string'
                    onKeyPress={this.keyPress}
                    name="search"
                    onChange={this.onTagsChange}
                    placeholder="Search here..."
                  />
                )}

              />


            </form>

          </div> : ''}
          <div className="d-flex">
            {localStorage.getItem("access_token") ?

              <div className="user-profile-view">

                <div className="profile-sec dropdown">
                  <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                    <img src={this.props.currentProfile ? this.props.currentProfile.avatar_url ? PATHS.BASEPATH + `/${this.props.currentProfile.avatar_url}` : "/assets/img/adult-3.png" : "/assets/img/adult-3.png"} className="img-radius" alt="User-Profile-Image" />
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right profile-notification">
                    <ul className="pro-body">
                      <div className="dropdown-left-item">
                        <li>
                          <Link to={PATHS.WISHLIST} className="dropdown-item">
                            Watchlist
                          </Link>
                        </li>

                        <li>
                          <Link to={PATHS.NOTIFICATION} className="dropdown-item">
                            Notification
                          </Link>
                        </li>

                        <li>
                          <Link to={PATHS.SETTING} className="dropdown-item">
                            Account & Settings
                          </Link>
                        </li>
                        <li>
                          <Link to={PATHS.VIDEO_HISTORY} className="dropdown-item">
                            Watch History
                          </Link>
                        </li>
                        <li>
                          <Link to={PATHS.LOGOUT} className="dropdown-item">
                            Sign Out?
                          </Link>
                        </li>
                      </div>

                      <div className="dropdown-right-item">
                        {this.props.userList ? this.props.userList.get_profiles ? this.props.userList.get_profiles.map((user, index) => (
                          <li key={index} className={(this.props.currentProfile ? this.props.currentProfile.id : '') == user.id ? 'mb-3 active-user' : 'mb-3'}>
                            <Link
                              to="#"
                              title={user.title}
                              data-id={user.id}
                              onClick={() => this.getCurrentProfile(user.id, user.is_adult)}
                              className="profile-dropdown-item"
                            >
                              {user.avatar_url ? user.avatar_url.length !== 0 ? <img src={PATHS.BASEPATH + `/${user.avatar_url}`} className="img-radius" width='32px' height='32px' alt={user.title} /> : <img src={'/assets/img/adult-3.png'} className="img-radius" width='32px' height='32px' alt="user" /> : <img src={'/assets/img/adult-3.png'} className="img-radius" width='32px' height='32px' alt="user" />}
                              <span>{user.title.length < 16 ? `${user.title}` : `${user.title.substring(0, 15)}...`}</span>
                            </Link>
                          </li>
                        )) : 'No user found' : ''}

                        {this.props.userList ? this.props.userList.get_profiles ? this.props.userList.get_profiles.length >= 5 ? ''
                          : <li className="mb-3">
                            <Link to={PATHS.PROFILE} className="profile-dropdown-item">
                              <img src={'/assets/img/add-icon.png'} className="img-radius" width='32px' height='32px' alt="user" />
                              <span> Add new</span>
                            </Link>
                          </li>
                          : '' : ''}

                        <li>
                          <Link to={PATHS.MANAGE_PROFILE} className="dropdown-item">
                            Manage profiles
                          </Link>
                        </li>


                      </div>

                    </ul>
                  </div>
                </div>

              </div>
              :
              <Link to={PATHS.LOGIN} className="get-started-btn scrollto">Sign In</Link>}

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>

      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.service.isLoading,
    currentProfile: state.service.viewProfileResponse ? state.service.viewProfileResponse.data : "",
    searchHistoryResponse: state.service.searchHistoryResponse ? state.service.searchHistoryResponse : '',
    userList: state.service.manageProfileResponse ? state.service.manageProfileResponse.data : "",
    headerMenu: state.service.headerMenuResponse ? state.service.headerMenuResponse.data : '',
  };
};

export default connect(mapStateToProps, {
  setLoader,
  viewProfile,
  videoSearch,
  getSearchHistory,
  manageProfile,
  getHeaderMenus,
  getvideoList
})(withRouter(Nav));