// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { Nav, Foot, LoaderScreen } from "../includes";
import * as ALL from "@material-ui/core";
import { connect } from 'react-redux';
import { getVideoHistory, setLoader, addToWatchlist, removeWatchlistVideo, removeUserVideoHistory } from "../../actions";
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import { Line } from 'rc-progress';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class VideoHistory extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            items: [],
            hasMore: true,
            alertMessage: "",
            alertClass: ""
        };
    }

    componentDidMount() {
        this.props.setLoader(true);

        let limit = 5;
        let offset = 0;
        let profile_id = localStorage.getItem('current profile');
        this.props.getVideoHistory(limit, offset, profile_id).then(result => {
            this.props.setLoader(true);
            if (this.props.videoHistoryRecord && this.props.videoHistoryRecord.success) {
                this.props.setLoader(false);
                this.setState({
                    items: this.props.videoHistoryResponse
                });
                // NotificationManager.success(
                // "Email sent successfully.",
                // "Success",
                // 3000
                // );
            }
            else if (
                this.props.videoHistoryRecord &&
                this.props.videoHistoryRecord.success === false
            ) {
                NotificationManager.error(
                    this.props.videoHistoryRecord.message,
                    "Error",
                    3000
                );
                // this.setState({
                // sendRequest: false,
                // });
            } else {
                // this.setState({
                // sendRequest: false,
                // });
                NotificationManager.error(
                    "Unable to procced this request",
                    "Error",
                    3000
                );
            }
            // if (this.props.videoHistoryRecord.success) {
            //     this.props.setLoader(false);
            //     this.setState({
            //         items: this.props.videoHistoryResponse
            //     });
            // }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    fetchMoreData = () => {
        let limit = 5;
        let offset = this.state.items.length;
        let profile_id = localStorage.getItem('current profile');
        this.props.getVideoHistory(limit, offset, profile_id).then(result => {
            if (this.props.videoHistoryRecord.success) {
                if (this.state.items.length >= this.props.videoHistoryRecord.totalPageCount) {
                    this.setState({ hasMore: false });
                    return;
                }
                else {
                    setTimeout(() => {
                        this.setState({
                            items: this.state.items.concat(this.props.videoHistoryResponse)
                        });
                    }, 500);

                }
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    videoInfoOpen = (e) => {
        let videoSlug = e.target.getAttribute('videoslug');
        let videoType = e.target.getAttribute('videotype');
        this.props.history.push('/details/' + videoSlug + '/' + videoType);
        //this.props.history.push('/details/' + videoSlug);
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    handleWatchlist = (id) => {

        let profile_id = localStorage.getItem('current profile');
        let video_id = id;

        this.props.addToWatchlist(profile_id, video_id).then(result => {
            if (this.props.watchlistResponse.success) {
                let limit = 2;
                let offset = 0;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoHistory(limit, offset, profile_id).then(result => {
                    this.setState({
                        items: this.props.videoHistoryResponse
                    });
                })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })
            .catch((err) => {
                console.log('err', err)
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            });

    };

    removeWatchlist = (id) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        this.props.removeWatchlistVideo(profile_id, video_id).then(result => {
            // console.log(this.props.removeWatchlistResponse)
            if (this.props.removeWatchlistResponse.success) {
                let limit = 2;
                let offset = 0;
                let profile_id = localStorage.getItem('current profile');
                this.props.getVideoHistory(limit, offset, profile_id).then(result => {
                    this.setState({
                        items: this.props.videoHistoryResponse
                    });
                })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            })

    }


    removeVideoHistory = (id) => {
        console.log(id);

        let successMessage = "";
        let errorMessage = "";

        this.props.removeUserVideoHistory(id).then(result => {
            this.props.setLoader(true);
            if (this.props.removeVideoHistoryResponse) {
                if (this.props.removeVideoHistoryResponse.success) {
                    this.props.setLoader(false);

                    successMessage = this.props.removeVideoHistoryResponse.message;
                    let limit = 2;
                    let offset = 0;
                    let profile_id = localStorage.getItem('current profile');
                    this.props.getVideoHistory(limit, offset, profile_id).then(result => {
                        this.setState({
                            items: this.props.videoHistoryResponse
                        });
                    })
                        .catch((err) => {
                            console.log(err);
                        });
                    this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
                } else {
                    this.props.setLoader(false);
                    errorMessage = this.props.removeVideoHistoryResponse.message;
                    this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
                }

                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            }
            this.props.setLoader(false);
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            })
    }






    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        return (
            <div className="black-body">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading">
                            <h2>Watch History</h2>
                        </div>
                    </div>
                    
                </div>
                <NotificationContainer />
                {this.state.alertMessage ? (
                    <div className={this.state.alertClass} role="alert">
                        {this.state.alertMessage}
                    </div>
                ) : null}
                {this.state.items.length ?
                    <InfiniteScroll
                        dataLength={this.state.items.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={<div className="load-more-btn"><Spinner color="#e30813" size={36} speed={1} animating={true} /></div>
                        }
                    >
                        <div className="container-fluid page-min-h page-carousel btm-shadow mt-4">

                            <div className="row">
                                <div className="col-md-12 pt-3 pt-md-4 pt-lg-5 pb-3 pb-md-4 mt-4">

                                    {/* <h6 >Videos removed from your watch history list will no longer influence video recommendations.
</h6> */}

                                    <div className="v-history"> {this.state.items.map((data, index) => (
                                        // console.log('data', data),
                                        // <li class="list-group-item d-flex justify-content-between lh-condensed video-history-list" key={index}>

                                        //     <div className="col-md-4 col-lg-3 mb-2">
                                        //         <div className="video-history-sec">
                                        //             <Link to="#" onClick={this.videoInfoOpen}>
                                        //                 <img src={PATHS.VIDEO_IMAGE_PATH + `/${data.get_video.video_thumnail_path}`} alt="Poster" videoid={data.get_video.id}
                                        //                     videoType={data.get_video.video_type} />

                                        //             </Link></div></div>
                                        //     <div>

                                        //         <h6 class="my-0">{data.get_video.title}</h6>
                                        //         <small class="text-muted">{ReactHtmlParser(data.get_video.description)}</small>
                                        //         <Line percent={data.playback_time} strokeWidth="4" strokeColor="#e30813" trailWidth='4' />

                                        //     </div>
                                        //     <span class="text-muted"><ALL.IconButton color="inherit" onClick={() => { this.removeVideoHistory(data.id) }} aria-label="close">
                                        //         <CloseIcon />
                                        //     </ALL.IconButton></span>
                                        // </li>


                                        <div className="vh-section" key={index}>
                                            {data.web_series_id == 0 ?
                                                data.get_video ?
                                                    <div className="vh-left">
                                                        <div className="vh-poster">
                                                            <Link to="#" onClick={this.videoInfoOpen}>
                                                                <img src={PATHS.VIDEO_IMAGE_PATH + `/${data.get_video.video_thumnail_path}`} alt="Poster" videoid={data.get_video.id}
                                                                    videoslug={data.get_video.video_slug}
                                                                    videotype={data.get_video.video_type} />
                                                                {/* <Line percent={data.playback_time/100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /> */}

                                                            </Link>
                                                        </div>
                                                        <div className="vh-content">
                                                            <h5>{data.get_video.title}</h5>
                                                            <p> {ReactHtmlParser(data.get_video.description)}</p>
                                                            {/* <Line percent={data.playback_time/100} strokeWidth="4" strokeColor="#e30813" trailWidth='4' /> */}

                                                        </div>
                                                    </div>

                                                    : '' : ''}


                                            {data.web_series_id !== 0 ? data.get_web_series ?

                                                <div className="vh-left">
                                                    <div className="vh-poster">
                                                        <Link to="#" onClick={this.videoInfoOpen}>
                                                            <img src={PATHS.VIDEO_IMAGE_PATH + `/${data.get_web_series.cover_image}`} alt="Poster" videoid={data.get_web_series.id}
                                                                videoslug={data.get_web_series.slug}
                                                                videotype={2} />
                                                            {/* <Line percent={data.playback_time/100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /> */}

                                                        </Link>
                                                    </div>
                                                    <div className="vh-content">
                                                        <h5>{data.get_web_series.title}</h5>
                                                        <p> {ReactHtmlParser(data.get_web_series.description)}</p>
                                                        {/* <Line percent={data.playback_time/100} strokeWidth="4" strokeColor="#e30813" trailWidth='4' /> */}

                                                    </div>
                                                </div>

                                                : '' : ''}


                                            {/* <div class="vh-right">
<ALL.IconButton color="inherit" onClick={() => { this.removeVideoHistory(data.id) }} aria-label="close">
<CloseIcon />
</ALL.IconButton>
</div> */}


                                        </div>




                                    ))} </div>
                                    {/* </InfiniteScroll> */}




                                </div>
                            </div>

                        </div>
                    </InfiniteScroll>
                    : <div className="wl-empty page-min-h mt-4" ><div className="wl-empty-poster"><img src="/assets/img/empty_list_watchlist.png" alt="" /></div><h3>Your Video history is currently empty</h3>
                        <p>Watch <Link to={PATHS.HOME}>Movies</Link> and <Link to={PATHS.HOME}>Webseries</Link> that you want to watch later by clicking Add to your history.</p>
                    </div>}


                <Foot />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        videoHistoryRecord: state.service.videoHistoryResponse ? state.service.videoHistoryResponse : '',
        videoHistoryResponse: state.service.videoHistoryResponse ? state.service.videoHistoryResponse.data : '',
        isLoading: state.service.isLoading,
        watchlistResponse: state.service.watchlistResponse ? state.service.watchlistResponse : '',
        removeWatchlistResponse: state.service.removeWatchlistVideoResponse ? state.service.removeWatchlistVideoResponse : '',
        removeVideoHistoryResponse: state.service.removeVideoHistoryResponse ? state.service.removeVideoHistoryResponse : ''
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getVideoHistory,
    addToWatchlist,
    removeWatchlistVideo,
    removeUserVideoHistory
})(VideoHistory);


