// @flow

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { SelectField } from "../../constants/redux-form";
import { required } from "../../constants/validation";
import { PATHS, PAYPAL_PAYMENT } from "../../constants";
import { Bottom, LoaderScreen } from "../includes";
import { setLoader, planDetail } from "../../actions";
import ReactHtmlParser from 'react-html-parser';
import { PayPalButton } from "react-paypal-button-v2";
import * as ALL from "@material-ui/core";
import moment from 'moment';

/*
 TYPES
*/

type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class SignupMobile extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            err: '',
            alertMessage: "",
            alertClass: "",
            plan_amount: '',
            paymentSuccess: '',
            open: false,
            cancelPaymentResponse: '',
            selectedPlanType: ''
        };
    }

    componentDidMount() {
        const query1 = new URLSearchParams(this.props.location.search);
        const isInner = query1.get('email');
        let email = isInner;
        this.props.planDetail(email);
    }

    handleChange = (event) => {
        this.setState({ plan_amount: event.target.value })
    };

    onError = (error) => {
        // TODO: We need to test this. Haven't found a way in sandbox
        // https://developer.paypal.com/docs/checkout/integration-features/handle-errors/
        if (error.message.indexOf('Expected an order id to be passed') >= 0)
            return;
        console.log(error.message.indexOf('Expected an order id to be passed'))
        return this.handleError(error.message, error);
    };

    cancelPayment = (data) => {
        console.log('PayPal payment cancelled', JSON.stringify(data, 0, 2), data.orderID);
        this.setState({ alertMessage: 'PayPal payment cancelled', alertClass: "alert alert-success", open: true, cancelPaymentResponse: data.orderID });
        console.log(this.state.alertMessage)
    }

    againPaymentHandle = () => {
        this.setState({
            open: false
        })
    }

    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        let self = this;

        let currentDate = moment(new Date());

        let addHour = moment(currentDate).add(2, 'hours');  // see the cloning?

        let today = moment(addHour).format('gggg-MM-DDTHH:mm:ss') + 'Z';
        return (
            <div className="form-container small-form-page pb-0">

                <div className="inner-page-h container pt-5">

                    {this.props.userDetail ? localStorage.setItem('vai_user_id', this.props.userDetail.id) : ''}
                    <div className="form-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <section className='plan-section p-0'>
                                        <div className="container">
                                            <div className="row">
                                                {this.props.planDetailResponse.data ?
                                                    <div className="col-md-12 plan-sec plan-sec-h">

                                                        <div className="card text-center mb-4">
                                                            <div className="title">
                                                                <h2>{this.props.planDetailResponse.data.title}</h2>
                                                            </div>
                                                            <div className="price">
                                                                {this.props.planDetailResponse ? this.props.planDetailResponse.data ? this.props.planDetailResponse.data.plan_price ? this.props.planDetailResponse.data.plan_price.map((feature, index) => (
                                                                    <h4><sup>{this.props.planDetailResponse.data.currency}</sup>{feature.price} /{feature.duration}</h4>
                                                                )) : '' : '' : ''}

                                                            </div>
                                                            <div>{ReactHtmlParser(this.props.planDetailResponse.data.description)}</div>
                                                            <div className="option">
                                                                <ul>

                                                                    {this.props.planDetailResponse ? this.props.planDetailResponse.data ? this.props.planDetailResponse.data.feature ? this.props.planDetailResponse.data.feature.map((feature, index) => (
                                                                        <li key={index}> <i class="fa fa-check" aria-hidden="true"></i> {feature.title} - {feature.value}</li>
                                                                    )) : '' : '' : ''}
                                                                </ul>
                                                            </div>
                                                            <section className='subscription-section p-0'>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-md-12">



                                                                            <Field
                                                                                name="plan_amount"
                                                                                validate={[required]}
                                                                                onChange={this.handleChange}
                                                                                component={SelectField}
                                                                                value={this.state.plan_amount}
                                                                            >
                                                                                <option value="">Please Select</option>
                                                                                {this.props.planDetailResponse.data ? this.props.planDetailResponse.data.plan_price ? this.props.planDetailResponse.data.plan_price.map((data, index) => (<option value={data.plan_id}>{data.title} ${data.price} {data.currency} - {data.duration}</option>)) : '' : ''}

                                                                            </Field>
                                                                            <PayPalButton
                                                                                options={{
                                                                                    vault: true,
                                                                                    clientId: PAYPAL_PAYMENT.CLIENT_ID
                                                                                }}

                                                                                style={{

                                                                                    label: 'subscribe',
                                                                                }}
                                                                                createSubscription={(data, actions) => {
                                                                                    if (this.state.plan_amount) {
                                                                                        return actions.subscription.create({
                                                                                            plan_id: this.state.plan_amount,
                                                                                            start_time: today
                                                                                        });
                                                                                    } else {
                                                                                        actions.disable();
                                                                                    }

                                                                                }}

                                                                                onError={(err) => { console.error('PayPal error', err) }}

                                                                                onCancel={(data) => this.cancelPayment(data)}

                                                                                onApprove={(data, actions) => {
                                                                                    console.log(data, actions)
                                                                                    // Capture the funds from the transaction
                                                                                    return actions.subscription.get().then(function (details) {
                                                                                        self.props.setLoader(true);
                                                                                        fetch(PATHS.API_PATH + 'user/save-user-payment', {
                                                                                            method: 'post',
                                                                                            headers: {
                                                                                                'content-type': 'application/json'
                                                                                            },
                                                                                            body: JSON.stringify({
                                                                                                user_id: localStorage.getItem('vai_user_id'),
                                                                                                subscription_id: data.subscriptionID
                                                                                            })


                                                                                        }).then((response) => {

                                                                                            if (response.ok) {
                                                                                                self.props.setLoader(false);
                                                                                                //window.location.href = PATHS.SIGNUP_MOBILE_SUCCESS;
                                                                                                self.props.history.push(PATHS.SIGNUP_MOBILE_SUCCESS)
                                                                                                console.log(response)
                                                                                                return response.json();
                                                                                            } else {
                                                                                                throw new Error('Server response wasn\'t OK');
                                                                                            }
                                                                                        })


                                                                                    }).catch(function (err) {
                                                                                        console.log(err, 'arey')
                                                                                        // redirect to error page
                                                                                    });
                                                                                }}
                                                                            />

                                                                            <ALL.Dialog
                                                                                open={this.state.open}
                                                                                aria-labelledby="responsive-dialog-title"
                                                                            >
                                                                                <ALL.DialogTitle id="responsive-dialog-title">Payment is cancelled of Order Id:  {this.state.cancelPaymentResponse}</ALL.DialogTitle>
                                                                                <ALL.DialogContent>
                                                                                    <ALL.DialogContentText>

                                                                                        Please try again to get subscription of VAI plans. And enjoy movies, webseries etc.

                                                                            </ALL.DialogContentText>
                                                                                </ALL.DialogContent>
                                                                                <ALL.DialogActions>
                                                                                    <ALL.Button
                                                                                        onClick={this.againPaymentHandle}
                                                                                        color="primary">
                                                                                        Try Again
                                                                            </ALL.Button>

                                                                                </ALL.DialogActions>
                                                                            </ALL.Dialog>


                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>

                                                    </div>
                                                    : ''}


                                            </div>
                                        </div>
                                    </section>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>




                <Bottom />
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.service ? state.service.isLoading : "",
        planDetailResponse: state.service.planDetailResponse ? state.service.planDetailResponse : '',
        userDetail: state.service.planDetailResponse ? state.service.planDetailResponse.user_data : ''
    };
};

export default connect(mapStateToProps, {
    setLoader,
    planDetail,
})(reduxForm({ form: "Register", enableReinitialize: true })(SignupMobile));

