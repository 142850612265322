// @flow

import React, { Component } from "react";

import { PATHS } from "../../constants";
import { Bottom } from "../includes";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setLoader, confirmUserAccount } from "../../actions";

/*
 TYPES
*/

type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class ConfirmAccount extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount() {

        if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'notAutherised') {
            this.props.history.push(PATHS.HOME);
        }
        let token = this.props.match.params.token;
        this.props.confirmUserAccount(token)
    }

    render() {

        return (
            <div className="form-container small-form-page pb-0">
                <div className="inner-page-h container pt-signsuccess">
                    <div className="form-body">
                        <div className="form-content">
                            <div className="mb-4 signup-success">
                                <img src="/assets/img/signup-success.png" className="img-fluid" alt="thank you" />
                                <h4>Your account has been confirmed. </h4>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed dapibus erat. In orci erat, hendrerit vestibulum arcu at, ultricies rhoncus nunc. Aenean in magna ut odio dignissim dignissim.</p> */}
                                <Link to={PATHS.LOGIN} className="btn btn-block btn-red mb-2">Sign In</Link>
                            </div>
                        </div>
                    </div>

                </div>
                <Bottom />
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        confirmAccountResponse: state.service.confirmAccountResponse ? state.service.confirmAccountResponse : '',
        isLoading: state.service.isLoading,
    };
};

export default connect(mapStateToProps, {
    setLoader,
    confirmUserAccount
})(ConfirmAccount);
