// @flow

import React, { Component } from "react";
import { Nav, Footer, Foot, LoaderScreen } from "../includes";
import { setLoader, getAboutUsContent, contactForm, contactUsSubmit } from "../../actions";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Field, reduxForm } from "redux-form";
import { renderTextArea1, renderInputNormal } from "../../constants/redux-form";
import { required, email, whiteSpace, phoneNumberValidation, number } from "../../constants/validation";
import Helmet from "react-helmet";

/*
 TYPES
*/

type Props = {};

class Producer extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            isInner: '',
            alertMessage: "",
            alertClass: "",
        }
    }

    componentDidMount() {
        this.props.setLoader(true);
        scroll.scrollToTop();
        this.props.getAboutUsContent().then(result => {
            if (this.props.loadData && this.props.loadData.success) {
                this.props.setLoader(false);
            } else {
                this.props.setLoader(false);
            }
        })

        const query1 = new URLSearchParams(this.props.location.search);
        const isInner = query1.get('isInner');

        this.setState({
            isInner: isInner
        })

        
    }

    onSubmit = formValues => {
        let errorMessage = "";
        setTimeout(() => {

            this.props.contactUsSubmit(formValues)
                .then(result => {
                    this.props.setLoader(false);
                    if (this.props.contactResponse.success) {
                        this.props.setLoader(false);
                        this.props.reset('contactForm');
                        this.setState({ alertMessage: this.props.contactResponse.message, alertClass: "alert alert-success" });
                    } else {
                        this.props.setLoader(false);
                        errorMessage = this.props.contactResponse.message;
                        this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
                    }

                    setTimeout(() => {
                        this.setState({ alertMessage: "", alertClass: "" })
                    }, 3000);
                })
                .catch(e => {
                    this.props.setLoader(false);
                });
        }, 500);
    };

    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        return (
            <div className="black-body">
                <Helmet>
                    <title>Share Your Content | Producer | View Africa International</title>
                    <meta name="description" content="We provides a platform to submit your video content. Please fill the form on this page with full details our team will contact you shortly." />
                </Helmet>
                {this.state.isInner == 1 ? '' : <Nav />}
                {this.state.isInner == 1 ? '' : <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                    <i className="fas fa-angle-left mr-2"></i>
                    Back
                </Link>}

                {this.state.isInner == 1 ? '' : <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading sb-pt">
                            <h2>Producer</h2>
                        </div>
                    </div>
                </div>}
                <div className="mb-n-22">
                    <section className="d-flex flex-column justify-content-center p-0">
                        <div className="page-slider pt-5 pb-0">
                            <Carousel showThumbs={false} infiniteLoop={true} autoPlay showStatus={false}>

                                <Link onClick={this.videoInfoOpen}>
                                    <div>
                                        <img src='https://admin.viewafricainternational.com//uploads/1612876543-Origins of mankind 01_Thumbnail.jpg' />
                                    </div>
                                </Link>
                                <Link onClick={this.videoInfoOpen}>
                                    <div>
                                        <img src='https://admin.viewafricainternational.com//uploads/mobile-1612876192-Tshetha_Thumbnail.jpg' />
                                    </div>
                                </Link>

                            </Carousel>
                        </div>
                    </section>
                </div>

                <div className="mt-5 pt-4">

                    <div className="container verticalcenter">

                        <div className="thn_post_wrap">

                            <h2 className="mb-3 mb-md-4 text-c">
                                About Producer
                            </h2>
                            {this.props.response ? ReactHtmlParser(this.props.response.content) : ''}

                            <h5>How to submit your content ?</h5>
                            <span>To submit your content first you need to  fill bellow form with content details after that our team will call you internally.</span>
                        </div>


                    </div>

                </div>

                <div className="container-fluid mt-4">
                    <h2 className="mb-3 mt-5 mb-md-4 text-c">
                        Producer movies
                    </h2>
                    <div className="row">
                        <div className="col-md-12 pt-3 pt-md-4 pt-lg-5 pb-3 pb-md-4 mt-4">
                            <div className="search-container">

                                <div className="item">
                                    <Link to="#" className="movie-poster">

                                        <img src={'https://admin.viewafricainternational.com//uploads/1612876543-Origins of mankind 01_Thumbnail.jpg'} alt="Poster" className="img-fluid" />
                                    </Link>


                                </div>
                                <div className="item">
                                    <Link to="#" className="movie-poster">

                                        <img src={'https://admin.viewafricainternational.com//uploads/mobile-1612876192-Tshetha_Thumbnail.jpg'} alt="Poster" className="img-fluid" />
                                    </Link>


                                </div>
                                <div className="item">
                                    <Link to="#" className="movie-poster">

                                        <img src={'https://admin.viewafricainternational.com//uploads/1612876543-Origins of mankind 01_Thumbnail.jpg'} alt="Poster" className="img-fluid" />
                                    </Link>


                                </div>

                            </div>



                        </div>
                    </div>

                </div>
                <div className="mt-5 pt-4 pb-4">

                    <div className="container verticalcenter">
                        <h2 className="mb-3 mb-md-4 text-c">
                            Share your Content
                        </h2>
                        <div className="col-md-12">

                            <form
                                name="contactForm"
                                onSubmit={this.props.handleSubmit(this.onSubmit)}
                                className="php-email-form mt-3 mt-md-4"
                            >
                                {this.state.alertMessage ? (
                                    <div className={this.state.alertClass} role="alert">
                                        {this.state.alertMessage}
                                    </div>
                                ) : null}
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        <Field name="name" type="text" id="name" validate={[required, whiteSpace]} placeholder={`Producer Name`} component={renderInputNormal} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <Field name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderInputNormal} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        <Field name="phone_number" id="phone_number" type="text" validate={[number, phoneNumberValidation, required]} placeholder={`Phone Number`} component={renderInputNormal} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <Field name="content_url" type="url" id="content_url" validate={[required]} placeholder={`Content Url`} component={renderInputNormal} />
                                    </div>
                                </div>



                                <div className="form-group">
                                    <Field name="message" id="message" validate={[required]} placeholder={`Write something about your content (synopsis, treatmeant, genre etc)`} component={renderTextArea1} />
                                </div>
                                <div className="text-center">

                                    <button type="submit" className="contact-btn get-started-btn">Share Content</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                {this.state.isInner == 1 ? '' : (!localStorage.getItem("access_token") ? <Footer /> : <Foot />)}
            </div >

        );
    }
}

const mapStateToProps = state => {
    return {
        response: state.service.getAboutUsResponse ? state.service.getAboutUsResponse.data : '',
        loadData: state.service.getPolicyResponse ? state.service.getPolicyResponse : '',
        contactResponse: state.service.contactResponse,
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getAboutUsContent,
    contactForm,
    contactUsSubmit
})(reduxForm({ form: "contactForm" })(Producer));

