// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
//import * as image from '../../images';
import { reduxForm } from "redux-form";

import { PATHS } from "../../constants";
import { connect } from 'react-redux';
import { viewProfile, videoSearch, setLoader, getVideoNotification } from "../../actions";
//var jwtDecode = require("jwt-decode");

/*
 TYPES
*/
type Props = {};
class TopBar extends Component<Props> {

    componentDidMount() {
        //console.log(localStorage.getItem('current profile'))
        let id = localStorage.getItem('current profile')
        this.props.viewProfile(id);
        this.props.getVideoNotification();
    }

    // loadScript() {
    //   const script = document.createElement("script");
    //   script.src = "./main.js";
    //   script.async = true;
    //   document.body.appendChild(script);
    // }

    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        }
    }

    searchVideo = () => {
        this.props.setLoader(true);
        let search = this.state.searchText;
        let profile_id = localStorage.getItem('current profile')
        this.props.videoSearch(search, profile_id).then(result => {
            this.props.setLoader(false);
            window.location.href = '/search';
        })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {

        let fetchUrl = window.location.pathname;

        // let isRole = 'student';

        // if (localStorage.getItem('access_token')) {
        //     let token = localStorage.getItem('access_token');
        //     const decoded: string = jwtDecode(token);
        //     isRole = decoded.role;
        // }

        //console.log(this.props.currentProfile)

        return (
            <header id="header" className="fixed-top btm-shadow header-position">
                <div className="container-fluid left">

                    <div className="row justify-content-center">
                        <div className="col-xl-12 d-flex align-items-center justify-content-between">
                            <a href={PATHS.HOME} className="logo">
                                <img src="/assets/img/logo.svg" alt="" className="img-fluid" />
                            </a>
                            <nav className="nav-menu d-none d-lg-block">
                                <ul>
                                    <li className={fetchUrl === "/" ? 'active' : ''}><a href={PATHS.HOME}>Home</a></li>
                                    <li><Link to="#about">Documentaries</Link></li>
                                    <li className="drop-down"><Link to="">Movies/Videos</Link>
                                        <ul>
                                            <li><Link to="#">Drop Down 1</Link></li>
                                            <li className="drop-down"><Link to="">Deep Drop Down</Link>
                                                <ul>
                                                    <li><Link to="#">Deep Drop Down 1</Link></li>
                                                    <li><Link to="#">Deep Drop Down 2</Link></li>
                                                    <li><Link to="#">Deep Drop Down 3</Link></li>
                                                    <li><Link to="#">Deep Drop Down 4</Link></li>
                                                    <li><Link to="#">Deep Drop Down 5</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link to="#">Drop Down 2</Link></li>
                                            <li><Link to="#">Drop Down 3</Link></li>
                                            <li><Link to="#">Drop Down 4</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="#portfolio">Latest</Link></li>
                                    <li className={fetchUrl.includes('contact-us') ? 'active' : ''}><Link to={PATHS.CONTACT}>Contact</Link></li>

                                </ul>
                            </nav>

                            <div className="search-sec">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="basic-addon2"
                                        onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                                        value={this.state.searchText} />
                                    <div className="input-group-append">
                                        <button type="button" className="input-group-text" onClick={this.searchVideo}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="user-profile-view">

                                <div className="notify-sec mr-3">
                                    <div className="dropdown">
                                        <a className="dropdown-toggle" href="#" data-toggle="dropdown">
                                            <i className="far fa-bell"></i>
                                            <span className="badge bg-danger">
                                                <span className="sr-only"></span>
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right notification">
                                            <div className="noti-head">
                                                <h6 className="d-inline-block m-b-0">Notifications</h6>
                                                <div className="float-right">
                                                    <Link to="#!" className="m-r-10">mark as read</Link>
                                                    <Link to="#!"> Clear all</Link>
                                                </div>
                                            </div>
                                            <ul className="noti-body ps">
                                                <li className="n-title">
                                                    <p className="m-b-0">NEW</p>
                                                </li>
                                                {this.props.videoNotification.data ? this.props.videoNotification.data.length !== 0 ? this.props.videoNotification.data.map(p => (
                                                    <li className="notification">
                                                        <div className="media">
                                                            <img src="/assets/img/avatar-1.jpg" className="img-radius" alt="User-Profile-Image" />
                                                            <div className="media-body">
                                                                <p>
                                                                    <strong>{p.title}</strong>
                                                                    <span className="n-time text-muted">
                                                                        <i className="far fa-clock mr-1"></i>
                    10 min
                </span>
                                                                </p>
                                                                <p>{p.content}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )) : '' : ''}

                                            </ul>
                                            <div className="noti-footer">
                                                <a href="#!">show all</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="profile-sec dropdown">
                                    <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown">
                                        <img src={this.props.currentProfile ? this.props.currentProfile.avatar_url : "/assets/img/avatar-1.jpg"} className="img-radius" alt="User-Profile-Image" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right profile-notification">
                                        <div className="pro-head">
                                            <img src={this.props.currentProfile ? this.props.currentProfile.avatar_url : "/assets/img/avatar-1.jpg"} className="img-radius" alt="User-Profile-Image" /><span>{this.props.currentProfile ? this.props.currentProfile.title : ''}</span>
                                            <Link to={PATHS.LOGOUT} className="dud-logout" title="Logout">
                                                <i className="fas fa-sign-out-alt"></i>
                                            </Link>
                                        </div>
                                        <ul className="pro-body">
                                            <li><Link to={PATHS.MANAGE_PROFILE} className="dropdown-item">
                                                <i className="far fa-user"></i>
                                    Manage profiles
                                </Link>
                                            </li>
                                            {/* <li><a href="email_inbox.html" className="dropdown-item">
                                                <i className="far fa-envelope"></i>
                                    My Messages
                                </a>
                                            </li> */}
                                            <li><Link to={PATHS.PROFILE} className="dropdown-item">
                                                <i className="fa fa-plus"></i>
                                    Add new
                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </header>

        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.service.isLoading,
        currentProfile: state.service.viewProfileResponse ? state.service.viewProfileResponse.data : "",
        videoNotification: (state.service.videoNotification) ? state.service.videoNotification : "",
    };
};

export default connect(mapStateToProps, {
    setLoader,
    // contactForm,
    // contactUsSubmit,
    getVideoNotification,
    viewProfile,
    videoSearch
})(reduxForm({ form: "contactForm" })(TopBar));
