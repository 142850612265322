// @flow

import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderPassword, renderInput } from "../../constants/redux-form";
import { required, passwordValidation, phoneNumberValidation, number, whiteSpace } from "../../constants/validation";
import { PATHS } from "../../constants";
import * as Styled from "../page.styled";
// import * as ALL from "@material-ui/core";
import { LoaderScreen, Foot, Nav } from "../includes";
import { setLoader, getUserProfile, updateUserProfile } from "../../actions";
// import { LoaderScreen } from "../includes";
import { Link } from "react-router-dom";

/*
 TYPES
*/

type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class Account extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            // alertMessage: "",
            // alertClass: "",
            icon: 'fas fa-eye',
            type: 'password',
            errorMsg: ""
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.setLoader(false);
        this.props.getUserProfile();
    }

    onSubmit = formValue => {
        // console.log(formValue.password_confirmation, formValue.password)
        let errorMsg = "";
        if (formValue.password !== formValue.password_confirmation) {
            errorMsg = "Please match your password";
            return false;
        }
        this.setState({
            errorMsg: errorMsg
        });
        this.props.setLoader(true);
        let successMessage = "";
        let errorMessage = "";

        let formValues = {
            first_name: formValue.first_name,
            last_name: formValue.last_name,
            contact_number: formValue.contact_number,
            email: formValue.email,
            password: formValue.password,
            password_confirmation: formValue.password_confirmation,
            active: 1
        };
        this.props.updateUserProfile(formValues).then(result => {
            this.props.setLoader(false);
            if (this.props.userProfileResponse) {
                if (this.props.userProfileResponse.success) {
                    this.props.setLoader(false);
                    successMessage = this.props.userProfileResponse.message;
                    this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });

                    this.props.getUserProfile();
                    //   setTimeout(() => {
                    //     this.props.history.push(PATHS.QUESTIONNAIRE);
                    //   }, 2000);
                } else {
                    errorMessage = this.props.userProfileResponse.message;
                    this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
                    this.props.setLoader(false);
                }

                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

            }
        });


    };


    handleChange(e) {
    }

    showHide = () => {
        // e.preventDefault();
        // e.stopPropagation();
        this.setState({
            type: this.state.type === 'text' ? 'password' : 'text',
            icon: this.state.icon === 'fas fa-eye' ? 'fas fa-eye-slash' : 'fas fa-eye'
        });
    }

    isPasswordMatch = event => {
        var pass1 = document.getElementById("password").value;
        var pass2 = document.getElementById("password_confirmation").value;

        var errorMsg = "";
        var ok = true;
        if (pass1 !== pass2) {
            errorMsg = "Please match your password";
            this.setState({
                errorMsg: errorMsg,
                alertClass: "alert alert-danger"
            });
            return false;
        } else {
            this.setState({
                errorMsg: "",
                alertClass: ""
            });
        }

        return ok;
    };

    // phoneValidate = () => {
    //     var contactLength = document.getElementById("contact_number").value;
    //     console.log(contactLength)
    //     var errorMsg = ""
    //     if (contactLength.length < 6 || contactLength.length > 11) {
    //         errorMsg = 'Phone number length must be between 6 to 11';
    //         this.setState({
    //             errorMsg: errorMsg,
    //             alertClass: "alert alert-danger"
    //         });
    //         return false;
    //     } else {
    //         this.setState({
    //             errorMsg: "",
    //             alertClass: ""
    //         });
    //     }
    // }

    renderPassword1 = ({ placeholder, id, input, icon, label, type, meta: { touched, error } }: Props) => (
        <div className="input-group mb-3">
            {/* <div className="input-group-prepend">
        <span className="input-group-text"><i className="fas fa-unlock-alt"></i></span>
      </div> */}
            <input  {...input} type={'password'} placeholder={placeholder} className="form-control" id={id} />
            {/* <div className="input-group-prepend">
        <span className="input-group-text" onClick={this.showHide}><i className={icon}></i></span>
      </div> */}
            {touched && (error && <Styled.error>{error}</Styled.error>)}
        </div>
    );

    render() {
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN);
        }
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        return (
            <div className="form-container small-form-page pb-0">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-page-h container pt-form">
                    <div className="form-body">
                        <div className="container">
                        
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="form-header mb-4">
                                        <div className="tc-heading">
                                            <h2 className="mb-3 mb-md-2">Update Profile</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12">

                                    <div className="trans-card p-4 mb-4">
                                        {this.state.alertMessage ? (
                                            <div className={this.state.alertClass} role="alert">
                                                {this.state.alertMessage}
                                            </div>
                                        ) : null}
                                        {this.state.errorMsg ? (
                                            <div className={this.state.alertClass}>{this.state.errorMsg}</div>
                                        ) : null}
                                        <form
                                            name="accountForm"
                                            onSubmit={this.props.handleSubmit(this.onSubmit)}
                                            className="form"
                                        >
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <label>First Name:</label>
                                                    <Field name="first_name" type="text" id="first_name" validate={[required, whiteSpace]} placeholder={`Name`} component={renderInput} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Last Name:</label>
                                                    <Field name="last_name" type="text" id="last_name" validate={[required, whiteSpace]} placeholder={`Name`} component={renderInput} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Email:</label>
                                                    <Field name="email" type="email" id="email" placeholder={`Email`} component={renderInput} disabled={true} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Mobile Phone Number:</label>
                                                    <Field name="contact_number" type="text" id="contact_number" validate={[number, phoneNumberValidation, required]} placeholder={`Mobile Number`} component={renderInput}
                                                    //onChange={this.phoneValidate} 
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Password:</label>
                                                    <Field name="password" type="password" id="password" validate={passwordValidation} placeholder={`Password`} component={renderPassword} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Confirm Password:</label>
                                                    <Field
                                                        name="password_confirmation"
                                                        type="password"
                                                        id="password_confirmation"
                                                        validate={passwordValidation}
                                                        placeholder={`Confirm Password`}
                                                        component={renderPassword}
                                                        onChange={this.isPasswordMatch}
                                                    />
                                                </div>
                                                <div className="col-md-4 pt-2">
                                                    <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span>Update</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Foot />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: state.service.endUserProfileResponse ? state.service.endUserProfileResponse.data : "",
        userProfileResponse: state.service ? state.service.updateUserProfileResponse : "",
        isLoading: state.service ? state.service.isLoading : "",
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getUserProfile,
    updateUserProfile,
})(
    reduxForm({
        form: "accountForm",
        touchOnChange: true,
        enableReinitialize: true
    })(Account)
);
