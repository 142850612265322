// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import { PATHS } from "../../constants";
import { setLoader, viewProfile } from "../../actions";
import { Foot, TopBar, LoaderScreen } from "../includes";
import { Link } from "react-scroll";

/*
 TYPES
*/

type Props = {
    history: Object,
    setLoader: Function
};

class ViewProfile extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.setLoader(true);
        this.props.viewProfile(this.props.match.params.id).then(result => {
            this.props.setLoader(false);
        })
            .catch((err) => {
                this.props.setLoader(false);
                console.log(err);
            });
    }

    render() {
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.HOME)
        }

        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        const {
            title,
            avatar_url
        } = this.props.response;
        
        return (
            <div className="form-container small-form-page pb-0">
                {/* <TopBar /> */}
                <div className="inner-page-h container pt-form">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="form-header mb-4">
                                    <div className="tc-heading">
                                        <Link to={PATHS.MANAGE_PROFILE} className="back-link">
                                            <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                        </Link>
                                        <h2 className="mb-3 mb-md-2">View Profile</h2>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="trans-card p-4 mb-4">
                                    <div className="tc-heading">
                                        <h2>{title}</h2>
                                    </div>

                                    <div className="profile-image img-center" >
                                        {avatar_url !== null ? <img src={avatar_url} className="rounded-circle img-center" width='100' height='100' alt={title}/> :
                                            <img src={'/assets/img/adult-3.png'} className="rounded-circle img-center" width='100' height='100' alt="user" />}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Foot />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        response: state.service.viewProfileResponse ? state.service.viewProfileResponse.data : "",
        isLoading: state.service ? state.service.isLoading : ""
    };
};

export default connect(mapStateToProps, {
    setLoader,
    viewProfile
})(ViewProfile);