// @flow

import React, { Component } from "react";
import { Nav, Footer, Foot, LoaderScreen } from "../includes";
import { setLoader, getFaqContent } from "../../actions";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from "react-router-dom";
import  Helmet  from "react-helmet";

/*
 TYPES
*/

type Props = {};

class Faq extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            isInner: ''
        }
    }

    componentDidMount() {
        this.props.setLoader(true);
        scroll.scrollToTop();
        this.props.getFaqContent().then(result => {
            if (this.props.loadData && this.props.loadData.success) {
                this.props.setLoader(false);
            } else {
                this.props.setLoader(false);
            }
        })

        const query1 = new URLSearchParams(this.props.location.search);
        const isInner = query1.get('isInner');
        this.setState({
            isInner: isInner
        })
    }
    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        return (
            <div className="black-body">
                <Helmet>
                    <title>Frequently Asked Questions | View Africa International</title>
                    <meta name="description" content="Visit our FAQ section for all your queries, and get reply to your all queries from our experts. Join our Free Plan Today." />
                </Helmet>

                {this.state.isInner == 1 ? '' : <Nav />}

                {this.state.isInner == 1 ? '' : <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                    <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>}

                {this.state.isInner == 1 ? '' : <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading sb-pt">
                            <h2>Frequent Asked Question</h2>
                        </div>
                    </div>
                </div>}

                <div className="page-min-h homepage mt-5 pt-4 btm-shadow">

                    <div className="container verticalcenter">
                        <div className="thn_post_wrap">
                            {this.props.response ? ReactHtmlParser(this.props.response.content) : ''}
                        </div>
                    </div>
                </div>
                {this.state.isInner == 1 ? '' : (!localStorage.getItem("access_token") ? <Footer /> : <Foot />)}
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        response: state.service.getFaqResponse ? state.service.getFaqResponse.data : '',
        loadData: state.service.getPolicyResponse ? state.service.getPolicyResponse : '',
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getFaqContent
})(Faq);