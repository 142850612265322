export const SET_LOADER = "SET_LOADER";
export const LOGIN_FORM = "LOGIN_FORM";
export const CONTACT_FORM = "CONTACT_FORM";
export const REGISTER_FORM = "REGISTER_FORM";
export const FORGET_PASSWORD_FORM = "FORGET_PASSWORD_FORM";
export const PROFILE_FORM = "PROFILE_FORM";
export const CHANGE_PASSWORD_FORM = "CHANGE_PASSWORD_FORM";
export const CATCH_ERROR = "CATCH_ERROR";
export const DATA_UPDATE = "DATA_UPDATE";
export const RESET_PASSWORD_FORM = "RESET_PASSWORD_FORM";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const REGISTER_WITH_MOBILE = "REGISTER_WITH_MOBILE";
export const VERIFY_MOBILE = "VERIFY_MOBILE";
export const ACTIVATION_CODE = "ACTIVATION_CODE";
export const USER_REGISTER = "USER_REGISTER";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const RESEND_CODE = "RESEND_CODE";
export const GET_STUDENT_PROFILE = "GET_STUDENT_PROFILE";
export const FORGOT_PSWD = "FORGOT_PSWD";
export const RESET_PSWD = "RESET_PSWD";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTION_DETAIL = "GET_QUESTION_DETAIL";
export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const CHECK_USERNAME = "CHECK_USERNAME";
export const CHECK_EMAIL = "CHECK_EMAIL";

export const QUESTIONS_LIST = "QUESTIONS_LIST";
export const QUESTION_ADD = "QUESTION_ADD";
export const QUESTION_DELETE = "QUESTION_DELETE";
export const QUESTION_DETAIL = "QUESTION_DETAIL";
export const QUESTION_UPDATE = "QUESTION_UPDATE";

export const USERS_LIST = "USERS_LIST";
export const USERS_DETAIL = "USERS_DETAIL";
export const USERS_CHANGE_SATUS = "USERS_CHANGE_SATUS";
export const RESET_APP = "RESET_APP";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const MAKE_PUBLIC = "MAKE_PUBLIC";
export const CONTACT_US = "CONTACT_US";

export const LEVEL_DATA = "LEVEL_DATA";
export const LEVEL_QUES_LIST = "LEVEL_QUES_LIST";
export const ADD_SOLVE_WIN_QUES = "ADD_SOLVE_WIN_QUES";
export const SOLVE_WIN_QUESTION_DETAIL = "SOLVE_WIN_QUESTION_DETAIL";
export const QUESTION_STATUS = "QUESTION_STATUS";
export const WINNER_IMAGE = "WINNER_IMAGE";
export const SET_WINNER = "SET_WINNER";
export const GET_SOLVE_WIN_QUESTIONS = "GET_SOLVE_WIN_QUESTIONS";
export const WINNERS = "WINNERS";
export const GET_LEVEL_QUESTIONS = "GET_LEVEL_QUESTIONS";
export const DELETE_WINNER = "DELETE_WINNER";


//cms pages
export const FAQ = "FAQ";
export const TERMS_OF_USE = "TERMS_OF_USE";
export const PRIVACY_POLICY = "PRIVACY_POLICY";
export const HELP_CENTRE = "HELP_CENTRE";
export const PLAN_LIST = "PLAN_LIST";
export const ADD_PROFILE = "ADD_PROFILE";
export const VIEW_PROFILE = 'VIEW_PROFILE';
export const MANAGE_PROFILE = 'MANAGE_PROFILE';
export const DELETE_PROFILE = "DELETE_PROFILE";
export const VIDEO_LIST = "VIDEO_LIST";
export const VIDEO_SEARCH = "VIDEO_SEARCH";
export const VIDEO_NOTIFICATION = "VIDEO_NOTIFICATION";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const VERIFY_EMAIL_REGISTER = "VERIFY_EMAIL_REGISTER";
export const USER_WISHLIST = "USER_WISHLIST";
export const SEARCH_HISTORY = "SEARCH_HISTORY";
export const VIDEO_HISTORY = 'VIDEO_HISTORY';
export const ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST';
export const UNREAD_NOTIFICATION = 'UNREAD_NOTIFICATION';
export const VIDEO_CATEGORY = 'VIDEO_CATEGORY';
export const ABOUT_US = 'ABOUT_US';
export const CORPORATE_INFO = 'CORPORATE_INFO';
export const LEGAL_NOTICE = 'LEGAL_NOTICE';
export const REMOVE_WATCHLIST = "REMOVE_WATCHLIST";
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const VIDEO_INFO = 'VIDEO_INFO';
export const HEADER_MENU = 'HEADER_MENU';
export const REMOVE_WATCHLIST_VIDEO = 'REMOVE_WATCHLIST_VIDEO';
export const ADD_TO_VIDEO_HISTORY = 'ADD_TO_VIDEO_HISTORY';
export const REMOVE_VIDEO_FROM_HISTORY = 'REMOVE_VIDEO_FROM_HISTORY';
export const ADD_USER_PLAN = "ADD_USER_PLAN";
export const PLAN_DETAIL = "PLAN_DETAIL";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const GET_USER_PAYMENT = "GET_USER_PAYMENT";
export const PAYMENT_HISTORY = "PAYMENT_HISTORY";
export const END_MEMBERSHIP = "END_MEMBERSHIP";
export const PAYMENT_DETAIL = "PAYMENT_DETAIL";
export const CONFIRM_USER_ACCOUNT = "CONFIRM_USER_ACCOUNT";
export const UPGRADE_PLAN = 'UPGRADE_PLAN';
export const LOGOUT = 'LOGOUT';