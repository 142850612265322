// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

import { PATHS, PAYPAL_PAYMENT } from "../../constants";
import { connect } from "react-redux";
import {
    setLoader,
    planDetail,
    getPlanList,
    addUserPlan,
    upgardePlan,
    getUserPayment,
    getUserProfile
} from "../../actions";
import { LoaderScreen, Nav, Foot } from "../includes";
import "react-activity/dist/react-activity.css";
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { required } from "../../constants/validation";
import { SelectField } from "../../constants/redux-form";
import { PayPalButton } from "react-paypal-button-v2";
import * as ALL from "@material-ui/core";
import { Dots } from "react-activity";
import "react-activity/dist/react-activity.css";

import * as Styled from "../page.styled";
/*
TYPES
*/
type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class RenewMembership extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            hasMore: true,
            selectedPlanType: '',
            planChecked: '',
            err: '',
            alertMessage: "",
            currentStep: 1,
            plan_amount: '',
            open: false,
            cancelPaymentResponse: ''
        };
        this.onRadioChange = this.onRadioChange.bind(this);
    }


    componentDidMount() {
        this.props.setLoader(true);
        this.props.reset('selectPlanForm');
        let isLogin = true;
        this.props.getPlanList(isLogin);
        this.props.getUserProfile();
        this.props.getUserPayment().then(result => {
            if (this.props.userPayment && this.props.userPayment.success) {
                this.props.setLoader(false);
            }
        });

        if (localStorage.getItem('vai_update_plan_stage') == 2) {
            this.props.setLoader(true);
            this.setState({
                currentStep: 2
            })

            this.props.getUserProfile().then(result => {
                if (this.props.userProfileResponse) {
                    let email = this.props.userProfileResponse.email;
                    this.props.planDetail(email).then(result => {
                        if (this.props.planDetailResponse && this.props.planDetailResponse.success) {
                            this.props.setLoader(false);
                        } else {
                            this.props.setLoader(false);
                        }
                    });
                } else {
                    let email = localStorage.getItem('username') == '' ? localStorage.getItem('vai_user_email') : localStorage.getItem('username');
                    this.props.planDetail(email).then(result => {
                        if (this.props.planDetailResponse && this.props.planDetailResponse.success) {
                            this.props.setLoader(false);
                        } else {
                            this.props.setLoader(false);
                        }
                    });
                }
            })
        }
    }

    cancelPayment = (data) => {
        this.setState({ alertMessage: 'PayPal payment cancelled', alertClass: "alert alert-success", open: true, cancelPaymentResponse: data.orderID });
    }

    handleChange = (event) => {
        this.setState({ plan_amount: event.target.value })
    };

    againPaymentHandle = () => {
        this.setState({
            open: false
        })
    }

    updatePlan = () => {
        this.props.reset('selectPlanForm');
        this.setState({
            currentStep: 1
        })
    }

    createNewPlan = () => {
        let err = '';
        if (!this.state.plan_amount) {
            err = 'Please select plan';
            this.setState({
                err: err,
                alertClass: "alert alert-danger"
            })
            return false
        }

        if (this.props.userPayment) {
            let plan_id = this.state.plan_amount;
            let subscription_id = this.props.userPayment.subscription_id;
            this.props.setLoader(true);
            this.props.upgardePlan(plan_id, subscription_id).then(result => {
                if (this.props.upgradePlanResponse.success) {
                    this.props.setLoader(false);
                    this.setState({
                        currentStep: 3
                    })
                    localStorage.setItem('vai_update_plan_stage', 3);
                }
            })
        }

    }

    onRadioChange = (e) => {
        this.setState({
            planChecked: e.target.value,
            selectedPlanType: e.target.getAttribute('plan-type')
        });
    }

    onSubmit = () => {
        let err = '';
        if (this.state.planChecked.length === 0) {
            err = 'Please select plan';
            this.setState({
                err: err,
                alertClass: "alert alert-danger"
            })
            return false
        }


        let plan_type = this.state.selectedPlanType;

        let email = this.props.userProfileResponse ? this.props.userProfileResponse.email : localStorage.getItem('username');
        this.props.setLoader(true);
        this.props.addUserPlan(plan_type, email).then(result => {

            if (this.props.addUserPlanResponse && this.props.addUserPlanResponse.success) {

                localStorage.setItem('vai_update_plan_stage', 2);
                let email = this.props.userProfileResponse ? this.props.userProfileResponse.email : localStorage.getItem('username');
                this.props.planDetail(email).then(result => {

                    if (this.props.planDetailResponse && this.props.planDetailResponse.success) {

                        this.setState({
                            currentStep: 2,
                            err: ''
                        })
                        this.props.setLoader(false);

                        this.setState({ alertMessage: this.props.addUserPlanResponse.message, alertClass: "alert alert-success" });
                    } else {

                        this.props.setLoader(false);
                        this.setState({ alertMessage: 'Something went wrong', alertClass: "alert alert-danger" });
                    }
                });

            } else {
                this.props.setLoader(false);
                this.setState({ alertMessage: this.props.addUserPlanResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                console.log(err);
            });

    };

    goSubscription = () => {
        localStorage.removeItem('vai_update_plan_stage');
        this.props.history.push(PATHS.SUBSCRIPTION);
    }

    render() {
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN);
        }

        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        let self = this
        //let today = moment(new Date()).format('gggg-MM-DDTHH:mm:ss') + 'Z';
        let currentDate = moment(new Date());
        let addHour = moment(currentDate).add(2, 'hours');

        let today = moment(addHour).format('gggg-MM-DDTHH:mm:ss') + 'Z';
        return (
            <div className="black-body">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                    <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading">
                            <h2>Purchase Plan</h2>
                        </div>
                    </div>

                </div>

                <div className="page-min-h homepage small-form-page inner-pt">

                    {this.state.err ? (
                        <div className={this.state.alertClass} role="alert">
                            {this.state.err}
                        </div>
                    ) : null}
                    {this.state.currentStep === 1 ? (
                        <form
                            name="selectPlanForm"
                            //className="form"
                            onSubmit={this.props.handleSubmit(this.onSubmit)}
                        >
                            <section className='plan-section update-plan-section'>
                                {/* <div class="container-fluid"> */}
                                <div className="container">
                                    <div className="row">
                                        {this.props.planResponse ? this.props.planResponse.map((p, index) => (
                                            <div className="col-lg-3 col-md-6 col-sm-6 plan-sec" key={index}>
                                                <label htmlFor={p.id}
                                                    className={`${index == this.state.activeIndex ? "plan-sec-h plan-sec-w planActive" : "plan-sec-h plan-sec-w planInactive"}`}
                                                >
                                                    <Field
                                                        name="planChecked"
                                                        onChange={this.onRadioChange}
                                                        onClick={e => this.setState({ activeIndex: index })}
                                                        component='input'
                                                        type="radio"
                                                        value={p.title}
                                                        id={p.id}
                                                        plan-type={p.plan_type_slug}
                                                    />
                                                    <div className="card text-center">
                                                        <div className="title">

                                                            <i className={p.title == 'Free Plan' ? "fa fas fa-dove" : p.title == 'Mobile Only' ? 'fa fa-rocket' : p.title == 'Standard' ? 'fa fa-plane rotate-plane' : p.title == 'Basic' ? 'fa fa-paper-plane' : ''} aria-hidden="true"></i>
                                                            <h2>{p.title}</h2>
                                                        </div>
                                                        <div className="price">
                                                            {p.plan_price ? p.plan_price.map((data, index) => (
                                                                <h4 key={index}><sup>{p.currency}{data.price}</sup>/{data.duration}</h4>
                                                            )) : ''}
                                                        </div>
                                                        <div>{ReactHtmlParser(p.description)}</div>
                                                        <div className="option">
                                                            <ul>
                                                                {p.feature ? p.feature.map((feature, index) => (
                                                                    <li key={index}> <i className="fa fa-check" aria-hidden="true"></i> {feature.title} - {feature.value}</li>
                                                                )) : ''}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        )) : <Styled.Innerloader>
                                                <h6 className='mb-3'>Please wait... Loading</h6>
                                                <Dots color="#e30813" size={36} speed={1} animating={true} />
                                            </Styled.Innerloader>}
                                        {this.props.planResponse ?
                                            <div className="col-md-12">
                                                <div className="row justify-content-center">
                                                    <div className="col-md-4">
                                                        <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span> Renew Membership</button>
                                                    </div>
                                                </div>

                                            </div>
                                            : ''}
                                    </div>
                                </div>
                                {/* </div> */}
                            </section>
                        </form>
                    ) : this.state.currentStep === 2 ? (
                        <div className="inner-page-h container pt-2">

                            {this.props.userDetail ? localStorage.setItem('vai_user_id', this.props.userDetail.id) : ''}
                            <div className="form-body">
                                <div className="container">
                                    <div className="tc-heading mb-5">
                                        <h2>Your membership starts as soon as you set up payment.</h2>
                                    </div>
                                    {this.props.planDetailResponse && this.props.planDetailResponse.data ?
                                        <div className="row">
                                            <div className="col-md-6">
                                                <section className='plan-section p-0'>
                                                    <div className="container">
                                                        <div className="row">

                                                            <div className="col-md-12 plan-sec plan-sec-h plan-sec-w">

                                                                <div className="card text-center mb-4">
                                                                    <div className="title">
                                                                        <h2>{this.props.planDetailResponse.data.title}</h2>
                                                                    </div>
                                                                    <div className="price">
                                                                        {this.props.planDetailResponse ? this.props.planDetailResponse.data ? this.props.planDetailResponse.data.plan_price ? this.props.planDetailResponse.data.plan_price.map((feature, index) => (
                                                                            <h4 key={index}><sup>{this.props.planDetailResponse.data.currency}</sup>{feature.price} /{feature.duration}</h4>
                                                                        )) : '' : '' : ''}

                                                                    </div>
                                                                    <div>{ReactHtmlParser(this.props.planDetailResponse.data.description)}</div>
                                                                    <div className="option">
                                                                        <ul>

                                                                            {this.props.planDetailResponse ? this.props.planDetailResponse.data ? this.props.planDetailResponse.data.feature ? this.props.planDetailResponse.data.feature.map((feature, index) => (
                                                                                <li key={index}> <i className="fa fa-check" aria-hidden="true"></i> {feature.title} - {feature.value}</li>
                                                                            )) : '' : '' : ''}
                                                                        </ul>
                                                                    </div>
                                                                    <Link to="#" onClick={this.updatePlan}>Change</Link>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="col-md-6">
                                                <section className='subscription-section p-0'>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-12">

                                                                <div className="card text-center">

                                                                    <Field
                                                                        name="plan_amount"
                                                                        validate={[required]}
                                                                        onChange={this.handleChange}
                                                                        component={SelectField}
                                                                        value={this.state.plan_amount}
                                                                    >
                                                                        <option value="">Please Select</option>
                                                                        {this.props.planDetailResponse.data ? this.props.planDetailResponse.data.plan_price ? this.props.planDetailResponse.data.plan_price.map((data, index) => (<option value={data.plan_id} key={index}>{data.title}  ${data.price} {data.currency} - {data.duration}</option>)) : '' : ''}
                                                                    </Field>

                                                                    {this.props.userPlanDetail && this.props.userPayment && this.props.userPayment.length != 0 && this.props.userPlanDetail.btnShow == false ?
                                                                        <Link onClick={this.createNewPlan} className="btn btn-block btn-red mb-2">Upgrade Plan</Link> : <PayPalButton
                                                                            options={{
                                                                                vault: true,
                                                                                clientId: PAYPAL_PAYMENT.CLIENT_ID
                                                                            }}

                                                                            style={{
                                                                                label: 'subscribe',
                                                                            }}
                                                                            createSubscription={(data, actions) => {
                                                                                if (this.state.plan_amount) {
                                                                                    return actions.subscription.create({
                                                                                        plan_id: this.state.plan_amount,
                                                                                        start_time: this.props.userPlanDetail && this.props.userPlanDetail.success == false && !this.props.userPayment ? today :
                                                                                            this.props.userPayment.next_billing_time == '' ? today :
                                                                                                moment(this.props.userPayment.next_billing_time).format('gggg-MM-DDTHH:mm:ss') + 'Z'
                                                                                    });
                                                                                } else {
                                                                                    actions.disable();
                                                                                }
                                                                            }}

                                                                            onError={(err) => { console.error('PayPal error', err) }}


                                                                            onCancel={(data) => this.cancelPayment(data)}

                                                                            onApprove={(data, actions) => {
                                                                                // Capture the funds from the transaction
                                                                                return actions.subscription.get().then(function (details) {
                                                                                    self.props.setLoader(true);
                                                                                    // alert("Subscription completed");

                                                                                    fetch(PATHS.API_PATH + 'user/save-user-payment', {
                                                                                        method: 'post',
                                                                                        headers: {
                                                                                            'content-type': 'application/json'
                                                                                        },
                                                                                        body: JSON.stringify({
                                                                                            user_id: localStorage.getItem('vai_user_id'),
                                                                                            subscription_id: data.subscriptionID
                                                                                        })


                                                                                    })
                                                                                        // .then(function(jsonData){
                                                                                        //     return JSON.stringify(jsonData);
                                                                                        //     }
                                                                                        //     ).then(function(jsonStr){
                                                                                        //         self.setState({currentStep: 3});
                                                                                        //     console.log(jsonStr);
                                                                                        //     });
                                                                                        .then((response) => {

                                                                                            if (response.ok) {
                                                                                                self.props.setLoader(false);
                                                                                                self.setState({
                                                                                                    currentStep: 3
                                                                                                })
                                                                                                localStorage.setItem('vai_update_plan_stage', 3);
                                                                                                return response.json();
                                                                                            }
                                                                                            // else {
                                                                                            //     throw new Error('Server response wasn\'t OK');
                                                                                            // }
                                                                                        })


                                                                                }).catch(function (err) {
                                                                                    console.log(err)
                                                                                    // redirect to error page
                                                                                });
                                                                            }}
                                                                        />}

                                                                    <ALL.Dialog
                                                                        open={this.state.open}
                                                                        aria-labelledby="responsive-dialog-title"
                                                                    >
                                                                        <ALL.DialogTitle id="responsive-dialog-title">Payment is cancelled of Order Id:  {this.state.cancelPaymentResponse}</ALL.DialogTitle>
                                                                        <ALL.DialogContent>
                                                                            <ALL.DialogContentText>

                                                                                Please try again to get subscription of VAI plans. And enjoy movies, webseries etc.

                                                                            </ALL.DialogContentText>
                                                                        </ALL.DialogContent>
                                                                        <ALL.DialogActions>
                                                                            <ALL.Button
                                                                                onClick={this.againPaymentHandle}
                                                                                color="primary">
                                                                                Try Again
                                                                            </ALL.Button>

                                                                        </ALL.DialogActions>
                                                                    </ALL.Dialog>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </section>

                                            </div>
                                        </div>
                                        :

                                        <Styled.Innerloader>
                                            <h6 className='mb-3'>Please wait... Loading</h6>
                                            <Dots color="#e30813" size={36} speed={1} animating={true} />
                                        </Styled.Innerloader>
                                    }



                                </div>
                            </div>
                        </div>
                    ) : <div className="container">
                                <div className="form-body">
                                    <div className="form-content">
                                        <div className="mb-4 signup-success">
                                            <img src="assets/img/signup-success.png" className="img-fluid" alt="thank you" />
                                            <h4>Verify your email address</h4>
                                            <p>We have sent a verification link to your registered email id. Please check your inbox to confirm the account.</p>
                                            <p>If you have not received the verification email, please check your 'Spam' folder. If you not received any mail then please<Link to={PATHS.CONTACT}> contact us</Link>.</p>
                                            <Link onClick={this.goSubscription} className="btn btn-block btn-red mb-2">Go to Subscription</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>}
                </div>
                <Foot />
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        isLoading: state.service ? state.service.isLoading : "",
        planResponse: state.service.planResponse ? state.service.planResponse.data : "",
        planDetailResponse: state.service.planDetailResponse ? state.service.planDetailResponse : '',
        upgradePlanResponse: state.service.upgradePlanResponse,
        addUserPlanResponse: state.service.addUserPlanResponse,
        userDetail: state.service.planDetailResponse ? state.service.planDetailResponse.user_data : '',
        userPayment: (state.service.userPaymentResponse) ? state.service.userPaymentResponse.payment : "",
        userPlanDetail: (state.service.userPaymentResponse) ? state.service.userPaymentResponse : "",
        userProfileResponse: state.service.endUserProfileResponse ? state.service.endUserProfileResponse.data : ""
    };
};

export default connect(mapStateToProps, {
    getPlanList,
    setLoader,
    planDetail,
    upgardePlan,
    addUserPlan,
    getUserPayment,
    getUserProfile
})(reduxForm({ form: "registerForm" })(RenewMembership));