// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderEmail } from "../../constants/redux-form";
import { PATHS } from "../../constants";
import { setLoader, forgetPasswordForm, forgotPassword, reset } from "../../actions";
import { required, email } from "../../constants/validation";
import { LoaderScreen } from "../includes";
import { Bottom } from "../includes";

/*
 TYPES
*/

type Props = {
  handleSubmit: Function,
  setLoader: Function,
  history: Object
};

class ForgetPassword extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      alertClass: "",
      email: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.reset('forgetPasswordForm');
  }

  onSubmit = formValue => {
    this.props.setLoader(true);
    const email = formValue.email;
    this.setState({
      email: email
    });
    localStorage.setItem('email', email)
    let successMessage = "";
    let errorMessage = "";

    setTimeout(() => {
      this.props.forgotPassword({ email }).then(result => {
        //console.log('forgot password',this.props.forgotPswdResponse)
        this.props.setLoader(true);
        if (this.props.forgotPswdResponse.success) {
          this.props.setLoader(false);
          successMessage = this.props.forgotPswdResponse.message;
          this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
          this.props.reset('forgetpassword');
          setTimeout(() => {
            this.props.history.push(PATHS.LOGIN);
          }, 2000);
          
        } else {
          this.props.setLoader(false);
          errorMessage = this.props.forgotPswdResponse.message;
          this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
        }

        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);

      });
    }, 1000);
  };


  handleChange(e) {
  }

  render() {
    if (localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.DASHBOARD);
    }

    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (

      <div className="form-container small-form-page pb-0">
        <div className="inner-page-h container pt-form">
        <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                        <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
          <div className="form-body">
            <div className="form-content">
              <div className="form-header mb-4">
                <Link to={PATHS.HOME} variant="body2">
                  <img src="assets/img/logo.svg" className="inner-logo img-fluid" alt="Logo Images" />
                </Link>
              </div>
              <div className="tc-heading">
                  <h2>Forgot Password</h2>
                </div>

              <div className="trans-card p-4 mb-4">
                
                {this.state.alertMessage ? (
                  <div className={this.state.alertClass} role="alert">
                    {this.state.alertMessage}
                  </div>
                ) : null}


                <form
                  name="forgetPasswordForm"
                  className="form"
                  onSubmit={this.props.handleSubmit(this.onSubmit)}
                >
                  <Field name="email" type="email" validate={[required, email]} placeholder={`Email`} component={renderEmail} />

                  <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span> Submit</button>
                </form>
              </div>
              <p className="footer-text">Do have an Account! <Link to={PATHS.LOGIN} variant="body2">Sign in Here</Link></p>
            </div>
          </div>

        </div>
        <Bottom />

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    forgotPswdResponse: state.service.forgotPswdResponse,
    isLoading: state.service ? state.service.isLoading : ""
  };
};

export default connect(mapStateToProps, {
  setLoader,
  forgetPasswordForm,
  forgotPassword,
  reset
})(reduxForm({ form: "forgetPasswordForm" })(ForgetPassword));
