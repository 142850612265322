import React, { Component } from "react";

/*
 TYPES
*/

type Props = {};
class Bottom extends Component<Props> {
    render() {
        return (
            <div className="copyright">
                <div className="container-fluid">
                    <div className="cpr-section justify-content-center">
                        <p className="m-0">© Copyright :  2020 - All Rights Reserved</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default Bottom;
