// @flow

import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderInput, renderFile } from "../../constants/redux-form";
import { required } from "../../constants/validation";
import { PATHS } from "../../constants";
// import * as ALL from "@material-ui/core";
import { setLoader, updateProfile, reset, viewProfile } from "../../actions";
import { LoaderScreen, Foot } from "../includes";

/*
 TYPES
*/

type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class EditDefaultProfile extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            alertMessage: "",
            alertClass: "",
            files: [],
            avatarurl: '',
            is_adult: '',
            formData: [],
            imagePreviewUrl: '',
            file: '',
            errorMsg: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.setLoader(true);
        this.props.viewProfile(this.props.match.params.id).then(result => {
            if (this.props.viewProfileResponse.success) {
                this.props.setLoader(false);
            }
        })
    }

    onSubmit = formValue => {
        let errorMsg = "";
        // console.log(this.props.viewProfileResponse.data.avatar_url)
        if (this.props.viewProfileResponse.data && !this.props.viewProfileResponse.data.avatar_url && !this.state.avatarurl) {
            errorMsg = "Please select Image";
            this.setState({
                errorMsg: errorMsg,
                alertClass: "alert alert-danger"
            });
            return false
        } else {
            this.setState({
                errorMsg: "",
                alertClass: ""
            });
        }

        // return false;
        this.props.setLoader(true);
        let input = new FormData();
        input.append('avatar', this.state.avatarurl);
        input.append('title', formValue.title);
        input.append('is_adult', this.state.is_adult);
        input.append('profile_id', this.props.match.params.id)
        setTimeout(() => {
            this.props.updateProfile(input).then(result => {
                this.props.setLoader(true);
                if (this.props.response.success) {
                    this.props.setLoader(false);
                    this.props.history.push(PATHS.MANAGE_PROFILE);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });

                } else {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-danger" });
                }

                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
        }, 500);
    };


    handleChange(e) {
        if (e.target.checked === true) {
            this.setState({
                is_adult: 0
            })
        } else {
            this.setState({
                is_adult: 1
            })
        }
    }

    fileChangedHandler = (event) => {
        event.preventDefault();

        this.setState({
            avatarurl: event.target.files[0]
        })

        let reader = new FileReader();

        let file = event.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file);
    }

    render() {

        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.HOME)
        }

        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        let { imagePreviewUrl } = this.state;
        let imagePreview = null;
        if (imagePreviewUrl) {
            imagePreview = (<img src={imagePreviewUrl} className="rounded-circle img-center" width='100' height='100' alt='user' />);
        } else {
            imagePreview = (<img src={this.props.initialValues ? this.props.initialValues.avatar_url ? PATHS.BASEPATH + `/${this.props.initialValues.avatar_url}` : '/assets/img/adult-3.png' : ''} className="rounded-circle img-center" width='100' height='100' alt="user" />);
        }

        return (
            <div className="form-container small-form-page pb-0">
                {/* <Nav /> */}
                <div className="inner-page-h container pt-form">
                    <div className="form-body">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="form-header mb-4">
                                        <div className="tc-heading">
                                            <h2 className="mb-3 mb-md-2">Edit Profile</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-10 col-md-8 col-lg-5">

                                    <div className="trans-card p-4 mb-4">

                                        {this.state.alertMessage ? (
                                            <div className={this.state.alertClass} role="alert">
                                                {this.state.alertMessage}
                                            </div>
                                        ) : null}
                                        {this.state.errorMsg ? (
                                            <div className={this.state.alertClass} role="alert">
                                                {this.state.errorMsg}
                                            </div>
                                        ) : null}

                                        <form
                                            name="editDefaultProfileForm"
                                            onSubmit={this.props.handleSubmit(this.onSubmit)}
                                            className="form"
                                        >
                                            <div className="profile-block mb-2">

                                                <div className="profile-image img-center mb-3" >
                                                    {/* {this.state.files.length ? this.state.files.map((file, i) => {
                                                        return <img key={i} src={file.base64} className="rounded-circle img-center" width='100' height='100' alt="user" />
                                                    }) : <img src={this.props.initialValues ? PATHS.BASEPATH + `/${this.props.initialValues.avatar_url}` : ''} className="rounded-circle img-center" width='100' height='100' alt="user" />} */}

                                                    {imagePreview}
                                                </div>

                                                <div className="up-file file btn btn-sm btn-primary mb-3">
                                                    <i className="fa fa-camera mr-1"></i> Add Photo
                                                    <Field
                                                        type="file"
                                                        onChange={this.fileChangedHandler}
                                                        id="avatar"
                                                        name="avatar"
                                                        component={renderFile}
                                                    />
                                                </div>

                                                <Field name="title" type="text" id="title" validate={[required]} placeholder={`Enter Name`} component={renderInput} />
                                            </div>
                                            {/* <div className="profile-container">
                                                <div className="kid-profile">
                                                    <span> Kids profile?</span>
                                                    <p className="footer-text">TV shows and movies for ages 12 and under
                                                    </p>
                                                </div>
                                                <div className="kids-switch">
                                                    <ALL.Switch
                                                        // checked={this.state.checkedA}
                                                        defaultChecked={this.props.initialValues ? this.props.initialValues.is_adult === 1 ? false : true : ''}
                                                        onChange={this.handleChange}
                                                        name="is_adult"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                    {/* <Field name="is_adult" defaultChecked={this.state.is_adult}  onChange={this.handleChange}  checked={this.props.initialValues.is_adult == 0 ? true : false} component={renderSwitch} label="Employed" /> */}
                                            {/* </div>
                                            </div> */}
                                            <div className="profile-btns">
                                                <a href={PATHS.MANAGE_PROFILE} className="contact-btn dark-btn m-0">
                                                    Cancel
                                        </a>
                                                <button type="submit" className="contact-btn red-btn m-0">Save</button>
                                            </div>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Foot />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        response: state.service ? state.service.updateProfileResponse : "",
        isLoading: state.service ? state.service.isLoading : "",
        initialValues: state.service.viewProfileResponse ? state.service.viewProfileResponse.data : '',
        viewProfileResponse: state.service.viewProfileResponse ? state.service.viewProfileResponse : '',
        enableReinitialize: true
    };
};

export default connect(mapStateToProps, {
    setLoader,
    updateProfile,
    reset,
    viewProfile,
})(reduxForm({ form: "editDefaultProfileForm", touchOnChange: true })(EditDefaultProfile));