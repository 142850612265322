import {
  SET_LOADER,
  CATCH_ERROR,
  DATA_UPDATE,
  UPDATE_PROFILE,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER,
  USER_LOGIN_FAIL,
  FORGOT_PSWD,
  RESET_PSWD,
  RESET_APP,
  CONTACT_US,



  //cms
  FAQ,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  HELP_CENTRE,
  PLAN_LIST,
  ADD_PROFILE,
  VIEW_PROFILE,
  MANAGE_PROFILE,
  DELETE_PROFILE,
  VIDEO_LIST,
  VIDEO_SEARCH,
  VIDEO_NOTIFICATION,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  VERIFY_EMAIL_REGISTER,
  USER_WISHLIST,
  SEARCH_HISTORY,
  VIDEO_HISTORY,
  ADD_TO_WATCHLIST,
  UNREAD_NOTIFICATION,
  VIDEO_CATEGORY,
  ABOUT_US,
  CORPORATE_INFO,
  LEGAL_NOTICE,
  REMOVE_WATCHLIST,
  CREATE_PASSWORD,
  VIDEO_INFO,
  HEADER_MENU,
  REMOVE_WATCHLIST_VIDEO,
  ADD_TO_VIDEO_HISTORY,
  REMOVE_VIDEO_FROM_HISTORY,
  ADD_USER_PLAN,
  PLAN_DETAIL,
  UPDATE_PLAN,
  GET_USER_PAYMENT,
  PAYMENT_HISTORY,
  END_MEMBERSHIP,
  PAYMENT_DETAIL,
  CONFIRM_USER_ACCOUNT,
  UPGRADE_PLAN,
  LOGOUT

} from "../actions/types";

const INITIAL_STATE = {
  response: [],
  isLoading: false,
  userRegisterResponse: [],
  forgotPswdResponse: [],
  resetPswdResponse: [],
  error_response: "",
  contactResponse: [],
  planResponse: [],
  getFaqResponse: [],
  getTermsResponse: [],
  getPolicyResponse: [],
  getHelpResponse: [],
  addProfileResponse: [],
  viewProfileResponse: [],
  manageProfileResponse: [],
  updateProfileResponse: [],
  deleteProfileResponse: [],
  videoListResponse: [],
  videoSearchResponse: [],
  videoNotification: [],
  endUserProfileResponse: [],
  updateUserProfileResponse: [],
  verifyEmailResponse: [],
  userWishlistResponse: [],
  searchHistoryResponse: [],
  videoHistoryResponse: [],
  watchlistResponse: [],
  unreadNotification: [],
  videoCategoryResponse: [],
  getAboutUsResponse: [],
  corporateInfoResponse: [],
  legalNoticeResponse: [],
  removeWatchlistResponse: [],
  createPasswordResponse: [],
  videoInfoResponse: [],
  headerMenuResponse: [],
  removeWatchlistVideoResponse: [],
  addVideoHistoryResponse: [],
  removeVideoHistoryResponse: [],
  addUserPlanResponse: [],
  planDetailResponse: [],
  updatePlanResponse: [],
  userPaymentResponse: [],
  paymentHistoryResponse: [],
  endMembershipResponse: [],
  paymentDetailResponse: [],
  confirmAccountResponse: [],
  upgradePlanResponse: [],
  logoutResponse: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_APP:
      return INITIAL_STATE;
    case SET_LOADER:
      return { ...state, isLoading: action.payload };
    case CATCH_ERROR:
      return { ...state, error_response: action.payload, isLoading: false };
    case DATA_UPDATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case USER_REGISTER:
      return { ...state, userRegisterResponse: action.payload, isLoading: false };
    case USER_LOGIN_SUCCESS:
      return { ...state, response: action.payload, token: action.token, isLoading: false };
    case USER_REGISTER_SUCCESS:
      return { ...state, response: action.payload, token: action.token, isLoading: false };
    case USER_LOGIN_FAIL:
      return { ...state, response: action.payload, token: "", isLoading: false };
    case USER_REGISTER_FAIL:
      return { ...state, response: action.payload, token: "", isLoading: false };






    //VAI

    case FAQ:
      return { ...state, getFaqResponse: action.payload, isLoading: false };
    case TERMS_OF_USE:
      return { ...state, getTermsResponse: action.payload, isLoading: false };
    case PRIVACY_POLICY:
      return { ...state, getPolicyResponse: action.payload, isLoading: false };
    case HELP_CENTRE:
      return { ...state, getHelpResponse: action.payload, isLoading: false };
    case ABOUT_US:
      return { ...state, getAboutUsResponse: action.payload, isLoading: false };
    case CORPORATE_INFO:
      return { ...state, corporateInfoResponse: action.payload, isLoading: false };
    case LEGAL_NOTICE:
      return { ...state, legalNoticeResponse: action.payload, isLoading: false };
    case PLAN_LIST:
      return { ...state, planResponse: action.payload, isLoading: false };
    case ADD_PROFILE:
      return { ...state, addProfileResponse: action.payload, isLoading: false };
    case VIEW_PROFILE:
      return { ...state, viewProfileResponse: action.payload, isLoading: false };
    case MANAGE_PROFILE:
      return { ...state, manageProfileResponse: action.payload, isLoading: false };
    case UPDATE_PROFILE:
      return { ...state, updateProfileResponse: action.payload, isLoading: false };
    case DELETE_PROFILE:
      return { ...state, deleteProfileResponse: action.payload, isLoading: false };
    case VIDEO_LIST:
      return { ...state, videoListResponse: action.payload, isLoading: false };
    case VIDEO_SEARCH:
      return { ...state, videoSearchResponse: action.payload, isLoading: false };
    case VIDEO_NOTIFICATION:
      return { ...state, videoNotification: action.payload, isLoading: false };
    case GET_USER_PROFILE:
      return { ...state, endUserProfileResponse: action.payload, isLoading: false };
    case CONTACT_US:
      return { ...state, contactResponse: action.payload, isLoading: false };
    case RESET_PSWD:
      return { ...state, resetPswdResponse: action.payload, isLoading: false };
    case FORGOT_PSWD:
      return { ...state, forgotPswdResponse: action.payload, isLoading: false };
    case UPDATE_USER_PROFILE:
      return { ...state, updateUserProfileResponse: action.payload, isLoading: false };
    case VERIFY_EMAIL_REGISTER:
      return { ...state, verifyEmailResponse: action.payload, isLoading: false };
    case USER_WISHLIST:
      return { ...state, userWishlistResponse: action.payload, isLoading: false };
    case SEARCH_HISTORY:
      return { ...state, searchHistoryResponse: action.payload, isLoading: false };
    case VIDEO_HISTORY:
      return { ...state, videoHistoryResponse: action.payload, isLoading: false };
    case ADD_TO_WATCHLIST:
      return { ...state, watchlistResponse: action.payload, isLoading: false };
    case UNREAD_NOTIFICATION:
      return { ...state, unreadNotification: action.payload, isLoading: false };
    case VIDEO_CATEGORY:
      return { ...state, videoCategoryResponse: action.payload, isLoading: false };
    case REMOVE_WATCHLIST:
      return { ...state, removeWatchlistResponse: action.payload, isLoading: false };
    case CREATE_PASSWORD:
      return { ...state, createPasswordResponse: action.payload, isLoading: false };
    case VIDEO_INFO:
      return { ...state, videoInfoResponse: action.payload, isLoading: false };
    case HEADER_MENU:
      return { ...state, headerMenuResponse: action.payload, isLoading: false };
    case REMOVE_WATCHLIST_VIDEO:
      return { ...state, removeWatchlistVideoResponse: action.payload, isLoading: false };
    case ADD_TO_VIDEO_HISTORY:
      return { ...state, addVideoHistoryResponse: action.payload, isLoading: false };
    case REMOVE_VIDEO_FROM_HISTORY:
      return { ...state, removeVideoHistoryResponse: action.payload, isLoading: false };
    case ADD_USER_PLAN:
      return { ...state, addUserPlanResponse: action.payload, isLoading: false };
    case PLAN_DETAIL:
      return { ...state, planDetailResponse: action.payload, isLoading: false };
    case UPDATE_PLAN:
      return { ...state, updatePlanResponse: action.payload, isLoading: false };
    case GET_USER_PAYMENT:
      return { ...state, userPaymentResponse: action.payload, isLoading: false };
    case PAYMENT_HISTORY:
      return { ...state, paymentHistoryResponse: action.payload, isLoading: false };
    case END_MEMBERSHIP:
      return { ...state, endMembershipResponse: action.payload, isLoading: false };
    case PAYMENT_DETAIL:
      return { ...state, paymentDetailResponse: action.payload, isLoading: false };
    case CONFIRM_USER_ACCOUNT:
      return { ...state, confirmAccountResponse: action.payload, isLoading: false };
    case UPGRADE_PLAN:
      return { ...state, upgradePlanResponse: action.payload, isLoading: false };
    case LOGOUT:
      return { ...state, logoutResponse: action.payload, isLoading: false }
    default:
      return state;
  }
};
