// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { connect } from "react-redux";
import {
    setLoader,
    getVideoNotification
} from "../../actions";
import { LoaderScreen, Nav, Foot } from "../includes";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import moment from 'moment';

/*
TYPES
*/
type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class Notification extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            hasMore: true
        };
    }


    componentDidMount() {
        this.props.setLoader(true);
        let offset = 0;
        let limit = 10;
        this.props.getVideoNotification(offset, limit).then(result => {
            this.props.setLoader(false);
            this.setState({
                items: this.props.videoNotification
            });
        })
            .catch((err) => {
                console.log(err);
            });
    }


    fetchMoreData = () => {
        let limit = 10;
        let offset = this.state.items.length;
        this.props.getVideoNotification(offset, limit).then(result => {
            if (this.props.videoNotificationRecord.totalPage == this.state.items.length) {
                this.setState({ hasMore: false });
                return;
            } else {
                this.setState({
                    items: this.state.items.concat(this.props.videoNotification)
                });
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        if (!localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.LOGIN);
        }

        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        return (
            <div className="black-body">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                            <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading">
                            <h2>Notification</h2>
                        </div>
                    </div>
                </div>

                <div className="page-min-h homepage small-form-page inner-pt">
                    {/* <div className="tc-heading pt-3 pt-md-4">
                        <h2>Your Notifications</h2>
                    </div> */}

                    <div className='container'>
                        {/* <ul className="list-group list-group-flush notify-box"> */}
                       
                        {this.state.items.length ?
                            <InfiniteScroll
                                dataLength={this.state.items.length}
                                next={this.fetchMoreData}
                                hasMore={this.state.hasMore}
                                loader={<div className="load-more-btn"><Spinner color="#e30813" size={36} speed={1} animating={true} />
                                </div>}
                            >{
                                    this.state.items.map((data, index) => (
                                        <div key={index} className="p-2 p-md-3 mb-1 notify-card">
                                            <div className="notify-content mb-2">
                                                <div className='notify-left'>
                                                    <Link to={data.path}>{data.title}</Link>

                                                </div>
                                                <div className='notify-right'>
                                                    <span><i className="far fa-clock mr-1"></i> {moment(data.created_at).fromNow()}</span>
                                                </div>
                                            </div>
                                            <p className="mb-0">{data.content}</p>
                                            {/* <a>Click here</a> */}
                                        </div>
                                    ))}
                            </InfiniteScroll>
                            : <div className="wl-empty page-min-h mt-4" ><div className="wl-empty-poster"><img src="/assets/img/no_data_found.png" alt="" /></div>
                            </div>}
                        {/* </ul> */}
                    </div>



                </div>
                <Foot />
            </div>
        );
    }
}
const mapStateToProps = state => {
    // console.log(state.service.videoNotification)
    return {
        isLoading: state.service ? state.service.isLoading : "",
        videoNotificationRecord: (state.service.videoNotification) ? state.service.videoNotification : "",
        videoNotification: (state.service.videoNotification) ? state.service.videoNotification.data : ""
    };
};
export default connect(mapStateToProps, {
    setLoader,
    getVideoNotification
})(Notification);