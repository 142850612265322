// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderEmail, SelectField } from "../../constants/redux-form";
import { required, email, passwordValidation } from "../../constants/validation";
import { PATHS, PAYPAL_PAYMENT } from "../../constants";
import * as Styled from "../page.styled";
import { Bottom, LoaderScreen } from "../includes";
import { setLoader, emailVerifyRegister, getPlanList, createPassword, addUserPlan, planDetail, updatePlan, savePaymentDetail } from "../../actions";
import ReactHtmlParser from 'react-html-parser';
import { PayPalButton } from "react-paypal-button-v2";
import * as ALL from "@material-ui/core";
import moment from 'moment';
import { Dots } from "react-activity";
import "react-activity/dist/react-activity.css";

/*
 TYPES
*/

type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};

class Register extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            email: '',
            planChecked: '',
            err: '',
            alertMessage: "",
            alertClass: "",
            type: 'password',
            hidden: true,
            selectedPlanId: '',
            plan_amount: '',
            paymentSuccess: '',
            open: false,
            cancelPaymentResponse: '',
            selectedPlanType: ''
        };
        this.toggleShow = this.toggleShow.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    componentDidMount() {
        this.props.reset('verifyEmailForm');
        this.props.reset('selectPlanForm');
        this.props.reset('verifyPasswordForm');
        this.props.getPlanList();

        let email = localStorage.getItem('vai_user_email');
        this.props.planDetail(email);

        if (!localStorage.getItem('vai_user_email')) {
            this.setState({
                currentStep: 1
            })
        }

        if (localStorage.getItem('vai_user_email') && localStorage.getItem('vai_stage') == 2) {
            this.setState({
                currentStep: 2
            })
        } else if (localStorage.getItem('vai_user_email') && localStorage.getItem('vai_stage') == 3) {
            this.setState({
                currentStep: 3
            })
        } else if (localStorage.getItem('vai_user_email') && localStorage.getItem('vai_stage') == 4) {
            this.setState({
                currentStep: 4
            })
        } else if (localStorage.getItem('vai_user_email') && localStorage.getItem('vai_stage') == 5) {
            this.props.history.push(PATHS.LOGIN)
        }

    }

    renderPassword1 = ({ placeholder, id, input, icon, label, type, meta: { touched, error } }: Props) => (
        <div className="input-group mb-3">
            <input  {...input} type={this.state.hidden ? "password" : "text"} placeholder={placeholder} className="form-control" id={id} />

            <span className="password-trigger"><Link to="#" onClick={this.toggleShow}><i className={this.state.hidden ? "fas fa-eye-slash" : "fas fa-eye"}></i></Link></span>

            {touched && (error && <Styled.error>{error}</Styled.error>)}
        </div>
    );


    handleChange = (event) => {
        this.setState({ plan_amount: event.target.value })
    };

    cancelPayment = (data) => {
        this.setState({ alertMessage: 'PayPal payment cancelled', alertClass: "alert alert-success", open: true, cancelPaymentResponse: data.orderID });
    }

    againPaymentHandle = () => {
        this.setState({
            open: false
        })
    }

    step1validate = formValue => {

        let successMessage = "";
        let errorMessage = "";
        setTimeout(() => {
            if (formValue.email) {
                this.props.setLoader(true);
                this.props.emailVerifyRegister(formValue.email).then(result => {
                    this.props.setLoader(false);
                    if (this.props.verifyEmailResponse) {

                        if (this.props.verifyEmailResponse.success) {
                            this.props.setLoader(false);
                            localStorage.setItem('vai_user_email', formValue.email);
                            localStorage.setItem('vai_stage', 2);
                            this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
                            this.props.getPlanList();

                            this.setState({
                                currentStep: 2
                            });
                        } else {
                            this.props.setLoader(false);
                            if (this.props.verifyEmailResponse.data) {
                                localStorage.setItem('vai_user_email', this.props.verifyEmailResponse.data.email);
                                localStorage.setItem('vai_stage', this.props.verifyEmailResponse.data.user_stage);

                                if (this.props.verifyEmailResponse.data.user_stage == 2) {
                                    this.setState({ alertMessage: this.props.verifyEmailResponse.message, alertClass: "alert alert-danger" });
                                    this.setState({
                                        currentStep: 2
                                    })
                                } else if (this.props.verifyEmailResponse.data.user_stage == 3) {
                                    this.setState({ alertMessage: this.props.verifyEmailResponse.message, alertClass: "alert alert-danger" });
                                    this.setState({
                                        currentStep: 3
                                    })
                                } else if (this.props.verifyEmailResponse.data.user_stage == 4) {
                                    this.setState({ alertMessage: this.props.verifyEmailResponse.message, alertClass: "alert alert-danger" });
                                    let email = localStorage.getItem('vai_user_email');
                                    this.props.planDetail(email).then(result => {
                                        if (this.props.planDetailResponse.success) {
                                            this.setState({
                                                currentStep: 4
                                            });
                                            this.setState({ alertMessage: this.props.createPasswordResponse.message, alertClass: "alert alert-success" });
                                        } else {
                                            this.setState({ alertMessage: 'Something went wrong', alertClass: "alert alert-danger" });
                                        }
                                    });
                                    this.setState({
                                        currentStep: 4
                                    });
                                } else if (this.props.verifyEmailResponse.data.user_stage == 5) {
                                    this.setState({ alertMessage: this.props.verifyEmailResponse.message, alertClass: "alert alert-danger" });
                                } else if (this.props.verifyEmailResponse.data.user_stage == 0) {
                                    errorMessage = this.props.verifyEmailResponse.message;
                                    this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
                                } else {
                                }
                            } else {
                                this.setState({ alertMessage: this.props.verifyEmailResponse.message, alertClass: "alert alert-danger" });
                            }

                        }

                        setTimeout(() => {
                            this.setState({ alertMessage: "", alertClass: "" })
                        }, 3000);
                    }
                }).catch(e => {
                    this.props.setLoader(false);
                });
            }
        }, 500);



    };

    step2validate = () => {
        let err = '';
        if (this.state.planChecked.length === 0) {
            err = 'Please select plan';
            this.setState({
                err: err,
                alertClass: "alert alert-danger"
            })
            return false
        }
        this.props.setLoader(true);
        let plan_type = this.state.selectedPlanType;
        let email = localStorage.getItem('vai_user_email');
        if (localStorage.getItem('vai_stage') == 2) {
            this.props.addUserPlan(plan_type, email).then(result => {

                if (this.props.addUserPlanResponse && this.props.addUserPlanResponse.success) {
                    this.props.setLoader(false);
                    localStorage.setItem('vai_stage', 3);
                    this.setState({ alertMessage: this.props.addUserPlanResponse.message, alertClass: "alert alert-success" });
                    this.setState({
                        err: '',
                        currentStep: 3,
                    })
                } else {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.addUserPlanResponse.message, alertClass: "alert alert-danger" });
                }

                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

            })
                .catch((err) => {
                    console.log(err);
                });
        }


        if (localStorage.getItem('vai_stage') == 4) {
            this.props.addUserPlan(plan_type, email).then(result => {
                this.props.setLoader(true);
                if (this.props.addUserPlanResponse && this.props.addUserPlanResponse.success) {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.addUserPlanResponse.message, alertClass: "alert alert-success" });
                    if (this.props.addUserPlanResponse.isFreeAccount == true) {
                        localStorage.setItem('vai_stage', 5);
                        this.props.history.push(PATHS.SIGNUP_SUCCESS);
                    } else {
                        localStorage.setItem('vai_stage', 4);
                        let email = localStorage.getItem('vai_user_email');
                        this.props.planDetail(email).then(result => {
                            if (this.props.planDetailResponse.success) {
                                this.setState({
                                    err: '',
                                    currentStep: 4,
                                })
                                this.setState({ alertMessage: this.props.createPasswordResponse.message, alertClass: "alert alert-success" });
                            } else {
                                this.setState({ alertMessage: 'Something went wrong', alertClass: "alert alert-danger" });
                            }
                        });
                        this.setState({
                            err: '',
                            currentStep: 4,
                        })
                    }

                } else {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.updatePlanResponse.message, alertClass: "alert alert-danger" });
                }

                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

            })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    step3validate = formValue => {

        this.props.setLoader(true);
        let email = localStorage.getItem('vai_user_email');
        let password = formValue.password;
        this.props.createPassword(email, password).then(result => {
            this.props.setLoader(true);
            if (this.props.createPasswordResponse && this.props.createPasswordResponse.success) {
                this.props.setLoader(false);
                this.setState({ alertMessage: this.props.createPasswordResponse.message, alertClass: "alert alert-success" });

                if (this.props.createPasswordResponse.isFreeAccount == true) {
                    localStorage.setItem('vai_stage', 5);
                    this.props.history.push(PATHS.SIGNUP_SUCCESS);
                } else {
                    localStorage.setItem('vai_stage', 4);
                    let email = localStorage.getItem('vai_user_email');
                    this.props.planDetail(email).then(result => {
                        if (this.props.planDetailResponse.success) {
                            this.setState({
                                currentStep: 4
                            });
                            this.setState({ alertMessage: this.props.createPasswordResponse.message, alertClass: "alert alert-success" });
                        } else {
                            this.setState({ alertMessage: 'Something went wrong', alertClass: "alert alert-danger" });
                        }
                    });



                }

            } else {
                this.props.setLoader(false);
                this.setState({ alertMessage: this.props.createPasswordResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                console.log(err);
            });
    };

    onRadioChange = (e) => {
        this.setState({
            planChecked: e.target.value,
            selectedPlanId: e.target.getAttribute('id'),
            selectedPlanType: e.target.getAttribute('plan-type')
        });
    }

    updatePlan = () => {
        this.props.reset('verifyEmailForm');
        this.props.reset('selectPlanForm');
        this.props.reset('verifyPasswordForm');
        this.setState({
            currentStep: 2
        })
    }

    goFirstStep = () => {
        this.props.reset('verifyEmailForm');
        this.setState({
            currentStep: 1
        })
    }

    goSecondStep = () => {
        this.props.reset('selectPlanForm');
        localStorage.setItem('vai_stage', 2);
        this.setState({
            currentStep: 2
        })
    }

    goThirdStep = () => {
        this.props.reset('verifyPasswordForm');
        this.setState({
            currentStep: 3
        })
    }

    render() {
        if (localStorage.getItem("access_token")) {
            this.props.history.push(PATHS.HOME);
        }
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        let self = this;
        let currentDate = moment(new Date());

        let addHour = moment(currentDate).add(2, 'hours');

        let today = moment(addHour).format('gggg-MM-DDTHH:mm:ss') + 'Z';
        return (
            <div className="form-container small-form-page pb-0">
                {this.state.currentStep === 1 ? (
                    <div className="inner-page-h container pt-form">
                        <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                            <i className="fas fa-angle-left mr-2"></i>
                            Back
                        </Link>
                        <div className="form-body">

                            <div className="form-content">
                                <div className="form-header mb-4">
                                    <Link to={PATHS.HOME}>
                                        <img src="assets/img/logo.svg" className="inner-logo img-fluid" alt="Logo Images" />
                                    </Link>
                                </div>
                                <p>STEP 1 OF 4</p>

                                <div className="trans-card p-4 mb-4">
                                    {this.state.alertMessage ? (
                                        <div className={this.state.alertClass} role="alert">
                                            {this.state.alertMessage}
                                        </div>
                                    ) : null}
                                    <div className="tc-heading">
                                        <h2>Sign Up</h2>
                                    </div>
                                    <form
                                        name="verifyEmailForm"
                                        onSubmit={this.props.handleSubmit(this.step1validate)}
                                        className="form"
                                    >
                                        <p>Please Enter Your Email</p>
                                        <Field name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderEmail} />


                                        <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span> Continue</button>

                                    </form>
                                </div>

                                <div className="form-footer text-center">
                                    <p className="footer-text">Already have an Account? <Link to={PATHS.LOGIN} variant="body2">Sign in here</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : this.state.currentStep === 2 ? (
                    <div className="inner-page-h container pt-5">
                        <Link onClick={this.goFirstStep} to="#" className="inner-back-link">
                            <i className="fas fa-angle-left mr-2"></i>
                            Back
                        </Link>
                        <div className="form-body">

                            <div>

                                <div className="form-header mb-4" to="#">
                                    <Link to={PATHS.HOME}>
                                        <img src="assets/img/logo.svg" className="inner-logo img-fluid" alt="Logo Images" />
                                    </Link>
                                </div>
                                <p className='text-c'>STEP 2 OF 4</p>
                                <div className="tc-heading">
                                    <h2>Choose the plan that’s right for you.</h2>
                                </div>

                                {this.state.err ? (
                                    <div className={this.state.alertClass} role="alert">
                                        {this.state.err}
                                    </div>
                                ) : null}
                                <form
                                    name="selectPlanForm"
                                    onSubmit={this.props.handleSubmit(this.step2validate)}
                                >
                                    <section className='plan-section'>
                                        <div className="container">
                                            <div className="row">
                                                {this.props.planResponse ? this.props.planResponse.map((p, index) => (
                                                    <div className="col-lg-3 col-md-6 col-sm-6 plan-sec" key={index}>
                                                        <label htmlFor={p.id} className={`${index == this.state.activeIndex ? "plan-sec-h plan-sec-w planActive" : "plan-sec-h plan-sec-w planInactive"}`}>
                                                            <Field
                                                                name="planChecked"
                                                                onChange={this.onRadioChange}
                                                                onClick={e => this.setState({ activeIndex: index })}
                                                                component='input'
                                                                type="radio"
                                                                value={p.title}
                                                                id={p.id}
                                                                plan-type={p.plan_type_slug}
                                                            />
                                                            <div className="card text-center">
                                                                <div className="title">
                                                                    <h2>{p.title}</h2>
                                                                </div>
                                                                <div className="price">

                                                                    {p.plan_price ? p.plan_price.map((data, index) => (
                                                                        <h4 key={index}><sup>{p.currency}{data.price}</sup>/{data.duration}</h4>
                                                                    )) : ''}
                                                                </div>
                                                                <div>{ReactHtmlParser(p.description)}</div>
                                                                <div className="option">

                                                                    <ul>
                                                                        {p.feature ? p.feature.map((feature, index) => (
                                                                            <li key={index}> <i className="fa fa-check" aria-hidden="true"></i> {feature.title} - {feature.value}</li>
                                                                        )) : ''}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                )) : ''}

                                                <div className="col-md-12">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-4">
                                                            <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span> Continue</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </form>
                            </div>
                        </div>
                    </div>
                ) : this.state.currentStep === 3 ? (
                    <div className="inner-page-h container pt-form">
                        <Link onClick={this.goSecondStep} className="inner-back-link">
                            <i className="fas fa-angle-left mr-2"></i>
                            Back
                        </Link>
                        <div className="form-body">
                            <div className="form-content">

                                <div className="form-header mb-4">
                                    <Link to={PATHS.HOME} >
                                        <img src="assets/img/logo.svg" className="inner-logo img-fluid" alt="Logo Images" />
                                    </Link>
                                </div>
                                {this.props.addUserPlanResponse && this.props.addUserPlanResponse.isFreeAccount != false ? <p>STEP 3 OF 3</p> : <p>STEP 3 OF 4</p>}

                                <div className="trans-card p-4 mb-4">
                                    <div className="tc-heading">
                                        <h2>Sign Up</h2>
                                    </div>
                                    <form
                                        name="verifyPasswordForm"
                                        onSubmit={this.props.handleSubmit(this.step3validate)}
                                        className="form"
                                    >
                                        <p>Please enter password</p>

                                        <Field name="password" type="password" onChange={(event) => this.handleChange(event)} validate={[required, passwordValidation]}
                                            placeholder={`Password`} component={this.renderPassword1} id="password" />

                                        <button type="submit" className="btn btn-block btn-red mb-2"><span className="glyphicon glyphicon-off"></span> Continue</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                    <div className="inner-page-h container pt-5">
                        <Link onClick={this.goThirdStep} className="inner-back-link">
                            <i className="fas fa-angle-left mr-2"></i>
                            Back
                        </Link>
                        {this.props.userDetail ? localStorage.setItem('vai_user_id', this.props.userDetail.id) : ''}
                        <div className="form-body">
                            <div className="container">

                                <div className="form-header mb-4">
                                    <Link to={PATHS.HOME}>
                                        <img src="/assets/img/logo.svg" className="inner-logo img-fluid" alt="Logo Images" />
                                    </Link>
                                </div>
                                <p className='text-c'>STEP 4 OF 4</p>
                                <div className="tc-heading mb-5">
                                    <h2>Your membership starts as soon as you set up payment.</h2>
                                </div>
                                {this.props.planDetailResponse && this.props.planDetailResponse.data ?
                                    <div className="row">
                                        <div className="col-md-6">
                                            <section className='plan-section p-0'>
                                                <div className="container">
                                                    <div className="row">

                                                        <div className="col-md-12 plan-sec plan-sec-h">

                                                            <div className="card text-center mb-4">
                                                                <div className="title">
                                                                    <h2>{this.props.planDetailResponse.data.title}</h2>
                                                                </div>
                                                                <div className="price">
                                                                    {this.props.planDetailResponse ? this.props.planDetailResponse.data ? this.props.planDetailResponse.data.plan_price ? this.props.planDetailResponse.data.plan_price.map((feature, index) => (
                                                                        <h4 key={index}><sup>{this.props.planDetailResponse.data.currency}</sup>{feature.price} /{feature.duration}</h4>
                                                                    )) : '' : '' : ''}

                                                                </div>
                                                                <div>{ReactHtmlParser(this.props.planDetailResponse.data.description)}</div>
                                                                <div className="option">
                                                                    <ul>

                                                                        {this.props.planDetailResponse ? this.props.planDetailResponse.data ? this.props.planDetailResponse.data.feature ? this.props.planDetailResponse.data.feature.map((feature, index) => (
                                                                            <li key={index}> <i className="fa fa-check" aria-hidden="true"></i> {feature.title} - {feature.value}</li>
                                                                        )) : '' : '' : ''}
                                                                    </ul>
                                                                </div>

                                                                <Link to="#" onClick={this.updatePlan}>Change</Link>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </section>
                                        </div>

                                        <div className="col-md-6">
                                            <section className='subscription-section p-0'>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">

                                                            <div className="card text-center">
                                                                <Field
                                                                    name="plan_amount"
                                                                    validate={[required]}
                                                                    onChange={this.handleChange}
                                                                    component={SelectField}
                                                                    value={this.state.plan_amount}
                                                                >
                                                                    <option value="">Please Select</option>
                                                                    {this.props.planDetailResponse.data ? this.props.planDetailResponse.data.plan_price ? this.props.planDetailResponse.data.plan_price.map((data, index) => (<option value={data.plan_id}>{data.title} ${data.price} {data.currency} - {data.duration}</option>)) : '' : ''}
                                                                </Field>
                                                                <PayPalButton
                                                                    options={{
                                                                        vault: true,
                                                                        clientId: PAYPAL_PAYMENT.CLIENT_ID
                                                                    }}

                                                                    style={{
                                                                        label: 'subscribe',
                                                                    }}

                                                                    createSubscription={(data, actions) => {
                                                                        if (this.state.plan_amount) {
                                                                            return actions.subscription.create({
                                                                                plan_id: this.state.plan_amount,
                                                                                start_time: today
                                                                            });
                                                                        } else {
                                                                            actions.disable();
                                                                        }

                                                                    }}

                                                                    onError={(err) => { console.error('PayPal error', err) }}

                                                                    onCancel={(data) => this.cancelPayment(data)}

                                                                    onApprove={(data, actions) => {
                                                                        return actions.subscription.get().then(function (details) {
                                                                            self.props.setLoader(true);
                                                                            fetch(PATHS.API_PATH + 'user/save-user-payment', {
                                                                                method: 'post',
                                                                                headers: {
                                                                                    'content-type': 'application/json'
                                                                                },
                                                                                body: JSON.stringify({
                                                                                    user_id: localStorage.getItem('vai_user_id'),
                                                                                    subscription_id: data.subscriptionID
                                                                                })


                                                                            }).then((response) => {

                                                                                if (response.ok) {
                                                                                    self.props.setLoader(false);

                                                                                    self.props.history.push(PATHS.SIGNUP_SUCCESS);
                                                                                    return response.json();
                                                                                }

                                                                            })


                                                                        }).catch(function (err) {

                                                                        });
                                                                    }}
                                                                />

                                                                <ALL.Dialog
                                                                    open={this.state.open}
                                                                    aria-labelledby="responsive-dialog-title"
                                                                >
                                                                    <ALL.DialogTitle id="responsive-dialog-title">Payment is cancelled of Order Id:  {this.state.cancelPaymentResponse}</ALL.DialogTitle>
                                                                    <ALL.DialogContent>
                                                                        <ALL.DialogContentText>

                                                                            Please try again to get subscription of VAI plans. And enjoy movies, webseries etc.

                                                                        </ALL.DialogContentText>
                                                                    </ALL.DialogContent>
                                                                    <ALL.DialogActions>
                                                                        <ALL.Button
                                                                            onClick={this.againPaymentHandle}
                                                                            color="primary">
                                                                            Try Again
                                                                        </ALL.Button>

                                                                    </ALL.DialogActions>
                                                                </ALL.Dialog>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </section>

                                        </div>
                                    </div>
                                    : <Styled.Innerloader>
                                        <h6 className='mb-3'>Please wait... Loading</h6>
                                        <Dots color="#e30813" size={36} speed={1} animating={true} />
                                    </Styled.Innerloader>}


                            </div>
                        </div>
                    </div>}




                <Bottom />
            </div >

        );
    }
}

const mapStateToProps = state => {
    return {
        planResponse: state.service.planResponse ? state.service.planResponse.data : "",
        isLoading: state.service ? state.service.isLoading : "",
        verifyEmailResponse: state.service.verifyEmailResponse ? state.service.verifyEmailResponse : '',
        createPasswordResponse: state.service.createPasswordResponse ? state.service.createPasswordResponse : '',
        addUserPlanResponse: state.service.addUserPlanResponse,
        planDetailResponse: state.service.planDetailResponse ? state.service.planDetailResponse : '',
        updatePlanResponse: state.service.updatePlanResponse,
        paymentDetailResponse: state.service.paymentDetailResponse ? state.service.paymentDetailResponse : '',
        userDetail: state.service.planDetailResponse ? state.service.planDetailResponse.user_data : ''
    };
};

export default connect(mapStateToProps, {
    setLoader,
    emailVerifyRegister,
    getPlanList,
    createPassword,
    addUserPlan,
    planDetail,
    updatePlan,
    savePaymentDetail
})(reduxForm({ form: "Register", enableReinitialize: true })(Register));

