import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";

/*
 TYPES
*/

type Props = {};
class Foot extends Component<Props> {
    render() {
        return (
            <footer id="footer" className="mt-25">
                <div className="copyright">
                    <div className="container-fluid">
                        <div className="cpr-section">
                            <div className="cr-links">
                                <a href={PATHS.HOME}>Home</a>
                                <Link to={PATHS.FAQ}>FAQ</Link>
                                <Link to={PATHS.HELP_CENTRE}>Help Centre </Link>
                                <Link to={PATHS.TERMS}>Terms of Use </Link>
                                <Link to={PATHS.PRIVACY_POLICY}>Privacy</Link>
                                <Link to={PATHS.CONTACT}>Contact Us </Link>
                                <Link to={PATHS.LEGAL_NOTICE}>Legal Notice </Link>
                                <Link to={PATHS.CORPORATION_INFO}>Corporate Information </Link>
                                <Link to={PATHS.ABOUT}>About Us </Link>
                                <Link to={PATHS.PRODUCER}>Producer </Link>
                            </div>
                            <p className="m-0">
                                © Copyright :  2020 - All Rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Foot;
