import React, { Component } from "react";

/*
 TYPES
*/

type Props = {};
class paypalButton extends Component<Props> {
  render() {
    return ( 
        <form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post">
        <input type="hidden" name="business" value="businesstestvai@business.com" />
        <input type="hidden" name="cmd" value="_xclick-subscriptions" />
        <input type="hidden" name="item_name" value="Membership Subscription" />
        <input type="hidden" name="item_number" value="MS123456" />
        <input type="hidden" name="currency_code" value="USD" />
        <input type="hidden" name="a3" id="paypalAmt" value="4" />
        <input type="hidden" name="p3" id="paypalValid" value="1" />
        <input type="hidden" name="t3" value="M" />
        <input type="hidden" name="custom" value="65" />
        <input type="hidden" name="cancel_return" value="http://viewafricainternational.24livehost.com/return/success" />
        <input type="hidden" name="return" value="http://viewafricainternational.24livehost.com/return/success" />
        <input type="hidden" name="notify_url" value="http://viewafricainternational.24livehost.com/return/success" />
        <input class="buy-btn" type="submit" value="Buy Subscription" >
               
</input>
</form>
    );
  }
}
export default paypalButton;
