// @flow

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../constants";
import { Nav, Foot, LoaderScreen } from "../includes";
import * as ALL from "@material-ui/core";
import { connect } from 'react-redux';
import { getUserWishlist, setLoader, removeWatchlistVideo, addUserVideoHistory } from "../../actions";
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import 'moment-duration-format';
import { Line } from 'rc-progress';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

class Wishlist extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            items: [],
            hasMore: true,
            openSubscriptionBox: false,
            duration: null,
            secondsElapsed: null,
            playing: true
        };

        this.handlePause = this.handlePause.bind(this);
    }

    componentDidMount() {
        let limit = 10;
        let offset = 0;
        let profile_id = localStorage.getItem('current profile');
        this.props.getUserWishlist(limit, offset, profile_id).then(result => {
            this.props.setLoader(true);

            if (this.props.wishlistRecord.success) {
                this.props.setLoader(false);
                this.setState({
                    items: this.props.wishlistResponse
                });

            } else {
                this.props.setLoader(false);
            }
        })
            .catch((err) => {
                console.log(err);
            });

    }


    fetchMoreData = () => {
        let limit = 10;
        let offset = this.state.items.length;
        let profile_id = localStorage.getItem('current profile');
        this.props.getUserWishlist(limit, offset, profile_id).then(result => {
            if (this.props.wishlistRecord.success) {
                if (this.state.items.length >= this.props.wishlistRecord.totalPageCount) {
                    this.setState({ hasMore: false });
                    return;
                } else {
                    setTimeout(() => {
                        this.setState({
                            items: this.state.items.concat(this.props.wishlistResponse)
                        });
                    }, 500);
                }
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    handleClickOpen = (video_id, playback_time, video_path) => {
        localStorage.setItem('video_id', video_id);
        localStorage.setItem('playback_time', playback_time);
        localStorage.setItem('video_path', video_path);

        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    removeWatchlist = (id, videoType) => {
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let video_type = videoType;
        this.props.removeWatchlistVideo(profile_id, video_id, video_type).then(result => {
            this.props.setLoader(true);
            if (this.props.removeWatchlistResponse.success) {
                this.props.setLoader(false);
                this.setState({ alertMessage: this.props.removeWatchlistResponse.message, alertClass: "alert alert-success" });
                let limit = 20;
                let offset = 0;
                let profile_id = localStorage.getItem('current profile');
                this.props.getUserWishlist(limit, offset, profile_id).then(result => {
                    setTimeout(() => {
                        this.setState({
                            items: this.props.wishlistResponse
                        });
                    }, 1500);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.setState({ alertMessage: this.props.removeWatchlistResponse.message, alertClass: "alert alert-danger" });
            }

            setTimeout(() => {
                this.setState({ alertMessage: "", alertClass: "" })
            }, 3000);

        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);

                console.log(err);
            })
    }

    videoInfoOpen = (e) => {
        let videoSlug = e.target.getAttribute('videoslug');
        let videoType = e.target.getAttribute('videotype');
        this.props.history.push('/details/' + videoSlug + '/' + videoType);
    }

    subscriptionBox = () => {
        this.setState({
            openSubscriptionBox: true
        })
    }

    closeSubscriptionBox = () => {
        this.setState({
            openSubscriptionBox: false
        })
    }

    gotoSubscribePlan = () => {
        this.props.history.push(PATHS.RENEW_MEMBERSHIP)
    }


    handlePause = (id) => {
        this.setState({ playing: false })
        let profile_id = localStorage.getItem('current profile');
        let video_id = id;
        let playback_time = Math.trunc(this.state.secondsElapsed);

        this.props.addUserVideoHistory(profile_id, video_id, playback_time).then(result => {
            if (this.props.addVideoHistory.success) {
            }
        })
            .catch((err) => {
                this.setState({ alertMessage: err, alertClass: "alert alert-danger" });
                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
    }

    handlePlayPause = (e) => {
        this.setState({ playing: !this.state.playing })
    }

    handleDuration = (duration) => {
        this.setState({ duration })
    }

    onProgress = (progress) => {
        if (!this.state.duration) {
            return
        }

        const secondsElapsed = progress.played * this.state.duration
        if (secondsElapsed !== this.state.secondsElapsed) {
            this.setState({ secondsElapsed })
        }
    }

    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        return (
            <div className="black-body">
                <Nav />
                <Link onClick={this.props.history.goBack} to="#" className="inner-back-link">
                    <i className="fas fa-angle-left mr-2"></i>
                                            Back
                                </Link>
                <div className="inner-bnr banner-shadow mb-n-20">
                    <div className="container mt-5 pt-4 h-100">
                        <div className="inner-page-heading">
                            <h2>Watchlist</h2>
                        </div>
                    </div>

                </div>
                {this.state.alertMessage ? (
                    <div className={this.state.alertClass} role="alert">
                        {this.state.alertMessage}
                    </div>
                ) : null}
                {this.state.items.length ?
                    <InfiniteScroll
                        dataLength={this.state.items.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={<div className="load-more-btn"><Spinner color="#e30813" size={36} speed={1} animating={true} /></div>}
                    >
                        <div className="container-fluid page-min-h page-carousel mt-4">

                            <div className="row">
                                <div className="col-md-12 pt-3 pt-md-4 pt-lg-5 pb-3 pb-md-4 mt-4">
                                    <div className="search-container pb-20">
                                        {this.state.items.map((result, index) => (
                                            result.web_series_id == 0 || result.web_series_id == null ?
                                                result.get_videos ?
                                                    <div className="item" key={index}>
                                                        <Link to="#" className="movie-poster" onClick={this.videoInfoOpen}>
                                                            {localStorage.getItem('isFreeAccount') == 'true' && result.get_videos.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                            <img src={PATHS.VIDEO_IMAGE_PATH + `/${result.get_videos.video_thumnail_path}`} alt="Poster" className="img-fluid" videoid={result.get_videos.id}
                                                                videotype={result.get_videos.video_type}
                                                                videoslug={result.get_videos.video_slug} />
                                                        </Link>
                                                        <div className="sub-item">
                                                            <Link to="#" className="movie-poster" onClick={this.videoInfoOpen}>
                                                                {localStorage.getItem('isFreeAccount') == 'true' && result.get_videos.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                                <img src={PATHS.VIDEO_IMAGE_PATH + `/${result.get_videos.video_thumnail_path}`} alt="Poster" className="img-fluid" videoid={result.get_videos.id}
                                                                    videotype={result.get_videos.video_type}
                                                                    videoslug={result.get_videos.video_slug} />
                                                            </Link>
                                                            <div className="hide-item">

                                                                <div className="video-display">
                                                                    <div className="video-play">
                                                                        <Link to="#"
                                                                            onClick={localStorage.getItem('isFreeAccount') == 'true' && result.get_videos.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(result.get_videos.id, result.get_videos.get_video_history ? result.get_videos.get_video_history.playback_time : 0, result.get_videos.video_path)}
                                                                        >
                                                                            <i className="fa fa-play-circle play-icon-margin" aria-hidden="true"></i>
                                                                        </Link>

                                                                        {result.get_videos.playback_time > 0 ? <div><span className='play-text'>Play</span><Line percent={(result.get_videos.playback_time / result.get_videos.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></div> : <span>Play</span>}

                                                                    </div>

                                                                    <ALL.Tooltip title="Remove from watchlist" arrow placement="top">
                                                                        <Link to="#" onClick={() => { this.removeWatchlist(result.get_videos.id, result.get_videos.video_type) }}>
                                                                            <i className="fas fa-trash-alt" aria-hidden="true" ></i>
                                                                        </Link>
                                                                    </ALL.Tooltip>
                                                                </div>

                                                                <h1 className="video-t"> {result.get_videos.title ? result.get_videos.title.length < 30 ? `${result.get_videos.title}` : `${result.get_videos.title.substring(0, 30)}...` : ''}</h1>
                                                                {result.get_videos.description.length < 150 ? <span dangerouslySetInnerHTML={{ __html: `${result.get_videos.description}` }} /> : <span className="video-des" dangerouslySetInnerHTML={{ __html: `${result.get_videos.description.substring(0, 150)}...` }} />}
                                                                <div className="video-time">
                                                                    <span className="video-duration">{moment.duration(result.get_videos.length, "seconds").format(' h[h] m[min] s[s]')}</span>
                                                                    <span className="video-duration">
                                                                        {moment(result.get_videos.release_date, 'MM-DD-YYYY').format('ll')}</span>
                                                                    <div className="ml-auto"><span className="subscribers" title="Subscribers">{result.get_videos.age_group}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    : '' : (result.video_id == null || result.video_id == 0) ? result.get_web_series ? <div className="item">
                                                        <Link to="#" className="movie-poster" onClick={this.videoInfoOpen}>
                                                            {localStorage.getItem('isFreeAccount') == 'true' && result.get_web_series.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                            <img src={PATHS.VIDEO_IMAGE_PATH + `/${result.get_web_series.cover_image}`} alt="Poster" className="img-fluid" videoid={result.get_web_series.id}
                                                                videotype={2}
                                                                videoslug={result.get_web_series.slug} />


                                                        </Link>
                                                        <div className="sub-item">
                                                            <Link to="#" className="movie-poster" onClick={this.videoInfoOpen}>
                                                                {localStorage.getItem('isFreeAccount') == 'true' && result.get_web_series.is_paid != 0 ? <span className="paid-label"> Premium </span> : ''}
                                                                <img src={PATHS.VIDEO_IMAGE_PATH + `/${result.get_web_series.cover_image}`} alt="Poster" className="img-fluid" videoid={result.get_web_series.id}
                                                                    videotype={2}
                                                                    videoslug={result.get_web_series.slug} />


                                                            </Link>
                                                            <div className="hide-item">

                                                                <div className="video-display">
                                                                    <div className="video-play">
                                                                        <Link to="#"
                                                                            onClick={localStorage.getItem('isFreeAccount') == 'true' && result.get_web_series.is_paid != 0 ? this.subscriptionBox : () => this.handleClickOpen(result.get_web_series.id, result.get_web_series.playback_time, result.get_web_series.video_path)}
                                                                        >
                                                                            <i className="fa fa-play-circle play-icon-margin" aria-hidden="true"></i>
                                                                        </Link>

                                                                        {result.get_web_series.playback_time > 0 ? <div><span className='play-text'>Play</span><Line percent={(result.get_web_series.playback_time / result.get_web_series.length) * 100} strokeWidth="2" strokeColor="#e30813" trailWidth='2' /></div> : <span>Play</span>}

                                                                    </div>

                                                                    <ALL.Tooltip title="Remove from watchlist" arrow placement="top">
                                                                        <Link to="#" onClick={() => { this.removeWatchlist(result.get_web_series.id, 2) }}>
                                                                            <i className="fas fa-trash-alt" aria-hidden="true" ></i>
                                                                        </Link>
                                                                    </ALL.Tooltip>
                                                                </div>

                                                                <h1 className="video-t"> {result.get_web_series.title ? result.get_web_series.title.length < 30 ? `${result.get_web_series.title}` : `${result.get_web_series.title.substring(0, 30)}...` : ''}</h1>
                                                                {result.get_web_series.description.length < 150 ? <span dangerouslySetInnerHTML={{ __html: `${result.get_web_series.description}` }} /> : <span className="video-des" dangerouslySetInnerHTML={{ __html: `${result.get_web_series.description.substring(0, 150)}...` }} />}
                                                                <div className="video-time">
                                                                    <span className="video-duration">
                                                                        {moment(result.get_web_series.release_date, 'MM-DD-YYYY').format('ll')}
                                                                    </span>
                                                                    <div className="ml-auto"><span className="subscribers" title="Subscribers">{result.get_web_series.age_group}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : '' : ''

                                        ))}
                                    </div>

                                    <ALL.Dialog
                                        open={this.state.openSubscriptionBox}
                                        aria-labelledby="responsive-dialog-title"
                                        className="upgrade-plan-box"
                                    >
                                        <ALL.DialogTitle id="responsive-dialog-title">
                                            Please upgrade your plan to unblock this video
                                    </ALL.DialogTitle>
                                        <ALL.DialogContent>
                                            <ALL.DialogActions className="justify-content-center mb-2">
                                                <Link to="#" onClick={this.closeSubscriptionBox} className="btn red-btn">
                                                    Not Now
                                        </Link>
                                                <Link
                                                    to="#"
                                                    className="btn red-btn"
                                                    onClick={this.gotoSubscribePlan}
                                                >
                                                    Upgrade Plan
                                        </Link>

                                            </ALL.DialogActions>
                                        </ALL.DialogContent>
                                    </ALL.Dialog>

                                </div>
                            </div>
                            <ALL.Dialog fullScreen open={this.state.open}>
                                <div className="video-header">
                                    <ALL.AppBar >
                                        <ALL.Toolbar>

                                            <ALL.IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                                <CloseIcon />
                                            </ALL.IconButton>

                                        </ALL.Toolbar>
                                    </ALL.AppBar>
                                </div>
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        ref={p => { this.p = p }}
                                        className='react-player'
                                        url={localStorage.getItem('video_path')}
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                        onStart={() => { this.p.seekTo(localStorage.getItem('playback_time') == 'null' ? 0 : localStorage.getItem('playback_time')) }}
                                        onPause={() => this.handlePause(localStorage.getItem('video_id'))}
                                        onDuration={this.handleDuration}
                                        onProgress={this.onProgress}
                                        playing={this.state.playing}
                                        config={{
                                            file: {
                                                attributes: {
                                                    autoPlay: true,
                                                    controlsList: 'nodownload',
                                                }
                                            }
                                        }}
                                    />
                                </div>

                            </ALL.Dialog>
                        </div>
                    </InfiniteScroll>

                    : <div className="wl-empty page-min-h mt-4" ><div className="wl-empty-poster"><img src="/assets/img/empty_list_watchlist.png" alt="" /></div><h3>Your Watchlist is currently empty</h3>
                        <p>Add <Link to={PATHS.HOME}>Movies</Link> and <Link to={PATHS.HOME}>Webseries</Link> that you want to watch later by clicking Add to Watchlist.</p>
                    </div>}

                <Foot />

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        wishlistRecord: state.service.userWishlistResponse ? state.service.userWishlistResponse : '',
        wishlistResponse: state.service.userWishlistResponse ? state.service.userWishlistResponse.data : '',
        removeWatchlistResponse: state.service.removeWatchlistVideoResponse ? state.service.removeWatchlistVideoResponse : '',
        isLoading: state.service.isLoading,
        addVideoHistory: state.service.addVideoHistoryResponse ? state.service.addVideoHistoryResponse : ''
    };
};

export default connect(mapStateToProps, {
    setLoader,
    getUserWishlist,
    removeWatchlistVideo,
    addUserVideoHistory
})(withRouter(Wishlist));
